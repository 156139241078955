import {
  showAlert,
  isEmpty,
  mapPicklist,
  getPicklistLabel,
  getPicklistValue,
  validate,
  findIdByNameFromArray,
  handleNavigationDuplicated
} from "@/utils/helpers.js";

export default {
  methods: {
    showAlert,
    isEmpty,
    mapPicklist,
    getPicklistLabel,
    getPicklistValue,
    validate,
    findIdByNameFromArray,
    handleNavigationDuplicated
  }
};
