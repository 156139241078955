<template>
  <div class="search">
    <Modal
      :modalShow="showModal"
      :infoTable="downloadPodTable"
      @closeModal="closeModal"
    />
    <h2>
      Hello, <b>{{ $store.getters["login/showUserLoggedIn"].username }}</b
      >!
    </h2>
    <h6>Please, type a SHP code to search.</h6>

    <div class="search__input-content">
      <b-form-input
        v-model="optionSelected"
        v-on:keyup.enter="search"
        @change="getLoad"
        autocomplete="off"
        class="search__input-content--input"
        list="my-list-search"
        type="text"
        placeholder="Search..."
      ></b-form-input>

      <datalist id="my-list-search">
        <option v-for="(option, index) in options" v-bind:key="index">
          {{ option.name }}
        </option>
      </datalist>
      <div class="search__input-content--icon" @click="search">
        <i class="fas fa-search"></i>
      </div>
    </div>
    <span v-if="showMessage" class="search__input-content--message"
      >SHP code not found</span
    >
    <div class="search__upload">
      <el-upload
        :key="uploadRender"
        class="upload-demo"
        action="/upload"
        multiple
        :on-change="uploadFile"
        :on-remove="removeFile"
        :file-list="fileList"
      >
        <el-button size="small" type="primary"> Upload POD</el-button>
        <div slot="tip" class="el-upload__tip">
          pdf/jpg/png/tiff files with a size less than 500kb
        </div>
      </el-upload>
      <b-overlay
        class="overlay-button"
        :show="showOverlay"
        rounded
        opacity="0.6"
        spinner-small
      >
        <el-button
          size="small"
          type="primary"
          @click="sendFiles"
          v-show="fileList.length >= 1 ? true : false"
          >Send POD</el-button
        >
      </b-overlay>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import Modal from "../components/Modal.vue";

export default {
  name: "Search",
  components: {
    Modal
  },
  data() {
    return {
      optionSelected: null,
      options: [],
      showMessage: false,
      fileList: [],
      uploadRender: 0,
      shpFailedList: [],
      shpSuccessList: [],
      existsFiles: [],
      nonExistsFiles: [],
      showOverlay: false,
      showModal: false,
      downloadPodTable: []
    };
  },
  watch: {
    async optionSelected() {
      if (this.optionSelected == "") {
        this.showMessage = false;
      }
      if (this.optionSelected.length >= 4) {
        await this.search();
        if (this.options.length == 1) {
          this.$router
            .push({
              name: "LoadView",
              params: { loadId: this.options[0].id }
            })
            .catch(() => this.handleNavigationDuplicated);
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.downloadPodTable = [];
    },
    uploadFile(data) {
      if (this.fileList.length > 0) {
        if (!this.fileList.includes(data)) {
          this.fileList.push(data);
        }
      } else {
        this.fileList.push(data);
      }
      this.uploadRender += 1;
    },
    removeFile(data) {
      this.fileList.forEach((file, index) => {
        if (file === data) {
          this.fileList.splice(index, 1);
        }
      });
      this.uploadRender += 1;
    },
    async sendFiles() {
      swal
        .fire({
          icon: "info",
          title: "Are you sure?",
          text:
            "Loads that do not exist or do not comply with the conditions will be removed from the stack",
          showDenyButton: true
        })
        .then(async result => {
          if (result.isConfirmed) {
            this.showOverlay = true;
            this.validateFileName();
            await this.shpExistenceValidation();
          }
        });
    },
    validateFileName() {
      const regex = /^SHP\d{7}\.(pdf|png|jpg|tiff)$/;
      this.fileList.forEach((file, index) => {
        if (!regex.test(file.name)) {
          this.fileList.splice(index, 1);
        }
      });
      this.uploadRender += 1;
    },
    async shpExistenceValidation() {
      this.shpFailedList = [];
      const shpList = [];
      this.fileList.forEach(file =>
        shpList.push({
          shp: file.name.slice(0, file.name.indexOf(".")),
          fileInfo: file
        })
      );
      for (const loadInfo of shpList) {
        try {
          const response = await this.$store.dispatch(
            "documents/shpExistenceValidation",
            loadInfo.shp
          );
          this.shpSuccessList.push({
            response: response,
            file: loadInfo.fileInfo
          });
        } catch {
          this.shpFailedList.push(loadInfo.shp);
        }
      }
      await this.showShpFailedAlert();
    },
    async showShpFailedAlert() {
      if (this.shpFailedList.length > 0) {
        this.removeShpFailed();
        swal
          .fire({
            icon: "info",
            title: "There are some non-existent SHP codes",
            html:
              "<ul>" +
              this.shpFailedList.map(item => `<li>${item}</li>`).join("") +
              "</ul>",
            text: "please try with other SHP code"
          })
          .then(async result => {
            if (result.isConfirmed) {
              await this.documentExist();
            }
          });
        this.uploadRender += 1;
      } else {
        await this.documentExist();
      }
    },
    async documentExist() {
      for (const shpInfo of this.shpSuccessList) {
        const payload = {};
        payload.load_id = shpInfo.response.id;
        payload.shp = shpInfo.response.name;
        payload.documents = ["DELIVERY_RECEIPT"];
        const response = await this.$store.dispatch(
          "documents/getDocuments",
          payload
        );
        if (response[0].url) {
          this.existsFiles.push({
            fileInfo: shpInfo.file,
            loadId: shpInfo.response.id,
            downloadUrl: response[0].url
          });
        } else {
          this.nonExistsFiles.push({ fileInfo: shpInfo.file });
        }
      }
      this.loadsManagement();
    },
    async getUrlBucket(fileInfo) {
      const UrlBucket = await this.$store.dispatch(
        "documents/getUrlFile",
        fileInfo.name
      );
      await this.saveDocument({ url: UrlBucket.url, file: fileInfo });
    },
    async saveDocument(fileSavedInfo) {
      const user = JSON.parse(localStorage.getItem("charlie-uploader-user"));
      let requestInfo = {
        url: fileSavedInfo.url,
        user: user.username,
        document: fileSavedInfo.file
      };
      try {
        await this.$store.dispatch("documents/saveDocument", requestInfo);
        this.showOverlay = false;
        swal.fire({
          icon: "success",
          title: `POD ${fileSavedInfo.file.name} was saved correctly`,
          timer: 4000
        });
      } catch {
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: `POD ${fileSavedInfo.file.name} was not saved correctly`,
          timer: 4000
        });
      }
    },
    async loadsManagement() {
      if (this.nonExistsFiles.length > 0) {
        for (const file of this.nonExistsFiles) {
          await this.getUrlBucket(file.fileInfo);
        }
      }
      if (this.existsFiles.length > 0) {
        swal
          .fire({
            title: `There are ${this.existsFiles.length} loads that already have a POD`,
            text: "Do you want to replace them all or download them all?",
            showDenyButton: true,
            confirmButtonText: "Download them",
            denyButtonText: `Replace Them `
          })
          .then(async result => {
            if (!result.isConfirmed) {
              await this.sendExistFiles();
            } else {
              await this.downloadFile();
            }
          });
      }
      this.fileList = [];
      this.shpFailedList = [];
      this.shpSuccessList = [];
      this.nonExistsFiles = [];
      this.showOverlay = false;
    },
    async sendExistFiles() {
      for (const file of this.existsFiles) {
        await this.getUrlBucket(file.fileInfo);
      }
      this.existsFiles = [];
    },
    async downloadFile() {
      for (const file of this.existsFiles) {
        const shp = file.fileInfo.name.slice(
          0,
          file.fileInfo.name.indexOf(".")
        );
        this.downloadPodTable.push({ shp: shp, url: file.downloadUrl });
      }
      this.existsFiles = [];
      this.showModal = true;
    },
    removeShpFailed() {
      for (let i = 0; i < this.shpFailedList.length; i++) {
        const shp = this.shpFailedList[i];
        for (let j = 0; j < this.fileList.length; j++) {
          if (
            this.fileList[j].name.slice(
              0,
              this.fileList[j].name.indexOf(".")
            ) === shp
          ) {
            this.fileList.splice(j, 1);
          }
        }
      }
    },

    async search() {
      try {
        let shp;
        if (
          this.optionSelected.localeCompare("SHP", undefined, {
            sensitivity: "accent"
          }) == 1
        ) {
          shp = this.optionSelected.slice(3);
        } else {
          shp = this.optionSelected;
        }
        await this.$store.dispatch("load/getLoadList", shp);
        if (this.$store.getters["load/loadList"].length == 0) {
          this.showMessage = true;
        } else {
          this.showMessage = false;
          this.options = this.$store.getters["load/loadList"];
        }
      } catch (err) {
        console.error("[SEARCH (85)]", err.message);
        this.showAlert({ text: err.message });
      }
    },
    getLoad() {
      for (let i = 0; i < this.options.length; i++) {
        if (this.optionSelected == this.options[i].name) {
          this.$router
            .push({
              name: "LoadView",
              params: { loadId: this.options[i].id }
            })
            .catch(() => this.handleNavigationDuplicated);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import url("//unpkg.com/element-ui@2.15.13/lib/theme-chalk/index.css");

::v-deep .el-upload-list {
  overflow-y: auto;
  width: 230px;
  height: 200px;
}
.search {
  height: 86.9vh;
  @include flex-wrap(center);
  flex-direction: column;
  background-image: url("../assets/GLT-LOGISTICS-(TRADEMARK).png");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 70%;
  box-shadow: inset 0 0 0 1000px get-opacity($color-white, 0.93);
  &__input-content {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 80% 20%;
    justify-content: center;
    align-items: center;
    width: 500px;
    border-radius: 20px;
    background-color: $color-white;
    @include box-shadow;
    &--message {
      margin-top: 10px;
      font-size: 13px;
      color: red;
    }
    &--input {
      background-color: inherit !important;
      outline: none;
      border: none;
      border-radius: 20px;
      padding: 20px 40px;
      color: inherit;
      &::placeholder {
        color: $color-gray-shadow;
      }
      &:hover {
        border-color: none !important;
        box-shadow: none !important;
      }
      &:focus {
        border-color: none !important;
        box-shadow: none !important;
      }
    }
    &--icon {
      cursor: pointer;
      justify-self: center;
      color: $color-primary-company;
      padding: 20px 30px;
    }
  }
  &__upload {
    margin-top: 15px;
  }
}

@media (max-width: 1225px) {
  .search {
    background-size: 90%;
    &__input-content {
      width: 90%;
    }
  }
}
</style>
