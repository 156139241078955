import axios from "axios";
import * as fx from "money";
import { showAlert } from "@/utils/helpers.js";

export async function CurrencyExchanger() {
  const { base, rates } = await getCurrencyRates();

  async function getCurrencyRates() {
    try {
      // Get currency rates using Data Fixer API
      const { data } = await axios.get(
        "http://data.fixer.io/api/latest?access_key=f9aff0ccbc4b5bc563017d44b88928d1"
      );
      if (!data.success) throw { message: data.error.info };
      return { base: data.base, rates: data.rates };
    } catch (err) {
      console.error("[CURRENCY (17)]", err.message);
      showAlert({ text: err.message });
    }
  }

  // Money.js currency object
  fx.base = base;
  fx.rates = rates;
  return fx;
}
