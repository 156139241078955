<template>
  <div class="footer">
    <footer class="page-footer font-small unique-color-dark footer-padding">
      <div class="footer-copyright text-center py-3">
        © {{ year.getFullYear() }} <b>developed</b> by
        <a href="http://guane.com.co/">guane.com.co</a>
        <!-- Upgrade version -->
        v2.3.10
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date()
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/CSS/globals.scss";

.footer {
  background-color: $color-background-darker;
  div {
    color: $color-white;
  }
  a {
    color: $color-primary-company;
  }
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
