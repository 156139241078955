<template>
  <div class="customer-quotes">
    <CollapsingContent
      :title="`Customer Quotes (${customerQuotesLength})`"
      icon="mobile-alt"
      content="customer-quotes"
      backgroundColor="background-color: #4caf50"
    >
      <div class="customer-quotes-content">
        <CustomerQuotesTable
          :fields="fields"
          :items="customerQuotes"
          :sortBy="sortBy"
          :tableSize="customerQuotesLength"
          :rowsPerPage="rowsPerPage"
          :currentPage="currentPage"
          @setCurrentPage="setCurrentPage"
        />
      </div>
    </CollapsingContent>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import CollapsingContent from "@/components/CollapsingContent.vue";
import CustomerQuotesTable from "@/components/AllQuotes/CustomerQuotesTable.vue";

export default {
  name: "CustomerQuotes",
  components: {
    CollapsingContent,
    CustomerQuotesTable
  },
  data() {
    return {
      rowsPerPage: 3,
      currentPage: 1,
      sortBy: "customer_quote",
      fields: [
        {
          key: "name",
          label: "Customer Quote",
          sortable: false
        },
        {
          key: "credit_hold",
          sortable: false
        },
        {
          key: "customer_contact",
          sortable: false
        },
        {
          key: "pricing",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters("customerQuotes", ["customerQuotes", "customerQuotesLength"])
  },
  methods: {
    ...mapActions("customerQuotes", ["getCustomerQuotes"]),
    setCurrentPage(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>
