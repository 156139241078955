<template>
  <div class="carrier-quotes">
    <CollapsingContent
      :title="`Carrier Quotes (${carrierQuotesLength})`"
      icon="shopping-cart"
      content="carrier-quotes"
      backgroundColor="background-color: #056676"
    >
      <div class="carrier-quotes-content">
        <CarrierQuotesTable
          :fields="fields"
          :items="carrierQuotes"
          :sortBy="sortBy"
          :tableSize="carrierQuotesLength"
          :rowsPerPage="rowsPerPage"
          :currentPage="currentPage"
          @setCurrentPage="setCurrentPage"
        />
      </div>
    </CollapsingContent>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// Components
import CollapsingContent from "@/components/CollapsingContent.vue";
import CarrierQuotesTable from "@/components/AllQuotes/CarrierQuotesTable.vue";

export default {
  name: "CarrierQuotes",
  components: {
    CollapsingContent,
    CarrierQuotesTable
  },
  data() {
    return {
      rowsPerPage: 3,
      currentPage: 1,
      sortBy: "carrier_quote",
      fields: [
        {
          key: "name",
          label: "Carrier Quote"
        },
        {
          key: "accepted"
        },
        {
          key: "carrier_service"
        },
        {
          key: "service_class"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("carrierQuotes", ["carrierQuotes", "carrierQuotesLength"])
  },
  methods: {
    ...mapActions("carrierQuotes", ["getCarrierQuotes"]),
    setCurrentPage(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>
