<template>
  <div class="carrier-quotes-section mt-3">
    <b-tabs content-class="mt-3">
      <b-tab title="Carrier Quotes" active>
        <div class="carrier-quotes-section__nmfc-class">
          <div class="carrier-quotes-section__container">
            <label class="carrier-quotes-section__label">NMFC Class:</label>
            <b-form-select
              class="carrier-quotes-section__select"
              v-model="densityClass"
              :options="nmfcClassOptions"
              size="sm"
            ></b-form-select>
            <span v-if="isSelected" class="carrier-quotes-section__span"
              >Please be aware that changing the class is only valid for rating
              purposes.<br />Always check your Bill of Lading to verify the
              correct class is shown in the PDF.</span
            >
          </div>
          <button class="refresh-button btn-sm mb-2" @click="requoteByLoad">
            Refresh Quotes
          </button>
        </div>
        <CarrierQuotesTable
          :busy="loadingCarriers"
          :fields="carrierQuotesSchema"
          :items="carrierQuotes"
          :sortBy="sortBy"
          :tableSize="carrierQuotesLength"
          :rowsPerPage="rowsPerPage"
        />
      </b-tab>
      <b-tab title="Customer View">
        <CarrierQuotesTable
          :busy="loadingCarriers"
          :fields="customerViewSchema"
          :items="carrierQuotes"
          :sortBy="sortBy"
          :tableSize="carrierQuotesLength"
          :rowsPerPage="rowsPerPage"
        />
      </b-tab>
      <b-tab
        @click.prevent="$refs.panelHistory.getShortHistoryByShp()"
        title="Panel History"
      >
        <PanelHistory ref="panelHistory" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import CarrierQuotesTable from "@/components/AllQuotes/CarrierQuotesTable.vue";
import PanelHistory from "./PanelHistory.vue";

export default {
  name: "CarrierQuotesSection",
  components: {
    CarrierQuotesTable,
    PanelHistory
  },
  computed: {
    ...mapGetters("load", ["selectedLoad"]),
    ...mapGetters("customerQuotes", ["customerQuote", "customerQuotes"]),
    ...mapGetters("carrierQuotes", ["carrierQuotes", "carrierQuotesLength"])
  },
  watch: {
    densityClass() {
      this.isSelected = false;
      if (this.densityClass) {
        this.isSelected = true;
      }
    }
  },
  data() {
    return {
      loadingCarriers: false,
      rowsPerPage: 10,
      // Delete.
      // currentPage: 1,
      sortBy: "carrier_quote",
      carrierQuotesSchema: [
        // { key: "select" },
        {
          key: "action"
        },
        {
          key: "vendor"
        },
        {
          key: "carrier_service"
        },
        {
          key: "direct_interline",
          label: "Direct/Interline"
        },
        {
          key: "transit_time",
          label: "Transit Days"
        },
        {
          key: "service_class"
        },
        // Dato pendiente
        {
          key: "expiration_date"
        },
        {
          key: "carrier_liability_limit"
        },
        {
          key: "carrier_total"
        },
        {
          key: "markup"
        },
        {
          key: "pickup_performance"
        },
        {
          key: "delivery_performance"
        }
      ],
      customerViewSchema: [
        {
          key: "carrier_service"
        },
        {
          key: "direct_interline",
          label: "Direct/Interline"
        },
        {
          key: "transit_time",
          label: "Transit Days"
        },
        {
          key: "service_class"
        },
        {
          key: "expiration_date"
        },
        {
          key: "carrier_liability_limit"
        },
        {
          key: "customer_quote_total"
        },
        {
          key: "pickup_performance"
        },
        {
          key: "delivery_performance"
        }
      ],
      densityClass: null,
      nmfcClassOptions: [
        { value: null, text: "Please select an option" },
        { value: "500", text: "500" },
        { value: "400", text: "400" },
        { value: "300", text: "300" },
        { value: "250", text: "250" },
        { value: "200", text: "200" },
        { value: "175", text: "175" },
        { value: "150", text: "150" },
        { value: "125", text: "125" },
        { value: "110", text: "110" },
        { value: "100", text: "100" },
        { value: "92.5", text: "92.5" },
        { value: "85", text: "85" },
        { value: "77.5", text: "77.5" },
        { value: "70", text: "70" },
        { value: "65", text: "65" },
        { value: "60", text: "60" },
        { value: "55", text: "55" },
        { value: "50", text: "50" }
      ],
      isSelected: false
    };
  },
  methods: {
    ...mapActions("carrierQuotes", ["getCarrierQuotes"]),
    // Delete.
    // setCurrentPage(currentPage) {
    //   this.currentPage = currentPage;
    // },
    async requoteByLoad() {
      try {
        this.loadingCarriers = true;
        await this.$store.dispatch("carrierQuotes/requoteByLoad", {
          loadId: this.selectedLoad.id,
          customerId:
            this.selectedLoad?.load_details?.customer?.id ||
            this.customerQuote?.id,
          nmfcClass: this.densityClass
        });
        this.$store.commit("load/SET_ACCESSORIALS_VALIDATION_STATUS", true);
        this.loadingCarriers = false;
      } catch (err) {
        console.error("[CARRIER QUOTE SECTION (133)]", err.message);
        this.loadingCarriers = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.carrier-quotes-section {
  width: 100%;
  &__nmfc-class {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__container {
    width: auto;
    display: flex;
    align-items: center;
  }
  &__label {
    white-space: nowrap;
    margin: 0px 10px 0px 0px;
  }
  &__select {
    width: auto;
  }
  &__span {
    margin-left: 20px;
    text-align: start;
    font-size: small;
    color: red;
  }
}

.refresh-button {
  @include primary-button;
}
</style>
