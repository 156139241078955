import API from "./api/methods.js";
import { errorsMessages, showAlert } from "@/utils/helpers.js";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getShortHistoryByShp({ dispatch }, shp) {
      const resource = `v2/history/shp/${shp}/short`;
      try {
        const response = await API.get(resource, true);
        return response;
      } catch (error) {
        if (error.response && error.response.status == 403) {
          await dispatch("login/refreshToken");
          location.reload();
        } else if (error.response?.data?.errors.length > 0) {
          let msg = errorsMessages(error.response?.data?.errors);
          showAlert({ html: msg });
          throw new Error(error.message);
        } else {
          throw error;
        }
        return error;
      }
    },
    async getHistoryByShp({ dispatch }, { object_id }) {
      const resource = `v2/history/${object_id}`;
      try {
        const response = await API.get(resource, true);
        return response;
      } catch (error) {
        if (error.response && error.response.status == 403) {
          await dispatch("login/refreshToken");
          location.reload();
        } else if (error.response?.data?.errors.length > 0) {
          let msg = errorsMessages(error.response?.data?.errors);
          showAlert({ html: msg });
          throw new Error(error.message);
        } else {
          throw error;
        }
        return error;
      }
    }
  }
};
