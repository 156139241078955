import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import LoadView from "../views/LoadView.vue";
import Search from "../views/Search.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Search",
    component: Search
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/load/:loadId",
    name: "LoadView",
    props: true,
    component: LoadView
  },
  {
    path: "*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "@/views/NotFoundView.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
