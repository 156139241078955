// import * as api from "./services";
import { CurrencyExchanger } from "@/utils/currency.js";

export default {
  namespaced: true,
  state: {
    financialSummary: [],
    currency: "USD"
  },
  getters: {
    financialSummary(state) {
      return state.financialSummary;
    }
  },
  actions: {
    transformFinancialSummary(ctx, summary) {
      let summaryArray = [];
      Object.entries(summary).forEach(function(entry) {
        summaryArray.push({
          ...entry[1],
          step: entry[0]
        });
      });
      return summaryArray;
    },
    async setFinancialSummary({ commit, dispatch }, payload) {
      const financialSummary = await dispatch(
        "transformFinancialSummary",
        payload
      );
      commit("SET_FINANCIAL_SUMMARY", financialSummary);
    },
    updateFinancialSummary({ commit }, payload) {
      commit("SET_FINANCIAL_SUMMARY", payload);
    },
    async changeCurrency({ state, commit }, newCurrency) {
      try {
        const oldCurrency = state.currency;
        const fx = await CurrencyExchanger();
        commit("SET_CURRENCY", { fx, from: oldCurrency, to: newCurrency });
      } catch (err) {
        console.error("[FINNANCIAL SUMMARY (42)]", err.message);
      }
    }
  },
  mutations: {
    SET_FINANCIAL_SUMMARY(state, payload) {
      state.financialSummary = payload;
    },
    SET_CURRENCY(state, payload) {
      const { fx, to } = payload;
      state.currency = to;
      Object.keys(state.financialSummary).map(function(key) {
        const item = state.financialSummary[key];
        if (item["customer"] !== null)
          item["customer"] = fx.convert(item["customer"], payload).toFixed(2);
        if (item["vendor"] !== null)
          item["vendor"] = fx.convert(item["vendor"], payload).toFixed(2);
        if (item["margin"] !== null)
          item["margin"] = fx.convert(item["margin"], payload).toFixed(2);
        return item;
      });
    }
  }
};
