<template>
  <div class="all-quotes-view">
    <FinancialSummary />
    <SalesPerformance />
    <CustomerQuotes />
    <CarrierQuotes />
  </div>
</template>

<script>
// Components
import FinancialSummary from "@/components/AllQuotes/FinancialSummary.vue";
import SalesPerformance from "@/components/AllQuotes/SalesPerformance.vue";
import CustomerQuotes from "@/components/AllQuotes/CustomerQuotes.vue";
import CarrierQuotes from "@/components/AllQuotes/CarrierQuotes.vue";

export default {
  name: "AllQuotesView",
  components: {
    FinancialSummary,
    SalesPerformance,
    CustomerQuotes,
    CarrierQuotes
  }
};
</script>
