<template>
  <div class="items-table">
    <b-table
      :key="renderTable"
      hover
      show-empty
      fixed
      stacked="md"
      :items="items"
      :fields="fields"
      small
      :busy="isBusy"
      head-variant="light"
      class="text-left items-table__table"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:thead-top="data">
        <b-tr v-bind:key="data.columns">
          <b-th variant="dark" :colspan="fields.length">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <b-overlay
                  :show="deletingItem"
                  opacity="0.7"
                  spinner-small
                  class="d-inline-block"
                >
                  <b-button
                    class="items-table__table--delete-item"
                    @click="deleteItem"
                    size="sm"
                    >Delete</b-button
                  ></b-overlay
                >
                <!-- Delete.?  -->
                <!-- Modal Form to add New Item -->
                <!-- <b-button
                  class="items-table__table--add-item"
                  @click="$bvModal.show('add-item')"
                  size="sm"
                  >Add new item</b-button
                > -->
                <b-overlay
                  :show="isLoading"
                  opacity="0.7"
                  spinner-small
                  class="d-inline-block"
                >
                  <b-button
                    class="items-table__table--add-item"
                    @click="addNewItem()"
                    size="sm"
                    >Add new item</b-button
                  ></b-overlay
                >

                <!-- Delete.?  -->
                <!-- Modal Form to add New Item -->
                <!-- <b-modal class="add-user__modal" id="add-item" hide-footer>
                  <template v-slot:modal-title>
                    Add new item
                  </template>
                  <div>
                    <AddItem
                      :deliveryStops="deliveryStops"
                      :pickupStops="pickupStops"
                      v-bind:loadId="$route.params.loadId"
                      v-on:closeModal="closeAddItemModal"
                    />
                  </div>
                </b-modal> -->
              </div>
              <div class="d-flex">
                <h6 class="mr-1" style="color:red" v-if="errorSaving">
                  <b-icon icon="x-circle"></b-icon>
                  There was an error saving a field
                </h6>
                <h6 v-if="saving">
                  <b-icon icon="arrow-clockwise" animation="spin"></b-icon>
                  Saving...
                </h6>
                <h6 v-if="showItemSavedMessage">
                  <b-icon
                    animation="throb"
                    variant="success"
                    icon="check-circle-fill"
                  ></b-icon>
                  Item successfully saved
                </h6>
              </div>
            </div>
          </b-th>
        </b-tr>
      </template>

      <template #row-details="row">
        <b-card>
          <div class="items-table__table__row-details">
            <div class="items-table__table__row-details--field">
              <label for="pickup-stop">
                Pickup Stop
              </label>
              <b-form-select
                id="pickup-stop"
                :key="row.item.pickup_stop"
                v-model="items[row.index].pickup_stop"
                :title="
                  pickupStops.find(
                    stop => stop.value == items[row.index].pickup_stop
                  )
                    ? pickupStops.find(
                        stop => stop.value == items[row.index].pickup_stop
                      ).text
                    : ''
                "
                :options="pickupStops"
                size="sm"
                class="items-table__table--input"
                @blur.native="
                  loseFocus(items[row.index].id, 'pickup_stop', row.index)
                "
              ></b-form-select>
            </div>

            <div class="items-table__table__row-details--field">
              <label for="delivery-stop">
                Delivery Stop
              </label>
              <b-form-select
                id="delivery-stop"
                :key="row.item.delivery_stop"
                v-model="items[row.index].delivery_stop"
                :title="
                  deliveryStops.find(
                    stop => stop.value == items[row.index].delivery_stop
                  )
                    ? deliveryStops.find(
                        stop => stop.value == items[row.index].delivery_stop
                      ).text
                    : ''
                "
                :options="deliveryStops"
                size="sm"
                class="items-table__table--input"
                @blur.native="
                  loseFocus(items[row.index].id, 'delivery_stop', row.index)
                "
              ></b-form-select>
            </div>

            <div class="items-table__table__row-details--field">
              <label for="pu-count">
                PU Count
              </label>
              <b-form-input
                id="pu-count"
                v-model="items[row.index].packaging_unit_count"
                placeholder="PU Count"
                type="text"
                size="sm"
                class="items-table__table--input"
                autocomplete="off"
                v-on:blur="
                  loseFocus(
                    items[row.index].id,
                    'packaging_unit_count',
                    row.index
                  )
                "
              ></b-form-input>
            </div>

            <div class="items-table__table__row-details--field">
              <label for="packaging-units">
                Packaging Units
              </label>
              <b-form-select
                v-model="items[row.index].packaging_units"
                class="items-table__table--input"
                size="sm"
                :options="packagingUnits"
                @blur.native="
                  loseFocus(items[row.index].id, 'packaging_units', row.index)
                "
              ></b-form-select>
            </div>

            <div class="items-table__table__row-details--field">
              <label for="nmfc-number">
                NMFC Number
              </label>
              <b-form-input
                id="nmfc-number"
                v-model="items[row.index].nmfc_number"
                maxlength="20"
                placeholder="NMFC Number"
                type="text"
                size="sm"
                class="items-table__table--input"
                autocomplete="off"
                v-on:blur="
                  loseFocus(items[row.index].id, 'nmfc_number', row.index)
                "
              ></b-form-input>
            </div>

            <div class="items-table__table__row-details--field">
              <label for="density">
                Density
              </label>
              <b-form-input
                id="density"
                class="items-table__table--input"
                size="sm"
                type="text"
                placeholder="Density"
                disabled
                :value="items[row.index].density"
              ></b-form-input>
            </div>
          </div>
          <div class="items-table__table__row-details__hide-button">
            <b-button
              class="items-table__table__row-details__hide-button--button"
              size="sm"
              @click="row.toggleDetails"
              >Hide Details</b-button
            >
          </div>
        </b-card>
      </template>

      <template v-slot:head(actions)="row">
        <b-form-checkbox
          :key="row.index"
          v-model="allAreSelected"
          name="checkbox-1"
          value="true"
          unchecked-value="false"
        >
        </b-form-checkbox>
      </template>

      <template v-slot:cell(actions)="row">
        <b-form-checkbox
          :key="row.index"
          v-model="items[row.index].is_checked"
          name="select-row"
          value="true"
          unchecked-value="false"
        >
        </b-form-checkbox>
      </template>

      <template v-slot:cell(item_number)="row">
        <div class="d-flex" :key="row.index">
          <b-form-input
            v-model="items[row.index].item_number"
            :title="items[row.index].item_number"
            type="text"
            step="0.01"
            size="sm"
            class="items-table__table--input"
            autocomplete="off"
            @blur="loseFocus(items[row.index].id, 'item_number', row.index)"
          ></b-form-input>
          <!-- Details button -->
          <b-button
            size="sm"
            class="text-info items-table__table--item-button"
            @click="row.toggleDetails"
          >
            <i class="fas fa-arrow-down"></i>
          </b-button>
        </div>
      </template>

      <template v-slot:cell(item_description)="row">
        <b-form-input
          :key="row.index"
          v-model="items[row.index].item_description"
          :title="items[row.index].item_description"
          type="text"
          size="sm"
          class="items-table__table--input"
          autocomplete="off"
          v-on:blur="
            loseFocus(items[row.index].id, 'item_description', row.index)
          "
        ></b-form-input>
      </template>

      <template v-slot:cell(weight)="row">
        <b-form-input
          :key="row.index"
          v-model="items[row.index].weight"
          :title="items[row.index].weight"
          type="number"
          step="0.01"
          size="sm"
          class="items-table__table--input"
          autocomplete="off"
          v-on:blur="loseFocus(items[row.index].id, 'weight', row.index)"
        ></b-form-input>
      </template>

      <template v-slot:cell(weight_units)="row">
        <b-form-input
          :key="row.index"
          v-model="items[row.index].weight_units"
          :title="items[row.index].weight_units"
          class="items-table__table--input"
          size="sm"
          list="weightUnits"
          autocomplete="off"
          v-on:blur="loseFocus(items[row.index].id, 'weight_units', row.index)"
        ></b-form-input>

        <datalist id="weightUnits">
          <option v-for="weightUnit in weightUnits" v-bind:key="weightUnit">{{
            weightUnit
          }}</option>
        </datalist>
      </template>

      <template v-slot:cell(hu_count)="row">
        <b-form-input
          :key="row.index"
          v-model="items[row.index].hu_count"
          :title="items[row.index].hu_count"
          type="number"
          size="sm"
          class="items-table__table--input"
          autocomplete="off"
          v-on:blur="loseFocus(items[row.index].id, 'hu_count', row.index)"
        ></b-form-input>
      </template>

      <template v-slot:cell(handling_units)="row">
        <b-form-input
          :key="row.index"
          v-model="items[row.index].handling_units"
          :title="items[row.index].handling_units"
          class="items-table__table--input"
          size="sm"
          list="handlingUnits"
          autocomplete="off"
          v-on:blur="
            loseFocus(items[row.index].id, 'handling_units', row.index)
          "
        ></b-form-input>

        <datalist id="handlingUnits">
          <option
            v-for="handlingUnit in handlingUnits"
            v-bind:key="handlingUnit"
            >{{ handlingUnit }}</option
          >
        </datalist>
      </template>

      <template v-slot:cell(length)="row">
        <b-form-input
          :key="row.index"
          v-model="items[row.index].length"
          :title="items[row.index].length"
          type="number"
          step="0.01"
          size="sm"
          class="items-table__table--input"
          autocomplete="off"
          v-on:blur="loseFocus(items[row.index].id, 'length', row.index)"
        ></b-form-input>
      </template>

      <template v-slot:cell(width)="row">
        <b-form-input
          :key="row.index"
          v-model="items[row.index].width"
          :title="items[row.index].width"
          type="number"
          step="0.01"
          size="sm"
          class="items-table__table--input"
          autocomplete="off"
          v-on:blur="loseFocus(items[row.index].id, 'width', row.index)"
        ></b-form-input>
      </template>

      <template v-slot:cell(height)="row">
        <b-form-input
          :key="row.index"
          v-model="items[row.index].height"
          :title="items[row.index].height"
          type="number"
          step="0.01"
          size="sm"
          class="items-table__table--input"
          autocomplete="off"
          v-on:blur="loseFocus(items[row.index].id, 'height', row.index)"
        ></b-form-input>
      </template>

      <template v-slot:cell(dimensions_units)="row">
        <b-form-input
          :key="row.index"
          v-model="items[row.index].dimensions_units"
          :title="items[row.index].dimensions_units"
          class="items-table__table--input"
          size="sm"
          list="dimensionsUnits"
          autocomplete="off"
          v-on:blur="
            loseFocus(items[row.index].id, 'dimensions_units', row.index)
          "
        ></b-form-input>

        <datalist id="dimensionsUnits">
          <option
            v-for="dimensionsUnit in dimensionsUnits"
            v-bind:key="dimensionsUnit"
            >{{ dimensionsUnit }}</option
          >
        </datalist>
      </template>

      <template v-slot:cell(nmfc_number)="row">
        <div :key="row.index" class="items-table__table__nfmc-class">
          <b-form-input
            id="nmfc-number"
            v-model="items[row.index].nmfc_number"
            placeholder="NMFC Number"
            maxlength="20"
            type="text"
            size="sm"
            class="items-table__table--input"
            autocomplete="off"
            v-on:blur="loseFocus(items[row.index].id, 'nmfc_number', row.index)"
          ></b-form-input>
        </div>
      </template>

      <template v-slot:cell(nmfc_class)="row">
        <div :key="row.index" class="items-table__table__nfmc-class">
          <b-form-select
            v-model="items[row.index].nmfc_class"
            v-on:input="loseFocus(items[row.index].id, 'nmfc_class', row.index)"
            :title="items[row.index].nmfc_class"
            class="items-table__table--input"
            size="sm"
          >
            <b-form-select-option
              v-for="nmfcClass in nmfcClasses"
              v-bind:key="nmfcClass"
              :value="nmfcClass"
              >{{ nmfcClass }}</b-form-select-option
            >
          </b-form-select>
          <div
            class="items-table__table__nfmc-class--icon"
            @click="calculateClass(row.index)"
          >
            <i class="fas fa-calculator"></i>
          </div>
        </div>
      </template>

      <template v-slot:cell(linear_feet)="row">
        <b-form-input
          :key="row.index"
          v-model="items[row.index].linear_feet"
          :title="items[row.index].linear_feet"
          type="number"
          step="0.01"
          size="sm"
          class="items-table__table--input"
          autocomplete="off"
          v-on:blur="loseFocus(items[row.index].id, 'linear_feet', row.index)"
        ></b-form-input>
      </template>

      <template v-slot:cell(hazmat)="row">
        <div class="items-table__table__hazmat">
          <b-form-checkbox
            :key="row.index"
            v-model="items[row.index].hazmat"
            name="check-button"
            @input="loseFocus(items[row.index].id, 'hazmat', row.index)"
            switch
          ></b-form-checkbox>
          <div
            class="items-table__table__hazmat--icon"
            @click="$bvModal.show(`edit-hazmat-${row.index}`)"
            v-if="saveItem[row.index]"
          >
            <i class="fas fa-pen"></i>
          </div>
          <b-modal
            :id="`edit-hazmat-${row.index}`"
            :no-close-on-esc="true"
            :no-close-on-backdrop="true"
            :hide-header-close="true"
            hide-footer
          >
            <template #modal-title>
              Edit hazmat
            </template>
            <div class="d-block text-center">
              <EditHazmat
                :fromTable="true"
                v-bind:hazmat="items[row.index].hazmatFields"
                v-bind:itemId="items[row.index].id"
                v-on:closeModal="closeEditHazmatModal(row.index)"
              />
            </div>
          </b-modal>
        </div>
      </template>

      <template v-slot:cell(save_item)="row">
        <div class="items-table__action-options">
          <b-overlay spinner-small :show="savingItem[row.index]" opacity="0.7">
            <span
              @click="validationToCreateNewItem(row.index)"
              class="items-table__action-options--icon"
              v-if="!saveItem[row.index]"
              v-b-tooltip.hover
              title="This item has not been saved. Please fill all fields."
            >
              <b-icon
                font-scale="1.5"
                variant="danger"
                icon="exclamation-circle-fill"
              ></b-icon>
            </span>
          </b-overlay>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
// import AddItem from "./AddItem";
import EditHazmat from "./EditHazmat";
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";

export default {
  name: "ItemsTable",
  components: {
    // Delete.
    // AddItem,
    EditHazmat
  },
  props: {
    stops: Array
  },
  data() {
    return {
      fields: [
        "actions",
        "item_number",
        "item_description",
        "weight",
        "weight_units",
        "hu_count",
        "handling_units",
        "length",
        "width",
        "height",
        "dimensions_units",
        "nmfc_class",
        "linear_feet",
        "hazmat"
      ],
      items: [],
      allAreSelected: false,
      selectedItem: null,
      isBusy: false,
      isLoading: false,
      saveItem: [],
      showItemSavedMessage: false,
      renderTable: 0,
      savingItem: [false],
      deletingItem: false
    };
  },
  computed: {
    ...mapGetters("lineItem", [
      "initialLineItems",
      "lineItems",
      "itemSelects",
      "savingBuffer"
    ]),
    // Delete.
    // items: function() {
    //   return this.lineItems;
    // },
    weightUnits: function() {
      return this.itemSelects.weightUnits;
    },
    handlingUnits: function() {
      return this.itemSelects.handUnits;
    },
    dimensionsUnits: function() {
      return this.itemSelects.dimUnits;
    },
    nmfcClasses: function() {
      return this.itemSelects.nmfcClasses;
    },
    packagingUnits: function() {
      return this.itemSelects.packagingUnits;
    },
    pickupStops: function() {
      let pickupStops = [];
      for (let i = 1; i < this.stops.length; i++) {
        let stopAux = this.stops[i].name.split(" - ");
        if (stopAux[1] == "Pickup") {
          pickupStops.push({
            value: this.stops[i].key,
            text: this.stops[i].name
          });
        }
      }
      return pickupStops;
    },
    deliveryStops: function() {
      let deliveryStops = [];
      for (let i = 1; i < this.stops.length; i++) {
        let stopAux = this.stops[i].name.split(" - ");
        if (stopAux[1] == "Delivery") {
          deliveryStops.push({
            value: this.stops[i].key,
            text: this.stops[i].name
          });
        }
      }
      return deliveryStops;
    },
    saving: function() {
      return this.savingBuffer.inQueue > 0;
    },
    errorSaving: function() {
      return this.savingBuffer.failed && this.savingBuffer.inQueue < 1;
    }
  },
  watch: {
    allAreSelected() {
      for (let item in this.items) {
        this.items[item].is_checked = this.allAreSelected;
      }
    },
    addEditHazmat() {
      if (typeof this.addEditHazmat == "number") {
        this.$bvModal.hide("edit-hazmat");
      } else {
        this.$bvModal.hide("edit-hazmat");
      }
    },
    lineItems() {
      this.items = [...this.lineItems];
      this.validateLineItem();
    }
  },
  async created() {
    this.isBusy = true;
    let loadId = this.$route.params.loadId;
    await this.getLineItems(loadId);
    this.items = [...this.lineItems];
    await this.getSelectsOptions();
    this.validateLineItem();
    this.isBusy = false;
  },
  methods: {
    ...mapActions("lineItem", [
      "getLineItems",
      "getSelectsOptions",
      "editLineItemField",
      "removeItem",
      "getNmfc",
      "createNewLineItem"
    ]),
    loseFocus(itemId, key) {
      if (itemId) {
        if (
          this.initialLineItems[
            this.initialLineItems.findIndex(item => item.id == itemId)
          ][key] !=
          this.items[this.items.findIndex(item => item.id == itemId)][key]
        ) {
          this.editLineItemField({ itemId: itemId, field: key });
        }
      }
    },
    async deleteItem() {
      this.deletingItem = true;
      const indexToDeleteArray = [];
      let i = 0;
      while (i < this.items.length) {
        if (this.items[i].is_checked && this.items[i]?.id) {
          await this.removeItem({ itemId: this.items[i].id, itemIndex: i });
        }
        if (this.items[i].is_checked && !this.items[i]?.id) {
          indexToDeleteArray.push(i);
        }
        i++;
      }
      this.deleteItemsUnsaved(indexToDeleteArray);
      if (this.allAreSelected) {
        this.allAreSelected = "false";
      }
      this.renderTable += 1;
      this.validateLineItem();
      this.deletingItem = false;
    },
    calculateClass(itemIndex) {
      if (
        this.items[itemIndex].weight != "" &&
        this.items[itemIndex].weight_units != "" &&
        this.items[itemIndex].hu_count != "" &&
        this.items[itemIndex].handling_units != "" &&
        this.items[itemIndex].length != "" &&
        this.items[itemIndex].width != "" &&
        this.items[itemIndex].height != "" &&
        this.items[itemIndex].dimensions_units != ""
      ) {
        let args = {
          weight: parseFloat(this.items[itemIndex].weight),
          weight_units: this.items[itemIndex].weight_units,
          hu_count: this.items[itemIndex].hu_count,
          handling_units: this.items[itemIndex].handling_units,
          length: parseFloat(this.items[itemIndex].length),
          width: parseFloat(this.items[itemIndex].width),
          height: parseFloat(this.items[itemIndex].height),
          dimensions_units: this.items[itemIndex].dimensions_units
        };
        this.getNmfc(args).then(res => {
          this.items[itemIndex].nmfc_class = res.nmfc;
          if (this.items[itemIndex].id) {
            this.editLineItemField({
              itemId: this.items[itemIndex].id,
              field: "nmfc_class"
            });
          }
        });
      } else {
        swal.fire({
          title: "Inputs error",
          text:
            "Please make sure these inputs are filled: weight, weight units, HU count, HU units, length, width, height and dimensions units.",
          icon: "error"
        });
      }
    },
    // Delete.
    // closeAddItemModal() {
    //   this.$bvModal.hide("add-item");
    // },
    closeEditHazmatModal(rowIndex) {
      this.$bvModal.hide(`edit-hazmat-${rowIndex}`);
    },
    async addNewItem() {
      this.isLoading = true;
      const item = {
        item_number: "item",
        item_description: "Commodity",
        load_id: this.$route.params.loadId,
        dimensions_units: "in",
        hu_count: 0,
        handling_units: "Pallets",
        height: 0,
        length: 0,
        linear_feet: null,
        nmfc_class: "",
        weight_units: "lbs",
        weight: 0,
        width: 0,
        pickup_stop: this.pickupStops[0]?.value,
        delivery_stop: this.deliveryStops[0]?.value,
        hazmat: false,
        hazmat_contact: "",
        hazmat_class_division: "",
        hazmat_number_type: "",
        hazmat_number: "",
        hazmat_packaging_group: ""
      };
      this.items.push(item);
      this.validateLineItem();
      this.isLoading = false;
    },
    async runNewItemCreation(item, index) {
      this.isLoading = true;
      this.savingItem[index] = true;
      const newItem = {
        name: item?.item_number,
        description: item?.item_description,
        load_id: this.$route.params.loadId,
        dimension_units: item?.dimensions_units,
        handling_unit_count: item?.hu_count,
        handling_units: item?.handling_units,
        height: item?.height,
        length: item?.length,
        linear_feet: item?.linear_feet,
        nmfc_number: item?.nmfc_number,
        nmfc_class: item?.nmfc_class,
        weight_units: item?.weight_units,
        weight: item?.weight,
        width: item?.width,
        pickup_stop: item?.pickup_stop,
        delivery_stop: item?.delivery_stop,
        packaging_unit_count: item?.packaging_unit_count,
        packaging_units: item?.packaging_units,
        hazardous_materials: item?.hazmat,
        hazmat_contact: "",
        hazmat_class_division: "",
        hazmat_number_type: "",
        hazmat_number: "",
        hazmat_packaging_group: ""
      };
      await this.createNewLineItem([newItem]);
      this.isLoading = false;
      this.savingItem[index] = false;
      this.showItemSavedMessage = !this.saving && !this.errorSaving;
      this.validateLineItem();
      setTimeout(() => {
        this.showItemSavedMessage = false;
      }, "5000");
    },
    validateLineItem() {
      this.items.forEach((element, index) => {
        this.saveItem[index] = !!element?.id;
      });
      this.validateFields();
    },
    validationToCreateNewItem(index) {
      this.isLoading = true;
      let ifCreateNewItem = false;
      ifCreateNewItem =
        !!this.items[index]?.item_number &&
        !!this.items[index]?.item_description &&
        !!this.items[index]?.weight &&
        !!this.items[index]?.weight_units &&
        !!this.items[index]?.hu_count &&
        !!this.items[index]?.handling_units &&
        !!this.items[index]["length"] &&
        !!this.items[index]?.width &&
        !!this.items[index]?.height &&
        !!this.items[index]?.dimensions_units &&
        !!this.items[index]?.nmfc_class;
      if (ifCreateNewItem) {
        this.runNewItemCreation(this.items[index], index);
      } else {
        this.showAlert({
          alertType: "warning",
          title: "Please validate!",
          text: "Please validate that you have filled in all the fields."
        });
      }
      this.isLoading = false;
    },
    validateFields() {
      const unsavedItems = this.saveItem.some(element => element === false);
      const validateIfSaveFieldExists = this.fields.some(
        field => field?.key === "save_item"
      );
      if (unsavedItems && !validateIfSaveFieldExists) {
        this.fields.push({ key: "save_item", label: "Save Item" });
      } else if (!unsavedItems && validateIfSaveFieldExists) {
        this.fields.pop();
      }
      this.renderTable += 1;
    },
    deleteItemsUnsaved(indexToDeleteArray) {
      const uncheckedArray = this.items.filter(item => !item.is_checked);
      this.items = [...uncheckedArray];
      if (indexToDeleteArray.length > 0) {
        for (let index = 0; index < indexToDeleteArray.length; index++) {
          this.saveItem.splice(indexToDeleteArray[index], 1);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
%flex-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}
%icon-cell {
  padding: 3px;
  color: $color-secondary-button;
  font-size: 15px;
  cursor: pointer;
}
.items-table {
  margin-bottom: 50px;
  &__table {
    @include box-shadow;
    &__row-details {
      color: #2c3e50;
      margin: 10px 0px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      justify-content: space-around;
      &--field {
        padding: 10px;
        label {
          font-size: 11px;
        }
        input {
          text-align: left;
        }
        select {
          &:hover {
            @include box-shadow;
          }
        }
      }
      &__hide-button {
        display: flex;
        justify-content: flex-end;
        &--button {
          @include secondary-button;
        }
      }
    }
    &--item-button {
      @include button-link;
      font-size: 12px;
    }
    &--delete-item {
      @include primary-button;
      margin-right: 10px;
    }
    &--add-item {
      @include primary-button;
    }
    &--input {
      border: 0.5px solid $color-background-input;
      background-color: $color-light !important;
      text-align: center;
      font-size: 10px;
      padding: 2px 4px !important;
    }
    &--button {
      @include secondary-button;
      padding: 3px !important;
      box-shadow: none;
    }
    &__nfmc-class {
      @extend %flex-cell;
      &--icon {
        @extend %icon-cell;
      }
    }
    &__hazmat {
      @extend %flex-cell;
      &--icon {
        @extend %icon-cell;
      }
    }
  }
  &__action-options {
    // @extend %flex-cell;
    &--icon {
      @extend %icon-cell;
    }
  }
}
.table .thead-light th {
  color: $color-light;
  background-color: $color-background-dark;
  border: none;
  border-radius: 6px 6px 0px 0px;
  padding: 15px;
}
.b-table {
  border-radius: 6px;
  font-size: 10px;
}

@media (max-width: 900px) {
  .items-table__table__row-details {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 400px) {
  .items-table__table__row-details {
    grid-template-columns: 1fr;
  }
}
</style>
