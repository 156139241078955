import API from "./api/methods.js";

export default {
  namespaced: true,
  state: {
    loadStops: null,
    locations: [],
    selectedLocation: null,
    contactList: null,
    stopDeleted: null,
    updatedStop: null,
    newStop: null,
    shippingCity: null,
    shippingZipCode: null,
    accountId: null,
    contactId: null,
    stopsContacts: [],
    editMode: false
  },
  getters: {
    loadStops(state) {
      return state.loadStops;
    },
    locations(state) {
      return state.locations;
    },
    selectedLocation(state) {
      return state.selectedLocation;
    },
    contactList(state) {
      return state.contactList;
    },
    shippingCity(state) {
      return state.shippingCity;
    },
    shippingZipCode(state) {
      return state.shippingZipCode;
    },
    accountId(state) {
      return state.accountId;
    },
    contactId(state) {
      return state.contactId;
    },
    stopsContacts(state) {
      return state.stopsContacts;
    },
    editMode(state) {
      return state.editMode;
    }
  },
  mutations: {
    SET_LOCATIONS(state, locations) {
      state.locations = locations;
    },
    SET_LOAD_STOPS(state, stops) {
      state.loadStops = stops;
    },
    SET_SELECTED_LOCATION(state, location) {
      state.selectedLocation = location;
    },
    SET_CONTACT_LIST(state, newContactList) {
      state.contactList = newContactList;
    },
    SET_STOP_DELETED(state, newStopDeleted) {
      state.stopDeleted = newStopDeleted;
    },
    UPDATED_STOP(state, newUpdatedStop) {
      state.updatedStop = newUpdatedStop;
    },
    SET_NEW_STOP(state, newStop) {
      state.newStop = newStop;
    },
    SET_SHIPPING_CITY(state, newShippingCity) {
      state.shippingCity = newShippingCity;
    },
    SET_SHIPPING_ZIP_CODE(state, newShippingZipCode) {
      state.shippingZipCode = newShippingZipCode;
    },
    SET_ACCOUNT_ID(state, newAccountId) {
      state.accountId = newAccountId;
    },
    SET_CONTACT_ID(state, newContactId) {
      state.contactId = newContactId;
    },
    SET_STOPS_CONTACTS(state, contacts) {
      state.stopsContacts = contacts;
    },
    SET_EDIT_MODE(state, editMode) {
      state.editMode = editMode;
    }
  },
  actions: {
    getLoadStops({ commit, dispatch }, loadId) {
      const resource = `/stop/load/${loadId}`;
      return API.get(resource, true).then(async function(res) {
        await dispatch("sortStops", res.data);
        let promises = [];
        res.data.forEach(async function(stop) {
          const accountId = stop.location_id;
          const contactId = stop.contact_info_id;
          if (accountId !== null && contactId !== null) {
            promises.push(
              dispatch("getContactInfo", {
                accountId,
                contactId
              })
            );
          } else promises.push(null);
        });
        const stopsContacts = await Promise.all(promises);
        commit("SET_STOPS_CONTACTS", stopsContacts);
      });
    },
    sortStops({ commit }, data) {
      data.sort(function(a, b) {
        var numberA = a.number;
        var numberB = b.number;
        if (numberA < numberB) {
          return -1;
        }
        if (numberA > numberB) {
          return 1;
        }
        return 0;
      });
      commit("SET_LOAD_STOPS", data);
    },
    getLocations({ commit }, name) {
      const resource = `/account/name/${name}`;
      return API.get(resource, true).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting locations from server");
        commit("SET_LOCATIONS", res.data);
      });
    },
    getLocationById({ commit }, accountId) {
      const resource = `/account/${accountId}`;
      return API.get(resource, true).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting location data from server");
        commit("SET_SELECTED_LOCATION", res.data);
      });
    },
    getContactList({ commit }, locationId) {
      const resource = `/contact/account/${locationId}`;
      return API.get(resource, true).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting stops contacts from server");
        commit("SET_CONTACT_LIST", res.data);
      });
    },
    getContactInfo(ctx, { accountId, contactId }) {
      const resource = `/contact/account/${accountId}`;
      return API.get(resource, true).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting stop contact info from server");
        const contact = res.data.filter(function(contact) {
          return contact.id === contactId;
        });
        return contact[0];
      });
    },
    addNewStop({ commit, dispatch }, info) {
      const resource = `/stop`;
      return API.post(resource, info.body, true).then(async function(res) {
        if (typeof res === "undefined")
          throw new Error("Error creating new stop");
        commit("SET_NEW_STOP", res.data);
        await dispatch("getLoadStops", info.loadId);
      });
    },
    deleteStop({ commit, dispatch }, info) {
      const resource = `/stop/${info.stopId}`;
      return API.delete(resource, true).then(async function(res) {
        if (typeof res === "undefined") throw new Error("Error deleting stop");
        commit("SET_STOP_DELETED", res.data);
        await dispatch("getLoadStops", info.loadId);
      });
    },
    updateStop({ commit, dispatch }, info) {
      const resource = `/stop/${info.stopId}`;
      return API.patch(resource, info.body, true).then(async function({
        data
      }) {
        commit("UPDATED_STOP", data);
        await dispatch("getLoadStops", info.loadId);
      });
    },
    getShippingCity({ commit }, city) {
      const resource = `/account/search-locations?city=${city}`;
      return API.get(resource, true).then(async function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting shipping city from server");
        commit("SET_SHIPPING_CITY", res.data);
      });
    },
    getShippingZipCode({ commit }, zipCode) {
      const resource = `/account/search-locations?zip_code=${zipCode}`;
      return API.get(resource, true).then(async function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting shipping zipcode from server");
        commit("SET_SHIPPING_ZIP_CODE", res.data);
      });
    },
    addLocation({ commit, dispatch }, { stopId, loadId, info }) {
      const resource = `/account`;
      return API.post(resource, info.location, true).then(async function({
        data
      }) {
        commit("SET_ACCOUNT_ID", data.id);
        info.contact.account_id = data.id;
        await dispatch("createContact", { stopId, loadId, info: info.contact });
      });
    },
    createContact({ commit, dispatch }, { stopId, loadId, info }) {
      const resource = `/contact`;
      return API.post(resource, info, true).then(async function(res) {
        if (typeof res === "undefined")
          throw new Error("Error creating stop contact from server");
        const payload = {
          stopId,
          loadId,
          body: { contact_info_id: res.data.id }
        };
        await dispatch("updateStop", payload);
        commit("SET_CONTACT_ID", res.data.id);
      });
    },
    updateContact({ dispatch }, { loadId, contactId, update }) {
      const resource = `/contact/${contactId}`;
      return API.patch(resource, update, true).then(async function() {
        await dispatch("getLoadStops", loadId);
      });
    },
    setEditMode({ commit }, value) {
      commit("SET_EDIT_MODE", value);
    }
  }
};
