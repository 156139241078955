<template>
  <div class="freight-quote-assistant">
    <FreightSummary />
    <div class="tables-section mt-5 d-flex flex-wrap justify-content-center">
      <CustomerQuote class="col-md-6" />
      <CarrierQuote class="col-md-6" />
      <CustomerPricing class="col-md-6" />
      <CarrierPricing class="col-md-6" />
      <hr />
      <CarrierQuotesSection />
    </div>
  </div>
</template>

<script>
// components
import FreightSummary from "@/components/FreightQuoteAssistant/FreightSummary.vue";
import CustomerQuote from "@/components/FreightQuoteAssistant/CustomerQuote.vue";
import CarrierQuote from "@/components/FreightQuoteAssistant/CarrierQuote.vue";
import CustomerPricing from "@/components/FreightQuoteAssistant/CustomerPricing.vue";
import CarrierPricing from "@/components/FreightQuoteAssistant/CarrierPricing.vue";
import CarrierQuotesSection from "@/components/FreightQuoteAssistant/CarrierQuotesSection.vue";

export default {
  name: "FreightQuoteAssistant",
  components: {
    FreightSummary,
    CustomerQuote,
    CarrierQuote,
    CustomerPricing,
    CarrierPricing,
    CarrierQuotesSection
  }
};
</script>
