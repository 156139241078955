<template>
  <b-modal
    :id="`edit-address-modal-${stopIndex}`"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
  >
    <template #modal-title>
      Edit Address
    </template>
    <div class="edit-address">
      <!-- City field -->
      <div class="edit-address__field">
        <span class="edit-address__field--label">City</span>
        <b-form-input
          v-model="city"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </div>

      <!-- State/Province field -->
      <div class="edit-address__field">
        <span class="edit-address__field--label">State/Province</span>
        <b-form-input
          v-model="stateProvince"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </div>

      <!-- Postal Code field -->
      <div class="edit-address__field">
        <span class="edit-address__field--label">Postal Code</span>
        <b-form-input
          v-model="postalCode"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </div>

      <!-- Country field -->
      <div class="edit-address__field">
        <span class="edit-address__field--label">Country</span>
        <b-form-input
          v-model="country"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </div>

      <hr />
      <div class="edit-address__buttons">
        <!-- Cancel button -->
        <b-button
          class="edit-address__buttons--cancel"
          size="sm"
          @click="closeModal"
          ><i class="fas fa-times-circle"></i> Cancel</b-button
        >
        <!-- Save Button -->
        <b-button
          class="edit-address__buttons--save"
          size="sm"
          @click="saveAddress"
        >
          <div v-if="!isLoading"><i class="fas fa-check-circle"></i></div>
          <div>
            <b-icon
              icon="arrow-clockwise"
              animation="spin"
              v-if="isLoading"
            ></b-icon>
          </div>
          <span class="ml-1">Save Changes</span>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EditAddressModal",
  computed: {
    ...mapGetters("load", ["selectedLoad"]),
    ...mapGetters("stops", ["loadStops"])
  },
  props: {
    stopIndex: Number
  },
  data() {
    return {
      isLoading: false,
      city: "",
      stateProvince: "",
      postalCode: "",
      country: ""
    };
  },
  methods: {
    ...mapActions("stops", ["updateStop"]),
    setFields() {
      this.city = this.stopData.city;
      this.stateProvince = this.stopData.state_province;
      this.postalCode = this.stopData.postal_code;
      this.country = this.stopData.country;
    },
    async saveAddress() {
      try {
        this.isLoading = true;
        const payload = {
          stopId: this.stopData.id,
          loadId: this.selectedLoad.id,
          body: {
            city: this.city,
            state_province: this.stateProvince,
            postal_code: this.postalCode,
            country: this.country
          }
        };
        await this.updateStop(payload);
        this.$emit("refreshStops");
        this.closeModal();
      } catch (err) {
        console.error("[EDIT ADDRESS (129)]", err.message);
        this.showAlert({ text: err.message });
      } finally {
        this.isLoading = false;
      }
    },
    closeModal() {
      this.$bvModal.hide(`edit-address-modal-${this.stopIndex}`);
    }
  },
  created() {
    this.stopData = this.loadStops[this.stopIndex];
    this.setFields();
  }
};
</script>

<style lang="scss" scoped>
.edit-address {
  &__field {
    @include modal-form-field(20% 80%);
    input {
      font-size: 13px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    &--cancel {
      @include secondary-button;
    }
    &--save {
      display: flex;
      @include primary-button;
      margin-left: 10px;
    }
  }
}
</style>
