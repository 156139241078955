<template>
  <div class="carrier-info">
    <b-table
      hover
      show-empty
      fixed
      stacked="md"
      :busy="busy"
      :fields="fieldsCarrierInfo"
      :items="items"
      head-variant="light"
      class="text-left carrier-info__table"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template
        v-for="field in fieldsCarrierInfo"
        v-slot:[`head(${field.key})`]="column"
      >
        <template v-if="field.key != 'shipperConsigneeInfo'">
          <div :key="field.key" class="carrier-info__table__head">
            <span>{{ column.field.name }}</span>
          </div>
        </template>
      </template>

      <template
        v-for="(field, index) in fields"
        v-slot:[`cell(${field.key})`]="row"
      >
        <template v-if="field.key != 'shipperConsigneeInfo'">
          <template v-if="row.index == 0">
            <b-form-input
              v-model="status[index]"
              :key="field.key"
              readonly
              size="sm"
              type="text"
              class="carrier-info__table--input"
              placeholder="Stop Status"
              autocomplete="off"
            ></b-form-input>
          </template>
          <template v-if="row.index == 1">
            <b-form-datepicker
              id="datepicker"
              v-model="departureDate[index]"
              :key="field.key"
              size="sm"
              locale="en"
              :disabled="readonly[index]"
              today-button
              reset-button
              close-button
              @input="updateStop(index, field.key)"
              class="carrier-info__table--input"
              autocomplete="off"
            ></b-form-datepicker>
          </template>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "CarrierInfo",
  props: {
    fields: Array,
    busy: Boolean
  },
  data() {
    return {
      items: [
        { carrierInfo: "Stop Status" },
        { carrierInfo: "Departure Date" }
      ],
      fieldsCarrierInfo: [],
      status: [],
      departureDate: [],
      count: 0,
      readonly: [],
      readOnlyStatus: [
        "Unassigned",
        "Quotes Requested",
        "Quotes Received",
        "Tendered"
      ]
    };
  },
  mounted() {
    for (let field in this.fields) {
      this.fieldsCarrierInfo.push(this.fields[field]);
    }
    this.fieldsCarrierInfo.shift();
    let firstColumn = { carrierInfo: "Carrier Info" };
    this.fieldsCarrierInfo.splice(0, 0, firstColumn);
    for (let field in this.fields) {
      if (field != 0) {
        this.status[field] = this.$store.getters["stops/loadStops"][
          field - 1
        ].stop_status;
        this.departureDate[field] = this.$store.getters["stops/loadStops"][
          field - 1
        ].departure_date;
        if (
          this.readOnlyStatus.includes(
            this.$store.getters["load/selectedLoad"].load_status
          )
        ) {
          this.readonly[field] = true;
        }
      }
    }
  },
  methods: {
    async updateStop(index, stopId) {
      let info = {};
      info.loadId = this.$route.params.loadId;
      info.stopId = stopId;
      info.body = {};
      info.body.departure_date = this.departureDate[index];
      this.count += 1;
      await this.$store.dispatch("stops/updateStop", info);
      return this.$emit("input", this.count);
    }
  }
};
</script>

<style lang="scss" scoped>
.carrier-info {
  &__table {
    @include box-shadow;
    &--input {
      @extend %input-table;
      background-color: $color-light !important;
    }
  }
}

.b-table {
  border-radius: 6px;
  font-size: 13px;
}
</style>
