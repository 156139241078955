import API from "./api/methods.js";

export default {
  namespaced: true,
  state: {
    loadStatus: "",
    documents: [],
    pendingDocuments: [],
    generatedDocuments: [],
    selectedDocument: {},
    tasks: []
  },
  getters: {
    loadStatus(state) {
      return state.loadStatus;
    },
    documents(state) {
      return state.documents;
    },
    pendingDocuments(state) {
      return state.pendingDocuments;
    },
    generatedDocuments(state) {
      return state.generatedDocuments;
    },
    selectedDocument(state) {
      return state.selectedDocument;
    },
    tasks(state) {
      return state.tasks;
    }
  },
  actions: {
    getUrlFile(_, fileName) {
      const resource = `/v2/documents/upload-url`;
      const params = {
        document_type: "DELIVERY_RECEIPT",
        file_name: fileName
      };
      return API.post(resource, null, true, params).then(function(res) {
        if (typeof res === "undefined") {
          throw new Error("Error getting URL file");
        } else {
          return res.data;
        }
      });
    },
    shpExistenceValidation(_, shp) {
      const resource = `/v2/loads/shp/${shp}/status`;
      return API.get(resource, true).then(function(res) {
        if (typeof res === "undefined") {
          throw new Error("Error getting shp information");
        } else {
          return res.data;
        }
      });
    },
    saveDocument(_, requestInfo) {
      fetch(requestInfo.url, {
        method: "PUT",
        body: requestInfo.document.raw,
        headers: {
          "x-goog-meta-uploaded-by": requestInfo.user,
          "Content-Type": "application/octet-stream"
        }
      })
        .then(response => {
          return response;
        })
        .catch(error => {
          console.log(error);
        });
    },
    downloadFile(_, body) {
      const resource = `/v2/documents/specifics`;
      return API.post(resource, body, true, null).then(function(res) {
        if (typeof res === "undefined") {
          throw new Error("Error getting document info");
        } else {
          return res.data.data;
        }
      });
    },
    // getDocuments({ commit }, loadId) {
    //   const resource = `/documents/${loadId}`;
    //   return API.get(resource, true).then(function(res) {
    //     if (typeof res === "undefined")
    //       throw new Error("Error getting documents from server");
    //     commit("SET_DOCUMENTS", res.data);
    //   });
    // },
    getDocuments({ commit }, params) {
      const resource = `/v2/documents`;
      return API.get(resource, true, params).then(function(res) {
        if (typeof res === "undefined") {
          throw new Error("Error getting documents from server");
        } else {
          commit("SET_DOCUMENTS", res.data.data);
          return res.data.data;
        }
      });
    },
    generateDocuments({ commit, state }, payload) {
      const resource = `/v2/documents/get-or-generate`;
      return API.post(resource, payload.payload, true, null).then(function(
        res
      ) {
        if (typeof res === "undefined") {
          throw new Error("Error generating documents from server");
        } else {
          if (payload.documents_bot?.length > 0) {
            const allTasks = state.tasks.concat(res.data.documents_task);
            commit("SET_TASKS", allTasks);
          }
          commit("SET_LOAD_STATUS", payload.loadStatus);
          return res.data;
        }
      });
    },
    // generateDocuments({ commit, state }, { loadId, names, loadStatus }) {
    //   const resource = `/documents/${loadId}`;
    //   return API.post(resource, names, true).then(function(res) {
    //     if (typeof res === "undefined")
    //       throw new Error("Error generating documents from server");
    //     const allTasks = state.tasks.concat(res.data);
    //     commit("SET_TASKS", allTasks);
    //     commit("SET_LOAD_STATUS", loadStatus);
    //   });
    // },
    getTasks({ commit, state }) {
      const resource = "/documents/tasks";
      return API.get(resource, true).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting tasks from server");
        const allTasks = state.tasks.concat(res.data);
        commit("SET_TASKS", allTasks);
      });
    },
    getTasksStatus({ commit }, params) {
      const resource = `/documents/status`;
      return API.get(resource, true, params).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting tasks status from server");
        commit("SET_TASKS", res.data);
      });
    },
    removeTask({ commit }, task) {
      const resource = `/documents/task/${task.task_id}`;
      API.patch(resource, null, true).then(function() {
        commit("REMOVE_TASK", task);
      });
    },
    selectDocument({ commit }, document) {
      commit("SELECT_DOCUMENT", document);
    },
    setPendingDocuments({ commit }, documents) {
      commit("SET_PENDING_DOCUMENTS", documents);
    },
    setGeneratedDocuments({ commit }, documents) {
      commit("SET_GENERATED_DOCUMENTS", documents);
    },
    addGeneratedDocument({ commit }, documents) {
      commit("ADD_GENERATED_DOCUMENT", documents);
    }
  },
  mutations: {
    SET_DOCUMENTS(state, documents) {
      state.documents = documents;
    },
    SET_TASKS(state, tasks) {
      state.tasks = tasks;
    },
    REMOVE_TASK(state, taskToRemove) {
      const index = state.tasks.findIndex(
        task => task.task_id === taskToRemove.task_id
      );
      state.tasks.splice(index, 1);
    },
    SELECT_DOCUMENT(state, document) {
      state.selectedDocument = document;
    },
    SET_PENDING_DOCUMENTS(state, documents) {
      state.pendingDocuments = documents;
    },
    SET_GENERATED_DOCUMENTS(state, documents) {
      state.generatedDocuments = documents;
    },
    ADD_GENERATED_DOCUMENT(state, document) {
      state.generatedDocuments.push(document);
    },
    SET_LOAD_STATUS(state, loadStatus) {
      state.loadStatus = loadStatus;
    }
  }
};
