<template>
  <div class="carrier-quotes-table text-align-left">
    <!-- TABLE -->
    <span
      class="message accept-message"
      :class="{ hidden: !acceptMessage }"
    ></span>
    <br />
    <span
      class="message tender-message"
      :class="{ hidden: !tenderMessage }"
    ></span>
    <b-table
      :busy="busy"
      class="items-table shadowed mt-2"
      stacked="lg"
      hover
      show-empty
      head-variant="light"
      empty-text="There are no items to show"
      :items="items"
      :sortBy="sortBy"
      :fields="fields"
    >
      <template v-slot:table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #head()="row">
        <span>{{ row.label }}</span>
      </template>

      <template #cell()="row">
        <span
          :class="{
            applied: carrierApplied(row.index, row.item),
            accepted: row.item.accepted
          }"
        >
          {{ row.value }}
        </span>
      </template>

      <template #cell(action)="row">
        <!-- <div> -->
        <!-- <a class="action-button p-1" @click="tender(row.item, row.index)">
            Tender
          </a> -->
        <!-- </div> -->
        <b-overlay
          spinner-small
          :show="isLoading[row.index]"
          opacity="0.7"
          class="h-100"
          :key="render"
        >
          <b-button
            class="accepted-button"
            size="sm"
            @click="acceptCarrierQuote(row.item, row.index)"
            :disabled="isLoading[row.index]"
          >
            Accept
          </b-button>
        </b-overlay>
      </template>

      <template #cell(vendor)="row">
        <span
          :class="{
            applied: carrierApplied(row.index, row.item),
            accepted: row.item.accepted
          }"
        >
          {{ row.item.vendor.name || row.item.vendor.Name }}
        </span>
      </template>

      <template #cell(carrier_service)="row">
        <span
          :class="{
            applied: carrierApplied(row.index, row.item),
            accepted: row.item.accepted
          }"
        >
          {{ row.item.carrier_service.name || row.item.carrier_service.Name }}
        </span>
      </template>

      <!-- ACCEPTED TOGGLER TOGGLER -->
      <template v-slot:cell(accepted)="row">
        <b-form-checkbox
          switch
          disabled
          :checked="row.item.accepted"
          @change="changeStatus(row.item, $event)"
        ></b-form-checkbox>
      </template>

      <template #cell(book_it)="row">
        <b-form-checkbox
          switch
          disabled
          :checked="row.item.book_it"
        ></b-form-checkbox>
      </template>
    </b-table>

    <!-- PAGINATION -->
    <!-- Delete. -->
    <!-- <section class="pagination d-flex justify-content-center">
      <b-pagination
        v-model="page"
        :total-rows="tableSize"
        :per-page="rowsPerPage"
        aria-controls="carrier-quotes-table"
      >
      </b-pagination>
    </section> -->
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "CarrierQuotesTable",
  props: {
    fields: Array,
    items: Array,
    sortBy: String,
    tableSize: Number,
    // Delete.
    // currentPage: Number,
    busy: Boolean
  },
  data() {
    return {
      deleting: false,
      carrierAppliedIndex: -1,
      acceptMessage: false,
      tenderMessage: false,
      isLoading: [false],
      render: 0
    };
  },
  watch: {
    carrierQuoteAccepted: {
      deep: true,
      handler(newVal) {
        const index = this.carrierQuotes.findIndex(function(carrier) {
          return carrier.id === newVal.id;
        });
        this.carrierAppliedIndex = index;
      }
    }
  },
  computed: {
    ...mapGetters("load", ["selectedLoad"]),
    ...mapGetters("customerQuotes", ["picklists", "customerQuote", "contacts"]),
    ...mapGetters("carrierQuotes", [
      "carrierQuotes",
      "carrierQuoteAccepted",
      "showNewCarrierApplied"
    ])
    // Delete.
    // page: {
    //   get() {
    //     return this.currentPage;
    //   },
    //   set(currentPage) {
    //     this.$emit("setCurrentPage", currentPage);
    //   }
    // }
  },
  methods: {
    ...mapActions("load", ["getLoad", "updateLoad"]),
    ...mapActions("customerQuotes", ["updateCustomerQuote"]),
    ...mapActions("carrierQuotes", ["acceptCarrier", "tenderCarrier"]),
    ...mapMutations("customerQuotes", [
      "SET_NET_FREIGHT_CHARGES",
      "SET_CUSTOMER_QUOTE_TOTAL"
    ]),
    carrierApplied(index, rowItem) {
      // Delete.
      // Current position in table according to pagination
      // const currentPosition = (this.page - 1) * this.rowsPerPage + index;
      // return currentPosition === this.carrierAppliedIndex;
      return (
        this.showNewCarrierApplied?.id &&
        rowItem?.carrier_service?.name ===
          this.showNewCarrierApplied?.carrier_service?.name &&
        !rowItem?.accepted
      );
    },
    requiredFieldsOk(required) {
      let valid = true;
      required.forEach(function(field) {
        if (field === "") {
          valid = false;
        }
      });
      return valid;
    },
    getCustomerQuoteFields() {
      const loadId = this.selectedLoad.id;
      const contact = document.querySelector("#customer-quote-contact-input")
        .value;
      const status = document.querySelector("#customer-quote-status-select")
        .value;
      const customerTotal = document.querySelector(
        "#customer-pricing-quote-total"
      ).value;
      const currency = document.querySelector("#customer-quote-currency-select")
        .value;
      const contactId = this.findIdByNameFromArray(this.contacts, contact);
      const pricing = document.querySelector("#customer-quote-pricing-select")
        .value;
      const accCharges = document.querySelector(
        "#customer-pricing-accessorial-charges"
      ).value;
      const comments = document.querySelector("#customer-quote-comments-input")
        .value;
      const netFreightCharges = document.querySelector(
        "#customer-pricing-net-freight-charges"
      ).value;
      const fuelSurcharge = document.querySelector(
        "#customer-pricing-fuel-surcharge"
      ).value;
      const date = document.querySelector("#customer-quote-date").value;

      const requiredFields = [loadId, status, customerTotal, currency];
      const validRequiredFields = this.requiredFieldsOk(requiredFields);

      const customerQuote = {
        load_id: loadId,
        status,
        customer_quote_total: Number.parseFloat(customerTotal),
        current_iso_code: this.getPicklistValue(currency, this.currencyOptions),
        customer_contact: contactId,
        pricing: this.getPicklistValue(pricing, this.pricingOptions),
        accessorial_charges: Number.parseFloat(accCharges),
        comments,
        net_line_haul: Number.parseFloat(netFreightCharges),
        fuel_surcharge: Number.parseFloat(fuelSurcharge),
        quote_date: date
      };

      return [customerQuote, validRequiredFields];
    },
    setCustomerQuoteData(carrier) {
      const netFreightCharges = Number.parseFloat(
        (carrier.carrier_total + carrier.markup).toFixed(2)
      );
      this.SET_NET_FREIGHT_CHARGES(netFreightCharges);
      // this.SET_CUSTOMER_QUOTE_TOTAL(netFreightCharges);
    },
    async acceptCarrierQuote(carrier, index) {
      let accessorialsValidationStatus = this.$store.getters[
        "load/accessorialsValidationStatus"
      ];
      if (accessorialsValidationStatus) {
        this.acceptMessage = true;
        this.isLoading[index] = true;
        try {
          this.resetMessage(".accept-message");
          this.showMessage(".accept-message", "Accepting...", "info");
          // change carrier status to accepted
          this.carrierAppliedIndex = index;
          const params = {};
          params.carrier_id = carrier?.carrier_id;
          params.load_id = this.selectedLoad?.id;
          const response = await this.$store.dispatch(
            "carrierQuotes/acceptCarrierQuote",
            params
          );
          if (response.data === true) {
            this.isLoading[index] = false;
            await this.$store.dispatch("carrierQuotes/getCarriers", {
              loadId: this.selectedLoad?.id,
              loadStatus: this.selectedLoad?.load_status
            });
            await this.updateLoadFunction();
          }
          this.setCustomerQuoteData(carrier);
          if (this.isEmpty(carrier)) {
            throw { message: "No Carrier Quote to Accept" };
          }
          this.showMessage(
            ".accept-message",
            "Carrier Successfully Accepted",
            "success"
          );
        } catch (err) {
          this.render += 1;
          this.isLoading[index] = false;
          console.error("[CARRIER QUOTES TABLE (256)]", err.message);
          this.showMessage(
            ".accept-message",
            `Assign: ${err.message}`,
            "error"
          );
        }
      } else {
        this.showAlert({
          title: "Please remember!",
          alertType: "warning",
          text: "Refresh quotes to continue."
        });
      }
    },
    async updateLoadFunction() {
      const update = {
        carrier_id: this.carrierQuoteAccepted.vendor.id,
        customer_quote_total: this.carrierQuoteAccepted.customer_quote_total,
        carrier_service: this.carrierQuoteAccepted.carrier_service.id
      };
      await this.updateLoad({ loadId: this.selectedLoad.id, update });
    },
    resetMessage(className) {
      const element = this.$el.querySelector(className);
      element.classList.remove("message-error");
      element.classList.remove("message-success");
    },
    showMessage(className, message, type) {
      const element = this.$el.querySelector(className);
      element.innerHTML = message;
      if (type === "error") element.classList.add("message-error");
      else if (type === "success") element.classList.add("message-success");
      element.classList.remove("hidden");
    },
    // eslint-disable-next-line no-unused-vars
    async tender(carrier) {
      // this.tenderMessage = true;
      // try {
      //   this.resetMessage(".tender-message");
      //   this.showMessage(".tender-message", "Tendering...", "info");
      //   const [customerQuote, valid] = this.getCustomerQuoteFields();
      //   const dispatcher = document.querySelector(
      //     "#customer-quote-dispatcher-input > .input"
      //   ).value;
      //   // Validations
      //   if (!valid) throw { message: "Required Customer Quote Fields Missing" };
      //   if (dispatcher === "") throw { message: "Dispatcher Required" };
      //   if (this.isEmpty(carrier)) throw { message: "No Carrier to Tender" };
      //   // Tender carrier
      //   const tender = {
      //     load_id: this.selectedLoad.id,
      //     quote_id: carrier.id
      //   };
      //   await this.tenderCarrier({ tender, customerQuote });
      //   // No customer quote error
      //   if (typeof this.customerQuote === "undefined")
      //     throw { message: "No Customer Quote Found" };
      //   const customerQuoteTotalValue = document.querySelector(
      //     "#customer-pricing-quote-total"
      //   ).value;
      //   const customerQuoteTotal = Number.parseFloat(customerQuoteTotalValue);
      //   const fuelSurcharge =
      //     Number.parseFloat(customerQuote.fuelSurcharge) || 0;
      //   const accCharges = Number.parseFloat(customerQuote.accCharges) || 0;
      //   const netFreightCharges =
      //     customerQuoteTotal - accCharges - fuelSurcharge;
      //   // updates customer quote
      //   const customerQuoteUpdate = {
      //     id: this.customerQuote.id,
      //     net_line_haul: netFreightCharges
      //   };
      //   await this.updateCustomerQuote({
      //     loadId: this.selectedLoad.id,
      //     customerQuote: customerQuoteUpdate
      //   });
      //   // updates load carrier and load total customer quote
      //   const loadUpdate = {
      //     carrier_id: carrier.vendor.id,
      //     carrier_service: carrier.carrier_service.id,
      //     customer_quote_total: this.selectedLoad.load_details.customer_quote_total
      //   };
      //   await this.updateLoad({
      //     loadId: this.selectedLoad.id,
      //     update: loadUpdate
      //   });
      //   this.showMessage(".tender-message", "Tender Successful", "success");
      // } catch (err) {
      //   console.error(err.message);
      //   this.showMessage(".tender-message", `Tender: ${err.message}`, "error");
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
.carrier-quotes-table {
  text-align: left;
}

.b-table {
  border-radius: 6px;
  font-size: 13px;
  text-align: center;
}

.action-button {
  cursor: pointer;
  color: $color-secondary-button;
  font-weight: bold;
}

.applied {
  font-weight: bold;
  color: $color-secondary-button;
}

.accepted {
  font-weight: bold;
  color: $color-user-active;
}

.accepted-button {
  @include secondary-button;
}
.message {
  font-weight: bold;
}

.message-error {
  color: $color-user-busy;
}

.message-success {
  color: $color-user-active;
}
</style>
