// import * as api from "./services";

export default {
  namespaced: true,
  state: {
    salesPerformance: {}
  },
  getters: {
    salesPerformance(state) {
      return state.salesPerformance;
    }
  },
  mutations: {
    SET_SALES_PERFORMANCE(state, payload) {
      state.salesPerformance = payload;
    }
  }
};
