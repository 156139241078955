<template>
  <div id="app">
    <Header v-if="$route.name != 'Login'" />
    <div v-if="$route.name != 'Login'" class="line-header"></div>
    <router-view class="app-content" />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      department: null
    };
  },
  async created() {
    let tokenInfo = "";
    if (
      localStorage.getItem("charlie-uploader-actual-department") != undefined
    ) {
      tokenInfo = JSON.parse(
        localStorage.getItem("charlie-uploader-actual-department")
      );
      this.department = localStorage.getItem(
        "charlie-uploader-actual-department"
      );
      this.$store.commit(
        "login/setUserDepartment",
        JSON.parse(this.department)
      );
      this.$store.commit(
        "login/setToken",
        JSON.parse(this.department).access_token
      );
    } else {
      this.$store.commit("login/setUserDepartment", {});
    }
    if (localStorage.getItem("charlie-uploader-user") != undefined) {
      let user = localStorage.getItem("charlie-uploader-user");
      this.$store.commit("login/setUserLoggedIn", JSON.parse(user));
    }
    if (
      !localStorage.getItem("charlie-uploader-user") ||
      !localStorage.getItem("charlie-uploader-actual-department")
    ) {
      if (this.$route.path == "/login") {
        this.$router.resolve({ name: "Login" });
      } else {
        this.$router
          .push({ name: "Login" })
          .catch(() => this.handleNavigationDuplicated);
      }
    }
    if (tokenInfo != "") {
      const currentDate = Math.floor(Date.now() / 1000);
      const tokenDecoded = this.$jwt.decode(tokenInfo.access_token);
      if (currentDate > tokenDecoded.exp) {
        this.$router.push({ name: "Login" });
      }
    }
  }
};
</script>

<style lang="scss">
@import "./CSS/globals.scss";

html {
  position: relative;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.line-header {
  background-color: $color-primary-company;
  height: 3px;
}

.form-control {
  background-color: $color-background-input !important;
  &:hover {
    @include box-shadow;
  }
  &:focus {
    border-color: get-opacity($color-gray-shadow, 0.8) !important;
    box-shadow: 0 0 0 0.2rem get-opacity($color-gray-shadow, 0.4) !important;
  }
}
</style>
