<template>
  <div class="sales-performance">
    <CollapsingContent
      title="Sales Performance"
      icon="truck-moving"
      content="sales-performance"
    >
      <div class="sales-performance__content">
        <div class="sales-performance__content--cell">
          <label for="customer-sales-rep">
            Customer Sales Rep
          </label>
          <input
            id="customer-sales-rep"
            type="text"
            class="hover-input input-sm form-control"
            placeholder="Customer Sales Rep"
            :value="validate(salesPerformance, 'customer_sales_rep')"
            disabled
          />
        </div>

        <div class="sales-performance__content--cell">
          <label for="carrier-sales-rep">
            Carrier Sales Rep
          </label>
          <input
            id="carrier-sales-rep"
            type="text"
            class="hover-input form-control input-sm"
            placeholder="Carrier Sales Rep"
            :value="validate(salesPerformance, 'carrier_sales_rep')"
            disabled
          />
        </div>

        <div class="sales-performance__content--cell">
          <label for="customer-quote-total">
            Customer Quote Total
          </label>
          <input
            id="customer-quote-total"
            type="text"
            class="hover-input form-control"
            placeholder="Customer Quote Total"
            :value="validate(salesPerformance, 'customer_quote_total')"
            disabled
          />
        </div>

        <div class="sales-performance__content--cell">
          <label for="carrier-quote-total">
            Carrier Quote Total
          </label>
          <input
            id="carrier-quote-total"
            type="text"
            class="hover-input form-control"
            placeholder="Carrier Quote Total"
            :value="validate(salesPerformance, 'carrier_quote_total')"
            disabled
          />
        </div>

        <div class="sales-performance__content--cell">
          <label for="margin-(quoted)">
            Margin (Quoted)
          </label>
          <input
            id="margin-(quoted)"
            type="text"
            class="hover-input form-control"
            placeholder="Margin (Quoted)"
            :value="`USD ${validate(salesPerformance, 'margin_quoted')}`"
            disabled
          />
        </div>

        <div class="sales-performance__content--cell">
          <label for="margin-%-(quoted)">
            Margin % (Quoted)
          </label>
          <input
            id="margin-%-(quoted)"
            type="text"
            class="hover-input form-control"
            placeholder="Margin % (Quoted)"
            :value="validate(salesPerformance, 'margin_pct_quoted') + '%'"
            disabled
          />
        </div>
      </div>
    </CollapsingContent>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CollapsingContent from "@/components/CollapsingContent.vue";

export default {
  name: "SalesPerformance",
  components: {
    CollapsingContent
  },
  computed: {
    ...mapGetters("salesPerformance", ["salesPerformance"])
  }
};
</script>

<style lang="scss" scoped>
.sales-performance {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &--cell {
      @include form-cell;
    }
  }
}

@media (max-width: 700px) {
  .sales-performance__content {
    grid-template-columns: 1fr;
  }
}
</style>
