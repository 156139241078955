<template>
  <div class="login">
    <div class="login__content">
      <div class="login__content--representative-glt">
        <h1 class="text-center">
          <b>We Are The</b
          ><img
            src="https://shipwithglt.com/wp-content/uploads/2018/09/DOS-FLECHAS.svg"
            width="15%"
            height="15%"
          />
        </h1>
        <h1 class="text-center"><b>Experts You Need</b></h1>
      </div>
      <div class="login__content__form">
        <div class="login__content__form--logo" aria-label="image logo"></div>
        <hr />

        <div class="login__content__form__submit">
          <b-form v-on:submit.prevent>
            <b-form-group
              id="input-group-2"
              label="User:"
              class="login__content__form__submit--user-label"
              label-for="input-2"
            >
              <div
                class="login__content__form__submit--input d-flex justify-content-center"
              >
                <b-input-group-prepend is-text>
                  <b-icon
                    icon="person-fill"
                    class="login__content__form__submit--user-icon"
                  ></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  name="username"
                  id="username"
                  v-model="login.username"
                  type="text"
                  autocomplete="username"
                  required
                ></b-form-input>
              </div>
            </b-form-group>

            <b-form-group
              label="Password:"
              class="login__content__form__submit--user-label"
            >
              <div
                class="login__content__form__submit--input d-flex justify-content-center"
              >
                <b-input-group-prepend is-text>
                  <b-icon
                    icon="lock-fill"
                    class="login__content__form__submit--user-icon"
                  ></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  name="password"
                  id="password"
                  type="password"
                  v-on:keyup.enter="submit"
                  autocomplete="password"
                  v-model="login.password"
                  required
                ></b-form-input>
              </div>
            </b-form-group>
          </b-form>
        </div>

        <div class="login__content__form__submit__options">
          <b-overlay
            :show="busy"
            rounded
            opacity="0.6"
            spinner-small
            class="d-inline-block"
            @hidden="onHidden"
          >
            <b-button
              ref="button-accept"
              :disabled="busy"
              class="login__content__form__submit__options--button"
              @click="submit"
              ><b>Accept</b></b-button
            >
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      login: {
        username: null,
        password: null
      },
      busy: false
    };
  },
  methods: {
    ...mapActions("login", ["setLoginData"]),
    async submit() {
      try {
        this.busy = true;
        const loginData = await this.$store.dispatch("login/login", this.login);
        await this.setLoginData(loginData.data);
        this.$router
          .push({
            name: "Search"
          })
          .catch(() => this.handleNavigationDuplicated);
      } catch (err) {
        console.error("[LOGIN (126)]", err.message);
        this.login.password = "";
        let text;
        if (err.response && err.response.status === 400)
          text = "User or password incorrect";
        else text = err.message;
        this.showAlert({ title: "There is something wrong", text });
      } finally {
        this.busy = false;
      }
    },
    onHidden() {
      this.$refs["button-accept"].focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  min-height: 94.4vh;
  background-image: url("../assets/login.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px get-opacity($color-black, 0.6);

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    top: -10%;
    bottom: 6%;
    overflow: auto;
    @include justify-items-center;
    @include flex-wrap(center);

    &--representative-glt {
      @include justify-items-center;
      color: $color-white;

      h1 {
        font-size: 70px;
      }
    }

    &__form {
      @include justify-items-center;
      width: 450px;
      background-color: get-opacity($color-black, 0.7);
      border-radius: $border-radius;
      padding: 1%;
      box-shadow: 0 3px 4px 0 get-opacity($color-black, 90%),
        0 3px 3px -2px get-opacity($color-black, 20%),
        0 1px 8px 0 get-opacity($color-black, 90%);

      hr {
        background-color: $color-white;
      }

      &--logo {
        @include justify-items-center;
        content: url("../"+$image-company);
        width: 150px;
        height: auto;
      }

      &__submit {
        padding: 20px;
        &--user-label {
          color: $color-white;
          text-align: left;
        }
        &--user-icon {
          color: $color-black;
        }
        &--input {
          .form-control {
            border-radius: 0px $border-radius $border-radius 0px;
          }
          .input-group-text {
            background-color: $color-primary-company;
            color: $color-light;
            border: none;
            border-radius: $border-radius 0px 0px $border-radius;
          }
        }
        &__options {
          @include justify-items-center;
          margin: $margin-most-used 0px;

          &--button {
            @extend %submit-button;
          }
        }
      }
    }
  }
}

@media (max-width: 1225px) {
  h1,
  .h1 {
    display: none;
  }
  .login__content {
    flex-direction: column;
  }
}

@media (max-width: 470px) {
  .login__content__form {
    max-width: 300px;
  }
}
</style>
