import Vue from "vue";

import { capitalize, toTitle, toReadableDate } from "@/utils/helpers.js";
const fileName = {
  BILL_OF_LADING: "SIGNED BOL",
  LTL_BILL_OF_LADING: "BOL",
  LTL_SHIPPING_LABELS: "LTL LABELS",
  EASY_POST_LABEL: "EASY POST LABELS",
  DELIVERY_RECEIPT: "POD",
  WEIGHT_CERTIFICATE: "WEIGHT CERTIFICATE"
};
// VUE FILTERS
Vue.filter("capitalize", capitalize);
Vue.filter("toTitle", toTitle);
Vue.filter("toReadableDate", toReadableDate);
Vue.filter("formatName", value => {
  return fileName[value];
});
