var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"carrier-quotes-table text-align-left"},[_c('span',{staticClass:"message accept-message",class:{ hidden: !_vm.acceptMessage }}),_c('br'),_c('span',{staticClass:"message tender-message",class:{ hidden: !_vm.tenderMessage }}),_c('b-table',{staticClass:"items-table shadowed mt-2",attrs:{"busy":_vm.busy,"stacked":"lg","hover":"","show-empty":"","head-variant":"light","empty-text":"There are no items to show","items":_vm.items,"sortBy":_vm.sortBy,"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-success my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"head()",fn:function(row){return [_c('span',[_vm._v(_vm._s(row.label))])]}},{key:"cell()",fn:function(row){return [_c('span',{class:{
          applied: _vm.carrierApplied(row.index, row.item),
          accepted: row.item.accepted
        }},[_vm._v(" "+_vm._s(row.value)+" ")])]}},{key:"cell(action)",fn:function(row){return [_c('b-overlay',{key:_vm.render,staticClass:"h-100",attrs:{"spinner-small":"","show":_vm.isLoading[row.index],"opacity":"0.7"}},[_c('b-button',{staticClass:"accepted-button",attrs:{"size":"sm","disabled":_vm.isLoading[row.index]},on:{"click":function($event){return _vm.acceptCarrierQuote(row.item, row.index)}}},[_vm._v(" Accept ")])],1)]}},{key:"cell(vendor)",fn:function(row){return [_c('span',{class:{
          applied: _vm.carrierApplied(row.index, row.item),
          accepted: row.item.accepted
        }},[_vm._v(" "+_vm._s(row.item.vendor.name || row.item.vendor.Name)+" ")])]}},{key:"cell(carrier_service)",fn:function(row){return [_c('span',{class:{
          applied: _vm.carrierApplied(row.index, row.item),
          accepted: row.item.accepted
        }},[_vm._v(" "+_vm._s(row.item.carrier_service.name || row.item.carrier_service.Name)+" ")])]}},{key:"cell(accepted)",fn:function(row){return [_c('b-form-checkbox',{attrs:{"switch":"","disabled":"","checked":row.item.accepted},on:{"change":function($event){return _vm.changeStatus(row.item, $event)}}})]}},{key:"cell(book_it)",fn:function(row){return [_c('b-form-checkbox',{attrs:{"switch":"","disabled":"","checked":row.item.book_it}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }