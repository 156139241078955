import Vue from "vue";
import Vuex from "vuex";

import login from "./login.js";
import load from "./load.js";
import financialSummary from "./financialSummary.js";
import salesPerformance from "./salesPerformance.js";
import customerQuotes from "./customerQuotes.js";
import carrierQuotes from "./carrierQuotes.js";
import documents from "./documents.js";
import stops from "./stops.js";
import lineItem from "./lineItem.js";
import user from "./user.js";
import history from "./history.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    load,
    stops,
    lineItem,
    financialSummary,
    salesPerformance,
    customerQuotes,
    carrierQuotes,
    documents,
    login,
    user,
    history
  }
});
