import API from "./api/methods.js";

import { formatPicklist, mapPicklistObject } from "@/utils/helpers.js";

export default {
  namespaced: true,
  state: {
    customerQuotes: [],
    picklists: {},
    contacts: [{ id: "0031I000006recXQAQ", name: "Pacho Carlton" }],
    netFreightCharges: null,
    customerQuoteTotal: null
  },
  getters: {
    customerQuote(state) {
      return state.customerQuotes[0];
    },
    customerQuotes(state) {
      return state.customerQuotes;
    },
    customerQuotesLength(state) {
      return state.customerQuotes.length;
    },
    picklists(state) {
      return state.picklists;
    },
    contacts(state) {
      return state.contacts;
    },
    netFreightCharges(state) {
      return state.netFreightCharges;
    },
    customerQuoteTotal(state) {
      return state.customerQuoteTotal;
    }
  },
  actions: {
    generateCustomerQuoteRoute({ state }) {
      const baseDomain = process.env.VUE_APP_BASE_URL;
      if (baseDomain.includes("dev")) {
        return `https://gltlogistics--full.sandbox.lightning.force.com/lightning/r/rtms__CustomerQuote__c/${state.customerQuotes[0]?.id}/view`;
      }
      return `https://gltlogistics.lightning.force.com/lightning/r/rtms__CustomerQuote__c/${state.customerQuotes[0]?.id}/view`;
    },
    getCustomerQuotes({ commit }, loadId) {
      const resource = `/customer-quote/${loadId}`;
      return API.get(resource, true).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting customer quotes from the server");
        res.data.forEach(function(quote) {
          if (quote.accessorial_charges === null) quote.accessorial_charges = 0;
          if (quote.fuel_surcharge === null) quote.fuel_surcharge = 0;
        });
        commit("SET_CUSTOMER_QUOTES", res.data);
      });
    },
    createCustomerQuote({ dispatch }, customerQuote) {
      const resource = "/customer-quote";
      return API.post(resource, customerQuote, true).then(async function() {
        await dispatch("getCustomerQuotes", customerQuote.load_id);
      });
    },
    async getPicklists({ commit }) {
      return new Promise(function(resolve, reject) {
        try {
          const picklists = API.get("/customer-quote/picklists", true);
          const dispatcherPicklists = API.get("/user/dispatcher", true);
          const arrays = Promise.all([picklists, dispatcherPicklists]);
          resolve(arrays);
        } catch (err) {
          console.error("[CUSTOMER QUOTES (54)]", err.message);
          reject(err);
        }
      }).then(function(data) {
        if (typeof data === "undefined")
          throw new Error("Error retrieving data from server");
        const picks = data[0].data;
        const dispatcher = formatPicklist(data[1].data);
        const picklists = { ...picks, dispatcher };
        const mappedPicklists = mapPicklistObject(picklists);
        commit("SET_PICKLISTS", mappedPicklists);
      });
    },
    updateCustomerQuote({ dispatch }, { loadId, customerQuote }) {
      const resource = `/customer-quote/${customerQuote.id}`;
      delete customerQuote.id;
      return new Promise((resolve, reject) => {
        try {
          API.patch(resource, customerQuote, true)
            .then(() => {
              dispatch("getCustomerQuotes", loadId);
            })
            .then(() => {
              resolve();
            });
        } catch (err) {
          reject(err);
        }
      });
    },
    getContactList({ commit, state }, contactName) {
      const resource = "/contact/list";
      const params = { name: contactName };
      if (state.customerQuotes.length !== 0)
        commit("SET_CUSTOMER_QUOTE_KEY", {
          key: "customer_contact",
          value: {
            ...state.customerQuotes[0].customer_contact,
            name: contactName
          }
        });
      return API.get(resource, true, params).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting contacts from server");
        const contacts = formatPicklist(res.data);
        commit("SET_CONTACTS", contacts);
      });
    }
  },
  mutations: {
    SET_CUSTOMER_QUOTES(state, customerQuotes) {
      state.customerQuotes = customerQuotes;
    },
    SET_PICKLISTS(state, picklists) {
      state.picklists = picklists;
    },
    SET_CONTACTS(state, contacts) {
      state.contacts = contacts;
    },
    SET_CUSTOMER_QUOTE_KEY(state, { key, value }) {
      state.customerQuotes[0][key] = value;
    },
    SET_NET_FREIGHT_CHARGES(state, netFreightCharges) {
      state.netFreightCharges = netFreightCharges;
    },
    SET_CUSTOMER_QUOTE_TOTAL(state, customerQuoteTotal) {
      state.customerQuoteTotal = customerQuoteTotal;
    }
  }
};
