<template>
  <div class="carrier-pricing card p-3">
    <div class="carrier-pricing-header d-flex justify-content-between">
      <strong>Carrier Pricing</strong>
      <button class="btn-sm calculate-button" @click="calculate">
        Calculate
      </button>
      <!-- <button class="btn-sm calculate-button" @click="addAccessorial">
        Add Accessorial
      </button> -->
    </div>
    <!-- <div class="form-group row mt-4">
      <span>Accessorials</span>
      <ul>
        <li :key="accessorial.label" v-for="accessorial in accessorials">
          <input
            type="text"
            class="hover-input form-control form-control-sm"
            id="carrier-pricing-net-freight-charges"
            placeholder="Net Freight Charges"
            :value="validate(carrierQuoteAccepted, 'net_line_haul')"
          />
        </li>
      </ul>
    </div> -->
    <div class="form-group row mt-4">
      <div class="col-sm-6">
        <label class="control-label" for="carrier-pricing-net-freight-charges"
          >Net Freight Charges</label
        >
        <input
          type="text"
          class="hover-input form-control form-control-sm"
          id="carrier-pricing-net-freight-charges"
          placeholder="Net Freight Charges"
          :value="validate(carrierQuoteAccepted, 'net_line_haul')"
        />
      </div>
      <div class="col-sm-6">
        <label for="carrier-pricing-fuel-surcharge">Fuel Surcharge</label>
        <input
          type="text"
          class="form-control form-control-sm"
          id="carrier-pricing-fuel-surcharge"
          placeholder="Fuel Surcharge"
          :value="validate(carrierQuoteAccepted, 'fuel_surcharge')"
        />
      </div>
    </div>

    <template v-for="accessorial in carrierQuoteAccepted.accessorial">
      <div :key="validate(accessorial, 'name')" class="form-group row">
        <div class="col-sm-6">
          <label :for="`carrier-pricing-${validate(accessorial, 'name')}`">
            {{ validate(accessorial, "name") }}
          </label>
        </div>
        <div class="col-sm-3">
          <input
            type="text"
            class="form-control form-control-sm"
            :id="`carrier-pricing-${accessorial.name}`"
            :placeholder="accessorial.name"
            :value="accessorial.charge"
          />
        </div>
        <div class="col-sm-3">
          <b-form-select
            size="sm"
            class="hover-input"
            id="carrier-quote-currency-input"
            :options="picklists.currency_iso_code.labels"
            :value="
              getPicklistLabel(
                validate(accessorial, 'currency_iso_code'),
                picklists.currency_iso_code
              )
            "
          ></b-form-select>
        </div>
      </div>
    </template>

    <div class="form-group row">
      <div class="col-sm-6">
        <label for="carrier-pricing-accessorial-charges">
          Accessorial Charges
        </label>
        <input
          type="text"
          class="hover-input form-control form-control-sm"
          id="carrier-pricing-accessorial-charges"
          placeholder="Accessorial Charges"
          :value="validate(carrierQuoteAccepted, 'accessorial_charges')"
        />
      </div>
      <div class="col-sm-6">
        <label for="carrier-pricing-quote-total">Carrier Total</label>
        <input
          type="text"
          class="form-control form-control-sm"
          id="carrier-pricing-quote-total"
          placeholder="Carrier Total"
          :value="validate(carrierQuoteAccepted, 'carrier_total')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CarrierPricing",
  computed: {
    ...mapGetters("load", ["picklists"]),
    ...mapGetters("carrierQuotes", ["carrierQuoteAccepted"])
  },
  methods: {
    ...mapActions("load", ["addAccessorialToLoad"]),
    calculate() {
      const netFreightCharges =
        Number.parseFloat(
          this.$el.querySelector("#carrier-pricing-net-freight-charges").value
        ) || 0;
      const fuelSurcharge =
        Number.parseFloat(
          this.$el.querySelector("#carrier-pricing-fuel-surcharge").value
        ) || 0;
      const accessorialCharges =
        Number.parseFloat(
          this.$el.querySelector("#carrier-pricing-accessorial-charges").value
        ) || 0;
      const sum = netFreightCharges + fuelSurcharge + accessorialCharges;
      this.$el.querySelector("#carrier-pricing-quote-total").value = sum;
    }
  }
};
</script>

<style lang="scss" scoped>
.calculate-button {
  @include primary-button;
}
</style>
