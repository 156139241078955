<template>
  <div class="pdf-viewer shadowed mt-4">
    <template v-for="page in selectedDocument.pages">
      <pdf :key="page" :src="selectedDocument.src" :page="page"></pdf>
    </template>
  </div>
</template>

<script>
import pdf from "vue-pdf";

import { mapGetters } from "vuex";

export default {
  components: {
    pdf
  },
  computed: {
    ...mapGetters("documents", ["selectedDocument"])
  }
};
</script>

<style lang="scss" scoped>
.pdf-viewer {
  width: 70%;
  margin: 0 auto;
}
</style>
