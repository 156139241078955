/* eslint-disable no-unused-vars */
import API from "./api/methods.js";
import swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    lineItems: [],
    initialLineItems: [],
    itemSelects: {
      weightUnits: [],
      handUnits: [],
      dimUnits: [],
      nmfcClasses: [],
      packagingUnits: []
    },
    hazmatSelects: {
      numberTypes: [],
      classes: [],
      packagingGroups: [],
      contactsList: {}
    },
    creatingLineItem: false,
    savingBuffer: {
      inQueue: 0,
      failed: false
    },
    loadingNmfc: false,
    loadingContacts: false,
    savingHazmat: false
  },
  getters: {
    initialLineItems(state) {
      return state.initialLineItems;
    },
    lineItems(state) {
      return state.lineItems;
    },
    itemSelects(state) {
      return state.itemSelects;
    },
    hazmatSelects(state) {
      return state.hazmatSelects;
    },
    creatingLineItem(state) {
      return state.creatingLineItem;
    },
    savingBuffer(state) {
      return state.savingBuffer;
    },
    loadingNmfc(state) {
      return state.loadingNmfc;
    },
    loadingContacts(state) {
      return state.loadingContacts;
    },
    savingHazmat(state) {
      return state.savingHazmat;
    }
  },
  mutations: {
    setLineItems(state, newLineItems) {
      state.initialLineItems = [];
      state.lineItems = [];
      for (let i in newLineItems) {
        let lineItem = newLineItems[i];
        let item = {};
        let initialItem = {};

        item.id = lineItem.line_item_base.id;
        item.is_checked = false;
        item.item_number = lineItem.line_item_base.name;
        item.item_description = lineItem.line_item_base.description;
        item.weight = lineItem.line_item_base.weight;
        item.weight_units = lineItem.line_item_base.weight_units;
        item.hu_count = lineItem.line_item_base.handling_unit_count;
        item.handling_units = lineItem.line_item_base.handling_units;
        item.length = lineItem.line_item_base.length;
        item.width = lineItem.line_item_base.width;
        item.height = lineItem.line_item_base.height;
        item.dimensions_units = lineItem.line_item_base.dimension_units;
        item.nmfc_class = lineItem.line_item_base.nmfc_class;
        item.nmfc_number = lineItem.line_item_base.nmfc_number;
        item.packaging_unit_count =
          lineItem.line_item_base.packaging_unit_count;
        item.packaging_units = lineItem.line_item_base.packaging_units;
        item.linear_feet = lineItem.line_item_base.linear_feet;
        item.pickup_stop = lineItem.line_item_base.pickup_stop;
        item.delivery_stop = lineItem.line_item_base.delivery_stop;
        item.density = lineItem.line_item_base.density;
        item.hazmat = lineItem.hazmat.hazardous_materials;

        item.hazmatFields = {
          hazmat_class_division: lineItem.hazmat.hazmat_class_division,
          hazmat_contact: lineItem.hazmat.hazmat_contact
            ? lineItem.hazmat.hazmat_contact
            : {
                id: null,
                name: null
              },
          hazmat_number: lineItem.hazmat.hazmat_number,
          hazmat_number_type: lineItem.hazmat.hazmat_number_type,
          hazmat_packaging_group: lineItem.hazmat.hazmat_packaging_group
        };

        initialItem.is_initial = true;
        initialItem.id = lineItem.line_item_base.id;
        initialItem.is_checked = false;
        initialItem.item_number = lineItem.line_item_base.name;
        initialItem.item_description = lineItem.line_item_base.description;
        initialItem.weight = lineItem.line_item_base.weight;
        initialItem.weight_units = lineItem.line_item_base.weight_units;
        initialItem.hu_count = lineItem.line_item_base.handling_unit_count;
        initialItem.handling_units = lineItem.line_item_base.handling_units;
        initialItem.length = lineItem.line_item_base.length;
        initialItem.width = lineItem.line_item_base.width;
        initialItem.height = lineItem.line_item_base.height;
        initialItem.dimensions_units = lineItem.line_item_base.dimension_units;
        initialItem.nmfc_class = lineItem.line_item_base.nmfc_class;
        initialItem.linear_feet = lineItem.line_item_base.linear_feet;
        initialItem.pickup_stop = lineItem.line_item_base.pickup_stop;
        initialItem.delivery_stop = lineItem.line_item_base.delivery_stop;
        initialItem.density = lineItem.line_item_base.density;
        initialItem.hazmat = lineItem.hazmat.hazardous_materials;

        initialItem.hazmatFields = {
          hazmat_class_division: lineItem.hazmat.hazmat_class_division,
          hazmat_contact: lineItem.hazmat.hazmat_contact
            ? {
                id: lineItem.hazmat.hazmat_contact.id,
                name: lineItem.hazmat.hazmat_contact.name
              }
            : {
                id: null,
                name: null
              },
          hazmat_number: lineItem.hazmat.hazmat_number,
          hazmat_number_type: lineItem.hazmat.hazmat_number_type,
          hazmat_packaging_group: lineItem.hazmat.hazmat_packaging_group
        };

        state.lineItems.push(item);
        state.initialLineItems.push(initialItem);
      }
    },
    changeInitialItemField(state, args) {
      state.initialLineItems[
        state.initialLineItems.findIndex(item => item.id == args.itemId)
      ][args.field] = args.value;
    },
    changeHazmatFields(state, args) {
      state.initialLineItems[
        state.initialLineItems.findIndex(item => item.id == args.itemId)
      ].hazmatFields = args.hazmatFields;
      state.lineItems[
        state.lineItems.findIndex(item => item.id == args.itemId)
      ].hazmatFields = args.hazmatFields;
    },
    setSelects(state, selectsInfo) {
      state.itemSelects.weightUnits = selectsInfo.weight_units.map(
        wu => wu.label
      );
      state.itemSelects.handUnits = selectsInfo.handling_units.map(
        hu => hu.label
      );
      state.itemSelects.dimUnits = selectsInfo.dimension_units.map(
        du => du.label
      );
      state.itemSelects.nmfcClasses = selectsInfo.nmfc_class.map(
        nc => nc.label
      );
      state.itemSelects.packagingUnits = selectsInfo.packaging_units.map(
        pu => pu.label
      );
      state.hazmatSelects.numberTypes = selectsInfo.hazmat_number_type.map(
        option => option.label
      );
      state.hazmatSelects.classes = selectsInfo.hazmat_class_division.map(
        option => option.label
      );
      state.hazmatSelects.packagingGroups = selectsInfo.hazmat_packaging_group.map(
        option => option.label
      );
    },
    setCreatingItem(state, isCreatingItem) {
      state.creatingLineItem = isCreatingItem;
    },
    setSavingBuffer(state, args) {
      state.savingBuffer.inQueue += args.amount;
      state.savingBuffer.failed = args.failed;
    },
    setLoadingNmfc(state, isLoadingNmfc) {
      state.loadingNmfc = isLoadingNmfc;
    },
    setLoadingContacts(state, isLoadingContacts) {
      state.loadingContacts = isLoadingContacts;
    },
    setSavingHazmat(state, isSavingHazmat) {
      state.savingHazmat = isSavingHazmat;
    },
    setContactsList(state, contactsList) {
      state.hazmatSelects.contactsList = {};
      contactsList.slice(0, 11).forEach(contact => {
        state.hazmatSelects.contactsList[contact.id] = contact.name;
      });
    },
    changeItemChecked(state, [index, checked]) {
      state.lineItems[index].is_checked = checked;
    },
    removeItem(state, itemId) {
      state.lineItems.splice(
        state.lineItems.findIndex(item => item.id == itemId),
        1
      );
    },
    SET_ITEM_INDEX(state, index) {
      state.lineItems[index].item_number = `Item ${index + 1}`;
    },
    SORT_LINE_ITEMS(state) {
      state.lineItems.sort(function(item1, item2) {
        const matchA = item1.item_number.match(/\d+/);
        const matchB = item2.item_number.match(/\d+/);
        const a = matchA !== null ? matchA[0] : 0;
        const b = matchB !== null ? matchB[0] : 0;
        return a - b;
      });
    }
  },
  actions: {
    getLineItems({ commit }, loadId) {
      const resource = `/line-item/${loadId}`;
      API.get(resource, true).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting line items from server");
        commit("setLineItems", res.data);
        commit("SORT_LINE_ITEMS");
        // Add auto-incremental index to the line item name
        // dispatch("setItemsIndexes", 0);
      });
    },
    getSelectsOptions({ commit }) {
      API.get("/line-item/picklists", true).then(res => {
        if (typeof res === "undefined")
          throw new Error("Error getting line items picklists from server");
        commit("setSelects", res.data);
      });
    },
    createNewLineItem({ commit }, [item]) {
      commit("setCreatingItem", true);
      commit("setSavingBuffer", {
        amount: 1,
        failed: false
      });
      return new Promise(resolve => {
        API.post("/line-item", item, true)
          .then(() => {
            API.get(`/line-item/${item?.load_id}`, true)
              .then(async function(res) {
                if (typeof res === "undefined")
                  throw new Error("Error creating line item from server");
                commit("setCreatingItem", false);
                commit("setSavingBuffer", {
                  amount: -1,
                  failed: false
                });
                // Add auto-incremental index to the line item name
                commit("setLineItems", res.data);
                commit("SORT_LINE_ITEMS");
                // await dispatch("setItemsIndexes", state.lineItems.length - 1);
                resolve();
                return res;
              })
              .catch(() => {
                commit("setCreatingItem", false);
                commit("setSavingBuffer", {
                  amount: -1,
                  failed: true
                });
                swal.fire({
                  title: "There is something wrong",
                  text:
                    "Item was created but it's not visible. Please reload the page to see it",
                  icon: "error"
                });
                resolve();
              });
          })
          .catch(error => {
            commit("setCreatingItem", false);
            commit("setSavingBuffer", {
              amount: -1,
              failed: true
            });
            swal.fire({
              title: "Item wasn't created",
              text: "Please, try again",
              icon: "error"
            });
            resolve();
            return error;
          });
      });
    },
    editLineItemField({ commit, state }, args) {
      let payload = {};
      const fieldsDic = {
        item_number: "name",
        item_description: "description",
        weight: "weight",
        weight_units: "weight_units",
        hu_count: "handling_unit_count",
        handling_units: "handling_units",
        length: "length",
        width: "width",
        height: "height",
        dimensions_units: "dimension_units",
        nmfc_class: "nmfc_class",
        nmfc_number: "nmfc_number",
        packaging_unit_count: "packaging_unit_count",
        packaging_units: "packaging_units",
        linear_feet: "linear_feet",
        hazmat: "hazardous_materials",
        pickup_stop: "pickup_stop",
        delivery_stop: "delivery_stop",
        density: "density"
      };
      if (args.field == "hu_count") {
        payload[fieldsDic[args.field]] = parseInt(
          state.lineItems[
            state.lineItems.findIndex(item => item.id == args.itemId)
          ][args.field]
        );
      } else if (
        args.field == "height" ||
        args.field == "length" ||
        args.field == "weigth" ||
        args.field == "width" ||
        args.field == "linear_feet"
      ) {
        payload[fieldsDic[args.field]] = parseFloat(
          state.lineItems[
            state.lineItems.findIndex(item => item.id == args.itemId)
          ][args.field]
        );
      } else {
        payload[fieldsDic[args.field]] =
          state.lineItems[
            state.lineItems.findIndex(item => item.id == args.itemId)
          ][args.field];
        commit("SORT_LINE_ITEMS");
      }
      commit("setSavingBuffer", {
        amount: 1,
        failed: false
      });
      API.patch(`/line-item/info-base/${args.itemId}`, payload, true)
        .then(() => {
          commit("changeInitialItemField", {
            itemId: args.itemId,
            field: args.field,
            value: payload[fieldsDic[args.field]]
          });
          commit("setSavingBuffer", {
            amount: -1,
            failed: false
          });
        })
        .catch(() => {
          commit("setSavingBuffer", {
            amount: -1,
            failed: true
          });
        });
    },
    getNmfc({ commit }, args) {
      return new Promise(resolve => {
        commit("setLoadingNmfc", true);
        API.post("/operations/nmfc", args, true)
          .then(res => {
            if (typeof res === "undefined")
              throw new Error("Error getting nmfc data from server");
            commit("setLoadingNmfc", false);
            resolve(res.data);
          })
          .catch(() => {
            commit("setLoadingNmfc", false);
            resolve({
              nmfc: ""
            });
            swal.fire({
              title: "There is something wrong",
              text: "NMFC didn't load",
              icon: "error"
            });
          });
      });
    },
    editHazmatFields({ commit }, args) {
      let payload = args.hazmat;
      commit("setSavingHazmat", true);
      return new Promise(resolve => {
        API.patch(`/line-item/info-hazmat/${args.itemId}`, payload, true)
          .then(() => {
            payload.hazmat_contact = {
              name: args.contactName,
              id: payload.hazmat_contact
            };
            commit("changeHazmatFields", {
              itemId: args.itemId,
              hazmatFields: payload
            });
            commit("setSavingHazmat", false);
            resolve();
          })
          .catch(() => {
            commit("setSavingHazmat", false);
            swal.fire({
              title: "Hazmat wasn't changed",
              text: "Please, try again",
              icon: "error"
            });
            resolve();
          });
      });
    },
    searchHazmatContacts({ commit }, contactName) {
      commit("setLoadingContacts", true);
      API.get(`/contact/list?name=${contactName}`, true)
        .then(res => {
          if (typeof res === "undefined")
            throw new Error("Error getting hazmat contacts from server");
          commit("setContactsList", res.data);
          commit("setLoadingContacts", false);
        })
        .catch(() => {
          commit("setLoadingContacts", false);
        });
    },
    removeItem({ commit, dispatch }, { itemId, itemIndex }) {
      commit("setSavingBuffer", {
        amount: 1,
        failed: false
      });
      API.delete(`/line-item/${itemId}`, true)
        .then(async () => {
          commit("removeItem", itemId);
          // When the item is deleted, proceed to rename the remaining items
          // await dispatch("setItemsIndexes", itemIndex);
          commit("setSavingBuffer", {
            amount: -1,
            failed: false
          });
        })
        .catch(() => {
          commit("setSavingBuffer", {
            amount: -1,
            failed: true
          });
        });
    },
    setItemsIndexes({ commit, state }, lowerBound) {
      state.lineItems.forEach(function(item, index) {
        if (index >= lowerBound) {
          const payload = { name: `Item ${index + 1}` };
          API.patch(`/line-item/info-base/${item.id}`, payload, true).then(
            function() {
              commit("SET_ITEM_INDEX", index);
            }
          );
        }
      });
    }
  }
};
