import axios from "axios";
import store from "../../store";

const baseDomain = process.env.VUE_APP_BASE_URL;
const baseURL = `${baseDomain}/api`;

export const createAxios = authorization => {
  const token = authorization ? store.getters["login/showToken"] : null;
  return axios.create({
    baseURL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "content-type": "application/json",
      token
    },
    timeout: 300000
  });
};

export default createAxios;
