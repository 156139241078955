<template>
  <div class="customer-pricing card">
    <b-overlay :show="isLoading" opacity="0.7" class="h-100">
      <div class="customer-pricing-header d-flex justify-content-between pt-3">
        <strong>Customer Pricing</strong>
        <button class="btn-sm calculate-button" @click="calculate">
          Calculate
        </button>
        <AccessorialSection></AccessorialSection>
      </div>

      <div class="form-group row mt-4">
        <div class="col-sm-6">
          <label
            class="control-label"
            for="customer-pricing-net-freight-charges"
          >
            Net Freight Charges
          </label>
          <input
            ref="customer-pricing-net-freight-charges"
            type="text"
            class="hover-input form-control form-control-sm"
            id="customer-pricing-net-freight-charges"
            placeholder="Net Freight Charges"
            v-model.number="netLineHaul"
            @keyup.enter="updateField('net_line_haul', netLineHaul)"
            @keydown.tab="updateField('net_line_haul', netLineHaul)"
          />
        </div>
        <div class="col-sm-6">
          <label for="customer-pricing-fuel-surcharge">Fuel Surcharge</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="customer-pricing-fuel-surcharge"
            placeholder="Fuel Surcharge"
            v-model.number="fuelSurcharge"
            @keyup.enter="updateField('fuel_surcharge', fuelSurcharge)"
            @keydown.tab="updateField('fuel_surcharge', fuelSurcharge)"
          />
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-6">
          <label for="customer-pricing-accessorial-charges">
            Accessorial Charges
          </label>
          <input
            type="text"
            class="hover-input form-control form-control-sm"
            id="customer-pricing-accessorial-charges"
            placeholder="Accessorial Charges"
            v-model.number="accessorialCharges"
            @keyup.enter="
              updateField('accessorial_charges', accessorialCharges)
            "
            @keydown.tab="
              updateField('accessorial_charges', accessorialCharges)
            "
          />
        </div>
        <div class="col-sm-6">
          <label for="customer-pricing-quote-total">Customer Quote Total</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="customer-pricing-quote-total"
            placeholder="Customer Quote Total"
            v-model.number="quoteTotal"
            @keydown.tab="updateField('customer_quote_total', quoteTotal)"
            @keyup.enter="updateField('customer_quote_total', quoteTotal)"
            disabled
          />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import AccessorialSection from "./AccessorialsSection.vue";

export default {
  name: "CustomerPricing",
  components: {
    AccessorialSection
  },
  computed: {
    ...mapGetters("load", ["selectedLoad"]),
    ...mapGetters("customerQuotes", [
      "customerQuote",
      "customerQuotes",
      "netFreightCharges",
      "customerQuoteTotal"
    ]),
    ...mapGetters("carrierQuotes", ["carrierQuoteAccepted"]),
    netLineHaul: {
      get() {
        return this.netFreightCharges;
      },
      set(value) {
        this.SET_NET_FREIGHT_CHARGES(value);
      }
    },
    quoteTotal: {
      get() {
        return this.customerQuoteTotal;
      },
      set(value) {
        this.SET_CUSTOMER_QUOTE_TOTAL(value);
      }
    }
  },
  watch: {
    netLineHaul() {
      this.calculate();
    }
  },
  data() {
    return {
      selectedAccessorial: null,
      isLoading: false,
      fuelSurcharge: null,
      accessorialCharges: null
    };
  },
  methods: {
    ...mapActions("load", ["updateLoad"]),
    ...mapActions("customerQuotes", ["updateCustomerQuote"]),
    ...mapMutations("customerQuotes", [
      "SET_NET_FREIGHT_CHARGES",
      "SET_CUSTOMER_QUOTE_TOTAL"
    ]),
    initData() {
      this.SET_NET_FREIGHT_CHARGES(
        this.customerQuote ? this.customerQuote.net_line_haul : null
      );
      this.fuelSurcharge = this.customerQuote
        ? this.customerQuote.fuel_surcharge
        : null;
      this.accessorialCharges = this.customerQuote
        ? this.customerQuote.accessorial_charges
        : null;
      this.SET_CUSTOMER_QUOTE_TOTAL(
        this.customerQuote ? this.customerQuote.customer_quote_total : null
      );
    },
    calculate() {
      this.SET_CUSTOMER_QUOTE_TOTAL(
        this.netLineHaul + this.fuelSurcharge + this.accessorialCharges
      );
    },
    setCustomerQuoteTotal() {
      const charlieQuote = this.validate(
        this.selectedLoad.load_details,
        "customer_quote_total"
      );
      if (charlieQuote !== null && charlieQuote !== "")
        return charlieQuote.toFixed(2);
      else if (
        typeof this.carrierQuoteAccepted.customer_quote_total !== "undefined"
      )
        return this.carrierQuoteAccepted?.customer_quote_total?.toFixed(2);
      else return this.carrierQuoteAccepted?.customer_quote_total;
    },
    async changeCharlieQuote(customerQuoteTotal) {
      try {
        const loadId = this.selectedLoad.id;
        const update = {
          customer_quote_total: Number.parseFloat(customerQuoteTotal) || -99999
        };
        await this.updateLoad({ loadId, update });
      } catch (err) {
        console.error("[CUSTOMER PRICING (111)]", err.message);
        this.showAlert({ text: err.message });
      }
    },
    selectAccessorial(accessorial) {
      this.selectedAccessorial = accessorial;
    },
    async updateField(field, value) {
      try {
        this.isLoading = true;
        this.calculate();
        if (
          typeof this.customerQuote !== "undefined" &&
          this.customerQuote !== null
        ) {
          const update = {
            id: this.customerQuote.id,
            [field]: value,
            customer_quote_total: this.quoteTotal
          };
          await this.updateCustomerQuote({
            loadId: this.selectedLoad.id,
            customerQuote: update
          });
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        this.isLoading = false;
      }
    }
  },
  created() {
    this.initData();
  },
  mounted() {
    if (this.quoteTotal === null || this.quoteTotal === 0) this.calculate();
  }
};
</script>

<style lang="scss" scoped>
.calculate-button {
  @include primary-button;
}

.loading-message {
  font-weight: bold;
}
</style>
