<template>
  <div class="add-stop" :key="stopKey">
    <div class="add-stop__type text-center">
      <div class="add-stop__type--pickup">
        <span class="add-item__field--label text-left">Pickup</span>
        <b-form-checkbox
          v-model="stop.isPickup"
          name="check-button"
          switch
          value="true"
          unchecked-value="false"
        ></b-form-checkbox>
      </div>
      <div class="add-stop__type--delivery">
        <span class="add-item__field--label text-left">Delivery</span>
        <b-form-checkbox
          v-model="stop.isDelivery"
          name="check-button"
          switch
          value="true"
          unchecked-value="false"
        ></b-form-checkbox>
      </div>
    </div>
    <hr />
    <div class="add-stop__field">
      <span class="add-item__field--label text-left"
        >Location <span class="add-stop__field--required">*</span></span
      >
      <b-input-group size="sm">
        <b-form-input
          size="sm"
          list="location-list"
          placeholder="Location"
          autocomplete="off"
          @input="getLocation"
          v-model="stop.location"
        ></b-form-input>
        <datalist id="location-list">
          <option v-for="location in locations" v-bind:key="location.id">
            {{ location.name }}
          </option>
        </datalist>
        <b-input-group-append>
          <b-button
            id="addNewLocation"
            class="add-stop__field--button"
            @click="addNewLocation"
            >Add New</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </div>

    <hr v-if="addLocation" />
    <AddLocation v-if="addLocation" v-model="cancelAddLocation" />
    <hr v-if="addLocation" />

    <div class="add-stop__field">
      <span class="add-item__field--label text-left"
        >Address <span class="add-stop__field--required">*</span></span
      >
      <b-form-input
        v-model="stop.address"
        size="sm"
        type="text"
        placeholder="Address"
        autocomplete="off"
      ></b-form-input>
    </div>

    <div class="add-stop__field">
      <span class="add-item__field--label text-left">Country</span>
      <b-form-input
        v-model="stop.country"
        size="sm"
        type="text"
        placeholder="Country"
        autocomplete="off"
        readonly
      ></b-form-input>
    </div>

    <div class="add-stop__field">
      <span class="add-item__field--label text-left">State</span>
      <b-form-input
        v-model="stop.state"
        size="sm"
        type="text"
        placeholder="State"
        autocomplete="off"
        readonly
      ></b-form-input>
    </div>

    <div class="add-stop__field">
      <span class="add-item__field--label text-left">City</span>
      <b-input-group size="sm">
        <b-form-input
          size="sm"
          list="city-list"
          placeholder="city"
          autocomplete="off"
          v-on:keyup.enter="searchByCity"
          @input="selectCity"
          v-model="stop.city"
          :key="cityKey"
        ></b-form-input>
        <datalist id="city-list">
          <option v-for="city in shippingCityList" v-bind:key="city.text">
            {{ city.text }}
          </option>
        </datalist>
        <b-input-group-append>
          <b-button class="add-stop__field--button" @click="searchByCity"
            ><i class="fas fa-search"></i
          ></b-button>
        </b-input-group-append>
      </b-input-group>
    </div>

    <div class="add-stop__field">
      <span class="add-item__field--label text-left">Postal Code</span>
      <b-input-group size="sm">
        <b-form-input
          size="sm"
          list="zip-code-list"
          placeholder="Postal code"
          autocomplete="off"
          v-on:keyup.enter="searchByZipCode"
          @input="selectZipCode"
          v-model="stop.postal_code"
          :key="zipCodeKey"
        ></b-form-input>
        <datalist id="zip-code-list">
          <option
            v-for="zipCode in shippingZipCodeList"
            v-bind:key="zipCode.text"
          >
            {{ zipCode.text }}
          </option>
        </datalist>
        <b-input-group-append>
          <b-button class="add-stop__field--button" @click="searchByZipCode"
            ><i class="fas fa-search"></i
          ></b-button>
        </b-input-group-append>
      </b-input-group>
    </div>

    <div class="add-stop__field">
      <span class="add-item__field--label text-left"
        >Shipping/Receiving Contact
        <span class="add-stop__field--required">*</span></span
      >
      <b-form-input
        size="sm"
        list="contact-list"
        placeholder="Shipping/Receiving Contact"
        autocomplete="off"
        v-model="stop.shippingReceivingContact"
      ></b-form-input>
      <datalist id="contact-list">
        <option v-for="contact in contactList" v-bind:key="contact.id">
          {{ contact.name }}
        </option>
      </datalist>
    </div>
    <div class="add-stop__field">
      <span class="add-item__field--label text-left"
        >Shipping/Receiving Hours
        <span class="add-stop__field--required">*</span></span
      >
      <b-form-input
        v-model="stop.shippingReceivingHours"
        size="sm"
        type="text"
        placeholder="Shipping/Receiving Hours"
        autocomplete="off"
      ></b-form-input>
    </div>
    <div class="add-stop__field">
      <span class="add-stop__field--label text-left"
        >Expected Date (<span class="add-stop__field--required"
          >*Required for Pickup</span
        >)</span
      >
      <b-form-datepicker
        v-model="stop.expectedDate"
        size="sm"
        locale="en"
        today-button
        reset-button
        close-button
        class="add-stop__field--data text-left"
        autocomplete="off"
      ></b-form-datepicker>
    </div>
    <div class="add-stop__field text-left">
      <span class="add-item__field--label text-left">Appointment Required</span>
      <b-form-checkbox
        v-model="stop.appointmentRequired"
        value="true"
        unchecked-value="false"
        name="check-button"
        switch
      ></b-form-checkbox>
    </div>
    <div class="add-stop__field">
      <span class="add-stop__field--label text-left">Appointment Time</span>
      <b-form-input
        v-model="stop.appointmentTime"
        size="sm"
        type="text"
        placeholder="Appointment Time"
        autocomplete="off"
      ></b-form-input>
    </div>
    <div class="add-stop__field">
      <span class="add-stop__field--label text-left"
        >Pickup/Delivery Number</span
      >
      <b-form-input
        v-model="stop.pickupDeliveryNumber"
        size="sm"
        type="text"
        placeholder="Pickup/Delivery Number"
        autocomplete="off"
      ></b-form-input>
    </div>
    <div class="add-stop__field">
      <span class="add-stop__field--label text-left">References</span>
      <b-form-input
        v-model="stop.references"
        size="sm"
        type="text"
        placeholder="References"
        autocomplete="off"
      ></b-form-input>
    </div>
    <div class="add-stop__field">
      <span class="add-stop__field--label text-left">Instructions</span>
      <b-form-textarea
        v-model="stop.instructions"
        class="add-stop__field--data text-left"
        size="sm"
        placeholder="Instructions"
        autocomplete="off"
      ></b-form-textarea>
    </div>
    <hr />
    <div class="add-stop__buttons">
      <b-button class="add-stop__buttons--cancel" size="sm" @click="cancel"
        ><i class="fas fa-times-circle"></i> Cancel</b-button
      >
      <b-overlay
        :show="addingStop"
        rounded
        opacity="0.6"
        spinner-small
        class="d-inline-block"
        @hidden="onHidden"
      >
        <b-button
          ref="button-save-stop"
          class="add-stop__buttons--add-stop"
          size="sm"
          @click="addstop"
          ><i class="fas fa-check-circle"></i> Save Stop</b-button
        >
      </b-overlay>
    </div>
  </div>
</template>

<script>
import AddLocation from "./AddLocation";

export default {
  name: "AddStop",
  components: {
    AddLocation
  },
  data() {
    return {
      locations: [],
      stop: {
        isPickup: "false",
        isDelivery: "false",
        location: null,
        address: null,
        country: null,
        state: null,
        city: null,
        postal_code: null,
        shippingReceivingContact: null,
        shippingReceivingHours: null,
        expectedDate: null,
        appointmentRequired: "false",
        appointmentTime: null,
        pickupDeliveryNumber: null,
        references: null,
        instructions: null
      },
      addLocation: false,
      cancelAddLocation: null,
      count: 0,
      contactList: [],
      stopKey: 0,
      addingStop: false,
      shippingCityList: [],
      shippingZipCodeList: [],
      cityKey: 0,
      zipCodeKey: 0
    };
  },
  watch: {
    "stop.isPickup": function() {
      if (this.stop.isPickup == "true" && this.stop.isDelivery == "true") {
        this.stop.isDelivery = "false";
      }
    },
    "stop.isDelivery": function() {
      if (this.stop.isDelivery == "true" && this.stop.isPickup == "true") {
        this.stop.isPickup = "false";
      }
    },
    "stop.location": async function() {
      if (this.stop.location != "") {
        if (this.stop.isPickup == "false" && this.stop.isDelivery == "false") {
          this.showAlert({
            text: "You have to pick a stop type; pickup or delivery."
          });
        } else {
          if (this.stop.address == "" || this.stop.address == null) {
            await this.$store.dispatch(
              "stops/getLocations",
              this.stop.location
            );
          } else {
            let name = `${this.stop.location}?city=${this.stop.city}&state=${this.stop.state}&zip_code=${this.stop.postal_code}&type_tms=Shipper/Consignee`;
            await this.$store.dispatch("stops/getLocations", name);
          }
          this.locations = this.$store.getters["stops/locations"].slice(0, 10);
        }
      }
    },
    cancelAddLocation() {
      if (this.cancelAddLocation == false) {
        this.addLocation = false;
        this.cancelAddLocation = true;
      } else if (this.cancelAddLocation == "location added add stop") {
        this.addLocation = false;
        this.cancelAddLocation = null;
        this.stop.location = this.$store.getters[
          "stops/selectedLocation"
        ][0].name;
        this.setLocationInfo();
        this.stopKey += 1;
      }
    }
  },
  methods: {
    addNewLocation() {
      if (this.stop.isDelivery == "false" && this.stop.isPickup == "false") {
        this.showAlert({
          text: "You have to pick a stop type; pickup or delivery."
        });
      } else {
        this.addLocation = !this.addLocation;
      }
    },
    async getLocation() {
      for (let i in this.locations) {
        if (this.stop.location == this.locations[i].name) {
          await this.$store.dispatch(
            "stops/getLocationById",
            this.locations[i].id
          );
          this.setLocationInfo();
        }
      }
    },
    async setLocationInfo() {
      let account = this.$store.getters["stops/selectedLocation"];
      this.stop.address =
        account[0].shipping_address.street +
        ", " +
        account[0].shipping_address.city +
        ", " +
        account[0].shipping_address.state +
        " " +
        account[0].shipping_address.postal_code;
      await this.$store.dispatch(
        "stops/getContactList",
        this.$store.getters["stops/selectedLocation"][0].id
      );
      this.contactList = this.$store.getters["stops/contactList"];
      this.stop.shippingReceivingContact = this.contactList[0].name;
      if (this.stop.isPickup == "true") {
        this.stop.shippingReceivingHours =
          account[0].transportation_profile.pickup_receiving_hours;
        this.stop.appointmentRequired =
          account[0].transportation_profile.pickup_appointment_required;
        this.stop.instructions =
          account[0].transportation_profile.pickup_instructions;
      } else if (this.stop.isDelivery == "true") {
        this.stop.shippingReceivingHours =
          account[0].transportation_profile.delivery_receiving_hours;
        this.stop.appointmentRequired =
          account[0].transportation_profile.delivery_appointment_required;
        this.stop.instructions =
          account[0].transportation_profile.delivery_instructions;
      }
    },
    cancel() {
      this.count += 1;
      return this.$emit("input", this.count);
    },
    async searchByZipCode() {
      await this.$store.dispatch(
        "stops/getShippingZipCode",
        this.stop.postal_code
      );
      let options = [];
      for (let i in this.$store.getters["stops/shippingZipCode"]) {
        let option = {};
        option.value = this.$store.getters["stops/shippingZipCode"][i];
        option.text =
          this.$store.getters["stops/shippingZipCode"][i].city +
          ", " +
          this.$store.getters["stops/shippingZipCode"][i].state +
          " " +
          this.$store.getters["stops/shippingZipCode"][i].zip_code;
        options.push(option);
      }
      this.shippingZipCodeList = options;
    },
    async searchByCity() {
      await this.$store.dispatch("stops/getShippingCity", this.stop.city);
      let options = [];
      for (let i in this.$store.getters["stops/shippingCity"]) {
        let option = {};
        option.value = this.$store.getters["stops/shippingCity"][i];
        option.text =
          this.$store.getters["stops/shippingCity"][i].city +
          ", " +
          this.$store.getters["stops/shippingCity"][i].state +
          " " +
          this.$store.getters["stops/shippingCity"][i].zip_code;
        options.push(option);
      }
      this.shippingCityList = options;
    },
    selectZipCode(e) {
      for (let i in this.shippingZipCodeList) {
        if (this.shippingZipCodeList[i].text == e) {
          this.stop.country = this.shippingZipCodeList[i].value.country;
          this.stop.state = this.shippingZipCodeList[i].value.state;
          this.stop.city = this.shippingZipCodeList[i].value.city;
          this.stop.postal_code = this.shippingZipCodeList[i].value.zip_code;
          this.zipCodeKey += 1;
          this.stop.address = this.shippingZipCodeList[i].text;
        }
      }
    },
    selectCity(e) {
      for (let i in this.shippingCityList) {
        if (this.shippingCityList[i].text == e) {
          this.stop.country = this.shippingCityList[i].value.country;
          this.stop.state = this.shippingCityList[i].value.state;
          this.stop.postal_code = this.shippingCityList[i].value.zip_code;
          this.stop.city = this.shippingCityList[i].value.city;
          this.cityKey += 1;
          this.stop.address = this.shippingCityList[i].text;
        }
      }
    },
    async addstop() {
      if (this.stop.isDelivery == "false" && this.stop.isPickup == "false") {
        this.showAlert({
          text: "You have to pick a stop type; pickup or delivery."
        });
      } else {
        if (
          this.stop.location == null ||
          this.stop.location == "" ||
          this.stop.address == null ||
          this.stop.address == "" ||
          this.stop.shippingReceivingContact == null ||
          this.stop.shippingReceivingContact == "" ||
          this.stop.shippingReceivingHours == null ||
          this.stop.shippingReceivingHours == ""
        ) {
          this.showAlert({
            text: "Please, fill all required fields (the ones with a red star)."
          });
        } else {
          this.addingStop = true;
          let newStop = {};
          newStop.load_id = this.$route.params.loadId;
          newStop.name =
            "Stop " + (this.$store.getters["stops/loadStops"].length + 1);
          newStop.number = this.getStopNumber();
          newStop.location_id = this.$store.getters[
            "stops/selectedLocation"
          ][0].id;
          newStop.contact_info_id = await this.getContactId();
          newStop.shipping_receiving_hours = this.stop.shippingReceivingHours;
          newStop.expected_date = this.stop.expectedDate;
          newStop.appointmet_required = this.stop.appointmentRequired;
          newStop.appointment_time = this.stop.appointmentTime;
          newStop.is_dropoff = this.stop.isDelivery;
          newStop.is_pickup = this.stop.isPickup;
          newStop.pickup_delivery_number = this.stop.pickupDeliveryNumber;
          newStop.references = this.stop.references;
          newStop.instructions = this.stop.instructions;
          let info = {};
          info.body = newStop;
          info.loadId = this.$route.params.loadId;
          await this.$store.dispatch("stops/addNewStop", info);
          this.addingStop = false;
          return this.$emit("input", this.stop);
        }
      }
    },
    getStopNumber() {
      let max = parseInt(this.$store.getters["stops/loadStops"][0].number, 10);
      for (let i = 1; i < this.$store.getters["stops/loadStops"].length; i++) {
        if (
          parseInt(this.$store.getters["stops/loadStops"][i].number, 10) > max
        ) {
          max = parseInt(this.$store.getters["stops/loadStops"][i].number, 10);
        }
      }
      max += 1;
      return max;
    },
    getContactId() {
      for (let i in this.contactList) {
        if (this.stop.shippingReceivingContact == this.contactList[i].name) {
          return this.contactList[i].id;
        }
      }
    },
    onHidden() {
      this.$refs["button-save-stop"].focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.add-stop {
  font-size: 13px;
  &__type {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &__field {
    @include modal-form-field(30% 70%);
    input {
      font-size: 13px;
    }
    &--required {
      color: red;
      font-size: 10px;
    }
    &--data {
      font-size: 13px;
    }
    &--button {
      @include secondary-button;
      box-shadow: none;
    }
  }
  &__buttons {
    @include flex-wrap(flex-end);
    margin-top: 20px;
    &--cancel {
      margin-right: 10px;
      @include secondary-button;
    }
    &--add-stop {
      @include primary-button;
    }
  }
}
</style>
