<template>
  <div class="freight-summary mt-4">
    <template v-if="isEmpty(selectedLoad)">
      No Load Info Available
    </template>
    <template v-else>
      <section class="top-section d-flex flex-wrap justify-content-around py-2">
        <span
          ><strong>Load Number:</strong>
          {{ validate(selectedLoad, "name") }}</span
        >
        <span
          ><strong>Load Status:</strong>
          {{
            validate(showTenderResponse, "new_load_status") ||
              validate(selectedLoad, "load_status")
          }}</span
        >
        <span>
          <strong>Total Weight:</strong>
          {{ validate(selectedLoad, "load_details", "o").total_weight }}
          lbs
        </span>
        <span>
          <strong>Linear Feet:</strong>
          {{ validate(selectedLoad, "load_details", "o").linear_feet }}
        </span>
        <!-- <span><strong>Handling Units:</strong> 6 Bags 5 Pallets</span> -->
        <!-- <span><strong>Packaging Units: </strong></span> -->
        <span>
          <strong>Distance (mi):</strong>
          {{ validate(selectedLoad, "load_details", "o").distance_miles }}
        </span>
      </section>
      <section class="bottom-section mt-4">
        <SummaryTable :stops="loadStops" />
      </section>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// components
import SummaryTable from "@/components/FreightQuoteAssistant/SummaryTable.vue";

export default {
  name: "FreightSummary",
  components: {
    SummaryTable
  },
  computed: {
    ...mapGetters("load", ["selectedLoad", "showTenderResponse"]),
    ...mapGetters("stops", ["loadStops"])
  }
};
</script>
