<template>
  <div>
    <b-modal @hidden="hiddenModal" v-model="localModalShow">
      <div>
        <b-table responsive :items="infoTable" :fields="fields">
          <template #cell(actions)="row">
            <b-button size="sm" variant="outline-primary"
              ><a target="_blank" :href="row.item.url">Download</a></b-button
            >
          </template>
        </b-table>
      </div>
      <template #modal-footer="{ ok }">
        <b-button size="sm" variant="success" @click="ok()">
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    modalShow: {
      type: Boolean,
      default: false
    },
    infoTable: {
      type: Array
    }
  },
  data() {
    return {
      fields: ["shp", "actions"],
      localModalShow: false
    };
  },
  watch: {
    modalShow() {
      if (this.modalShow) {
        this.localModalShow = this.modalShow;
      }
    }
  },
  methods: {
    hiddenModal() {
      this.localModalShow = false;
      this.$emit("closeModal");
    }
  }
};
</script>
