<template>
  <div class="load-details-summary">
    <div class="d-flex flex-wrap justify-content-center">
      <div class="col-12 col-sm-6 col-md-4 col-lg-2 cell my-3 my-sm-1 my-lg-0">
        <CustomPicklist
          bold
          noMargin
          id="carrier-picklist"
          :data="picklists.carrier"
          :value="validate(selectedLoad.load_details, 'carrier', 'o').name"
          placeholder="Carrier"
          @onChange="
            onChange($event, 'carrier', picklists.carrier, 'carrier_id')
          "
        ></CustomPicklist>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 cell my-3  my-sm-1 my-lg-0">
        <CustomPicklist
          bold
          noMargin
          id="customer-picklist"
          :data="picklists.customer"
          :value="validate(selectedLoad.load_details, 'customer', 'o').name"
          placeholder="Customer"
          @onChange="
            onChange($event, 'customer', picklists.customer, 'customer_id')
          "
        ></CustomPicklist>
      </div>
      <div class="cell col-12 col-sm-4 col-md-4 col-lg-1 my-3 my-sm-1 my-lg-0">
        <strong>Mode</strong><br />
        <span class="d-inline-block mt-1">
          {{ validate(selectedLoad, "load_details").mode_name }}
        </span>
      </div>
      <div class="cell col-12 col-sm-4 col-md-4 col-lg-2 my-3 my-sm-1 my-lg-0">
        <strong>Service Class</strong><br />
        <span class="d-inline-block mt-1">
          {{ validate(selectedLoad, "load_details").service_class }}
        </span>
      </div>
      <div class="col-12 col-sm-4 col-md-4 col-lg-2 cell my-3 my-sm-1 my-lg-0">
        <CustomPicklist
          bold
          noMargin
          id="billing-picklist"
          :data="picklists.billing"
          :value="
            validate(selectedLoad.load_details, 'carrier_bill_to', 'o').name
          "
          placeholder="Carrier Bill To"
          @onChange="
            onChange($event, 'billing', picklists.billing, 'carrier_bill_id')
          "
        ></CustomPicklist>
      </div>
      <div class="cell col-12 col-sm-6 col-md-4 col-lg-2 my-3 my-sm-1 my-lg-0">
        <CustomPicklist
          bold
          noMargin
          id="documents-picklist"
          :data="picklists.documents"
          :value="
            validate(selectedLoad.load_details, 'documents_options', 'o').name
          "
          placeholder="Document Options"
          @onChange="
            updateLoadValue($event, picklists.documents, 'document_options_id')
          "
        ></CustomPicklist>
      </div>
    </div>
    <div class="loading-message mt-2" :class="{ hidden: !isLoading }">
      Loading...
    </div>
    <div
      class="loading-message mt-2"
      :class="{ hidden: !documentOptionsMissing }"
    >
      Document Options Missing...
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// Components
import CustomPicklist from "@/components/CustomPicklist.vue";

export default {
  name: "LoadDetailsSummary",
  components: {
    CustomPicklist
  },
  computed: {
    ...mapGetters("load", ["selectedLoad", "picklists"])
  },
  data() {
    return {
      isLoading: false,
      documentOptionsMissing: false
    };
  },
  methods: {
    ...mapActions("load", ["updateLoad", "getSummaryPicklist"]),
    async updateLoadValue(label, picklist, key) {
      this.isLoading = true;
      if (key === "document_options_id") {
        this.documentOptionsMissing =
          this.$el.querySelector("#documents-picklist > .input").value === "";
      }
      const value = this.getPicklistValue(label, picklist);
      if (typeof value !== "undefined") {
        const update = { [key]: value };
        await this.updateLoad({ loadId: this.selectedLoad.id, update });
      }
      this.isLoading = false;
    },
    async onChange(search, picklistName, picklist, key) {
      this.isLoading = true;
      const params = { [picklistName]: search };
      await this.getSummaryPicklist({ picklistName, params });
      this.isLoading = false;
      if (picklistName === "billing") {
        const documentsInput = this.$el.querySelector(
          "#documents-picklist > .input"
        );
        documentsInput.value = "";
        // Reset Document Options
        await this.updateLoadValue(
          "",
          this.picklists.documents,
          "document_options_id"
        );
      }
      await this.updateLoadValue(search, picklist, key);
    }
  }
};
</script>

<style lang="scss" scoped>
.cell {
  padding: 0 1rem;
  font-size: 13px;
}

.loading-message {
  font-weight: bold;
  color: $color-user-busy;
}
</style>
