<template>
  <div class="summary-table d-flex flex-wrap justify-content-center">
    <!-- Stops Section -->
    <template v-for="(stop, index) in stops">
      <div class="summary-table-cell my-2" :key="index">
        <div
          class="summary-table-cell-header d-flex justify-content-between p-2"
        >
          <span>
            <strong>{{ stop.name }}</strong>
            -
            <strong>{{
              validate(stop, "is_pickup") ? "Pickup" : "Delivery"
            }}</strong>
          </span>
          <span>
            <strong>{{ validate(stop, "departure_date") }}</strong>
          </span>
        </div>
        <div class="summary-table-body px-2 pt-4">
          {{ validate(stop, "address") }}<br />
          <!-- <strong>Accessorials: PENDING</strong> -->
        </div>
      </div>
    </template>

    <!-- Markup Section -->
    <div class="summary-table-cell my-2" key="markup">
      <div class="summary-table-cell-header d-flex justify-content-between p-2">
        <span><strong>Markup</strong></span>
      </div>
      <div class="summary-table-body p-2">
        <strong>Actual:</strong>
        USD {{ getActualMarkup().toFixed(2) }}
        <br />
        <strong>Target:</strong>
        USD {{ getValue("markup").toFixed(2) }}
        <br />
        <strong>Minimum:</strong>
        USD {{ getValue("minimum").toFixed(2) }}
      </div>
    </div>

    <!-- Margin Section -->
    <div class="summary-table-cell my-2" key="margin">
      <div class="summary-table-cell-header d-flex justify-content-between p-2">
        <span><strong>Margin</strong></span>
      </div>
      <div class="summary-table-body p-2">
        <strong>Actual %:</strong>
        {{ getMargin(getActualMarkup()) }} %
        <br />
        <strong>Target %:</strong>
        {{ getMargin(getValue("markup")) }} %
        <br />
        <strong>Minimum %:</strong>
        {{ getMargin(getValue("minimum")) }} %
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SummaryTable",
  props: {
    stops: Array
  },
  computed: {
    ...mapGetters("load", ["selectedLoad"]),
    ...mapGetters("carrierQuotes", ["carrierQuoteAccepted"]),
    ...mapGetters("customerQuotes", ["customerQuote"])
  },
  methods: {
    getValue(key) {
      if (this.isEmpty(this.carrierQuoteAccepted)) return 0;
      else return this.carrierQuoteAccepted[key];
    },
    getActualMarkup() {
      let markup = 0;
      try {
        const carrierTotal = this.isEmpty(this.carrierQuoteAccepted)
          ? 0
          : parseFloat(this.carrierQuoteAccepted?.carrier_total);
        // No Carrier
        if (carrierTotal !== 0) {
          const customerTotal = this.selectedLoad.load_details
            .customer_quote_total;
          markup = customerTotal - carrierTotal;
        }
      } catch (err) {
        console.error("[SUMMARY TABLE (94)]", err.message);
      }
      return markup;
    },
    getMargin(markup) {
      const customerTotal = this.carrierQuoteAccepted?.customer_quote_total;
      let margin = 0;
      if (typeof customerTotal !== "undefined" && customerTotal !== 0)
        margin = ((markup / customerTotal) * 100).toFixed(2);
      return margin;
    }
  }
};
</script>

<style lang="scss" scoped>
.summary-table-cell {
  background-color: #fff;
  min-width: 250px;
  border: 1px solid #ccc;

  .summary-table-cell-header {
    background-color: $color-primary-button;
    color: #fff;
  }
}
</style>
