import qs from "qs";
import API from "./api";
import store from "../../store";

export default {
  get: (resource, token, params = null) =>
    API(token)
      .get(resource, {
        params,
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: "repeat"
          });
        }
      })
      .catch(async err => {
        if (err.response && err.response.status == 403) {
          await store.dispatch("login/refreshToken");
          location.reload();
        } else {
          throw err;
        }
      }),
  post: (resource, payload, token, params = null) =>
    API(token)
      .post(resource, payload, {
        params,
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: "repeat"
          });
        }
      })
      .catch(async err => {
        if (err.response && err.response.status == 403) {
          await store.dispatch("login/refreshToken");
          location.reload();
        } else {
          throw err;
        }
      }),
  put: (resource, payload, token) =>
    API(token)
      .put(resource, payload)
      .catch(async err => {
        if (err.response && err.response.status == 403) {
          await store.dispatch("login/refreshToken");
          location.reload();
        } else {
          throw err;
        }
      }),
  patch: (resource, payload, token) =>
    API(token)
      .patch(resource, payload)
      .catch(async err => {
        if (err.response && err.response.status == 403) {
          await store.dispatch("login/refreshToken");
          location.reload();
        } else {
          throw err;
        }
      }),
  delete: (resource, token) =>
    API(token)
      .delete(resource)
      .catch(async err => {
        if (err.response && err.response.status == 403) {
          await store.dispatch("login/refreshToken");
          location.reload();
        } else {
          throw err;
        }
      })
};
