<template>
  <div class="customer-quote card p-3">
    <template>
      <div
        class="customer-quote-header d-flex flex-wrap justify-content-between"
      >
        <div class="col-12 col-md-6">
          <a
            v-if="validate(customerQuote, 'name')"
            @click="getCustomerQuoteRoute()"
            target="_blank"
            :href="customerQuoteRoute"
            ><strong>{{ validate(customerQuote, "name") }}</strong></a
          >
          <strong v-else>"No Customer Quote"</strong>
          <br />
          <span :class="{ hidden: !savingQuote }">Saving Quote...</span>
        </div>
        <button class="btn-sm save-button" @click="saveQuote">
          Save Quote
        </button>
      </div>
      <div class="form-group row mt-4">
        <div class="col-sm-6">
          <label class="control-label" for="customer-quote-contact-input"
            >Contact</label
          >
          <div class="form-group d-flex mb-0">
            <div class="col-10 px-0">
              <input
                list="contacts"
                type="text"
                class="hover-input form-control
                  form-control-sm"
                id="customer-quote-contact-input"
                placeholder="Contact"
                autocomplete="off"
                v-model="
                  getCustomerQuoteDefaultValue('customer_contact', 'o').name
                "
                @keyup.enter="searchContact"
                @change="changeContact($event.target.value)"
              />
              <datalist id="contacts">
                <template v-for="(contact, index) in contacts">
                  <option :key="index" :value="validate(contact, 'label')" />
                </template>
              </datalist>
            </div>
            <button
              class="search-contact-button btn-sm col-2"
              @click="searchContact"
            >
              <font-awesome-icon icon="search" />
            </button>
          </div>
          <div class="text-danger mt-2 " :class="{ hidden: !searchingMessage }">
            Searching contacts...
          </div>
          <div class="text-danger mt-2" :class="{ hidden: !errorMessage }">
            {{ errorMessage }}
          </div>
        </div>
        <div class="col-sm-6">
          <label for="customer-quote-date">Date</label>
          <input
            type="date"
            class="form-control form-control-sm"
            id="customer-quote-date"
            placeholder="Date"
            :value="getCustomerQuoteDefaultValue('quote_date')"
            @change="inputChange('quote_date', $event.target.value)"
          />
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-6">
          <label for="customer-quote-status-select">Status</label>
          <b-form-select
            ref="statusSelect"
            size="sm"
            class="hover-input"
            id="customer-quote-status-select"
            :options="picklists.status.labels"
            v-model="customerQuoteStatus"
            @change="selectChange('status', $event, picklists.status)"
          ></b-form-select>
        </div>
        <div class="col-sm-6">
          <label for="customer-quote-pricing-select">Pricing</label>
          <b-form-select
            size="sm"
            class="hover-input"
            id="customer-quote-pricing-select"
            :options="picklists.pricing.labels"
            :value="
              getPicklistLabel(
                getCustomerQuoteDefaultValue('pricing'),
                picklists.pricing
              )
            "
            @change="selectChange('pricing', $event, picklists.pricing)"
          ></b-form-select>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-6">
          <label for="customer-quote-comments-input">Comments</label>
          <textarea
            type="text"
            class="form-control form-control-sm"
            id="customer-quote-comments-input"
            placeholder="Comments"
            :value="validate(customerQuote, 'comments')"
            @blur="inputChange('comments', $event.target.value)"
          />
        </div>
        <div class="col-sm-6">
          <div class="col-sm-12 mt-2">
            <b-form-checkbox
              id="customer-quote-credit-hold-input"
              :checked="validate(customerQuote, 'credit_hold', 'b')"
              name="customer-quote-credit-hold-check"
              switch
              disabled
            >
              Credit Hold
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CustomerQuote",
  computed: {
    ...mapGetters("login", ["showUserLoggedIn"]),
    ...mapGetters("load", ["selectedLoad"]),
    ...mapGetters("customerQuotes", [
      "customerQuote",
      "customerQuotes",
      "picklists",
      "contacts"
    ])
  },
  watch: {
    customerQuoteStatus(newVal, oldVal) {
      try {
        if (newVal === "Accepted") {
          const creditHold = this.$el.querySelector(
            "#customer-quote-credit-hold-input"
          ).checked;
          if (creditHold) {
            this.customerQuoteStatus = oldVal;
            const select = this.$refs.statusSelect.$refs.input;
            select.value = oldVal;
            // Activates event to see select value changes
            select.dispatchEvent(new Event("change"));
            throw new Error(
              "Customer is on hold, please contact AR representative. Status Accepted."
            );
          }
        }
      } catch (err) {
        console.error("[CUSTOMER QUOTE (177)]", err.message);
        this.showAlert({ text: err.message });
      }
    }
  },
  data() {
    return {
      savingQuote: false,
      searchingMessage: false,
      errorMessage: null,
      customerQuoteStatus: "",
      defaults: {
        customer_contact: {
          id: "0031I000006recXQAQ",
          name: "Pacho Carlton"
        },
        quote_date: new Date().toISOString().slice(0, 10),
        status: "Pending",
        pricing: "Spot",
        currency: "USD"
      },
      customerQuoteRoute: ""
    };
  },
  methods: {
    ...mapActions("load", ["updateLoad"]),
    ...mapActions("customerQuotes", [
      "getPicklists",
      "updateCustomerQuote",
      "getContactList",
      "createCustomerQuote",
      "generateCustomerQuoteRoute"
    ]),
    getCustomerQuoteDefaultValue(key, type = "s") {
      const value = this.validate(this.customerQuote, key, type);
      if (type === "o" && !this.isEmpty(value)) return value;
      if (type === "a" && value.length !== 0) return value;
      if (type === "s" && value !== "") return value;
      return this.defaults[key];
    },
    async changeContact(contact) {
      let contactId = this.findIdByNameFromArray(this.contacts, contact);
      if (typeof contactId !== "undefined")
        await this.inputChange("customer_contact", contactId);
    },
    async selectChange(key, value, picklist) {
      if (key === "status" && value === "Accepted") {
        const creditHold = this.$el.querySelector(
          "#customer-quote-credit-hold-input"
        ).checked;
        if (creditHold) {
          // const select = this.$refs.statusSelect.$refs.input;
          throw new Error(
            "Customer is on hold, please contact AR representative. Select Change."
          );
        }
      }
      const newValue = this.getPicklistValue(value, picklist);
      await this.inputChange(key, newValue);
    },
    async inputChange(key, value) {
      try {
        if (typeof this.customerQuote !== "undefined") {
          const update = { id: this.customerQuote.id, [key]: value };
          await this.updateCustomerQuote({
            loadId: this.selectedLoad.id,
            customerQuote: update
          });
        }
      } catch (err) {
        console.error("[CUSTOMER QUOTE (217)]", err.message);
        this.showAlert({ text: err.message });
      }
    },
    async searchContact() {
      try {
        this.searchingMessage = true;
        this.errorMessage = null;
        const searchbar = document.querySelector(
          "#customer-quote-contact-input"
        );
        const contact = searchbar.value;
        await this.getContactList(contact);
        this.searchingMessage = false;
      } catch (err) {
        console.error("[CUSTOMER QUOTE (231)]", err.message);
        this.searchingMessage = false;
        this.errorMessage = err.message;
      }
    },
    requiredFieldsOk(required) {
      let valid = true;
      required.forEach(function(field) {
        if (field === "") {
          valid = false;
        }
      });
      return valid;
    },
    getCustomerQuoteFields() {
      const loadId = this.selectedLoad.id;
      const contact = this.$el.querySelector("#customer-quote-contact-input")
        .value;
      const status = this.$el.querySelector("#customer-quote-status-select")
        .value;
      // const currency = this.$el.querySelector("#customer-quote-currency-select")
      //   .value;
      let contactId;
      this.contacts.forEach(item => {
        if (item.label === contact) contactId = item.value;
      });
      const pricing = this.$el.querySelector("#customer-quote-pricing-select")
        .value;
      const accCharges = document.querySelector(
        "#customer-pricing-accessorial-charges"
      ).value;
      const comments = this.$el.querySelector("#customer-quote-comments-input")
        .value;
      const netFreightCharges = document.querySelector(
        "#customer-pricing-net-freight-charges"
      ).value;
      const fuelSurcharge = document.querySelector(
        "#customer-pricing-fuel-surcharge"
      ).value;
      const customerTotal = document.querySelector(
        "#customer-pricing-quote-total"
      ).value;
      const date = this.$el.querySelector("#customer-quote-date").value;
      const requiredFields = [loadId, status, customerTotal];
      const validRequiredFields = this.requiredFieldsOk(requiredFields);
      const customerQuote = {
        load_id: loadId,
        status,
        customer_quote_total: Number.parseFloat(customerTotal),
        current_iso_code: "USD",
        customer_contact: contactId,
        pricing: this.getPicklistValue(pricing, this.picklists.pricing),
        accessorial_charges: Number.parseFloat(accCharges),
        comments,
        net_line_haul: netFreightCharges || 0,
        fuel_surcharge: fuelSurcharge || 0,
        quote_date: date
      };
      return [customerQuote, validRequiredFields];
    },
    async saveQuote() {
      try {
        this.savingQuote = true;
        let customerTotal;
        let pricingRepId;
        const userId = this.showUserLoggedIn.id_salesforce;
        if (typeof this.customerQuote === "undefined") {
          const [customerQuote, validFields] = this.getCustomerQuoteFields();
          if (!validFields)
            throw {
              message:
                "Status, Customer Quote Total, Date and Currency are Required"
            };
          // Getting pricing rep before gets updated
          pricingRepId = this.selectedLoad.load_details.pricing_rep_id;
          await this.createCustomerQuote(customerQuote);
          customerTotal = customerQuote.customer_quote_total;
          if (this.customerQuote && this.customerQuote.credit_hold)
            this.showAlert({
              text: "Customer is on hold, please contact AR representative. Saving Quote."
            });
        } else {
          const netFreightCharges = document.querySelector(
            "#customer-pricing-net-freight-charges"
          ).value;
          const fuelSurcharge = document.querySelector(
            "#customer-pricing-fuel-surcharge"
          ).value;
          const accCharges = document.querySelector(
            "#customer-pricing-accessorial-charges"
          ).value;
          customerTotal = document.querySelector(
            "#customer-pricing-quote-total"
          ).value;

          // Getting pricing rep before gets updated
          pricingRepId = this.selectedLoad.load_details.pricing_rep_id;

          // Update customer quote with the new values
          const update = {
            id: this.customerQuote.id,
            net_line_haul: parseFloat(netFreightCharges) || 0,
            fuel_surcharge: parseFloat(fuelSurcharge) || 0,
            accessorial_charges: parseFloat(accCharges) || 0,
            customer_quote_total: parseFloat(customerTotal) || 0
          };
          await this.updateCustomerQuote({
            loadId: this.selectedLoad.id,
            customerQuote: update
          });
        }

        let newPricingRep;
        if (!pricingRepId) newPricingRep = userId;
        else newPricingRep = pricingRepId;

        // Update customer quote total in the load
        const loadUpdate = {
          customer_quote_total: parseFloat(customerTotal) || 0,
          pricing_rep_id: newPricingRep
        };
        await this.updateLoad({
          loadId: this.selectedLoad.id,
          update: loadUpdate
        });
      } catch (err) {
        console.error("[CUSTOMER QUOTE (331)]", err.message);
        this.showAlert({ text: err.message });
      } finally {
        this.savingQuote = false;
      }
    },
    async getCustomerQuoteRoute() {
      this.customerQuoteRoute = await this.generateCustomerQuoteRoute();
    }
  },
  async created() {
    this.customerQuoteStatus = this.getPicklistLabel(
      this.getCustomerQuoteDefaultValue("status"),
      this.picklists.status
    );
    this.getCustomerQuoteRoute();
    // get contact list
    await this.getContactList(
      this.getCustomerQuoteDefaultValue("customer_contact", "o").name
    );
  }
};
</script>

<style lang="scss" scoped>
.save-button,
.send-quote-button,
.search-contact-button {
  @include primary-button;
}
</style>
