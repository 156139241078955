"use strict";

// ****************************************************************
// String Methods
// ****************************************************************

export const capitalize = function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const toTitle = function(value) {
  const splittedTitle = value.split("_").join(" ");
  return capitalize(splittedTitle);
};

export const toReadableDate = function(value) {
  return new Date(value).toLocaleString();
};

// ****************************************************************
// Object Methods
// ****************************************************************

export const isEmpty = function(object) {
  return Object.keys(object).length === 0 && object.constructor === Object;
};

export const formatPicklist = function(picklist) {
  return picklist.map(function(item) {
    return { label: item.name, value: item.id };
  });
};

// Puts the picklist into the format { labels: [], values: [] }
export const mapPicklist = function(picklist) {
  if (Array.isArray(picklist)) {
    const labels = [];
    const values = [];
    picklist.forEach(item => {
      labels.push(item.label);
      values.push(item.value);
    });
    return { labels, values };
  } else return picklist;
};

// Puts picklists into the format { key1: { labels: [], values: [] }, ... }
export const mapPicklistObject = function(picklistObj) {
  return Object.keys(picklistObj).reduce((mapped, key) => {
    if (picklistObj[key] !== null) mapped[key] = mapPicklist(picklistObj[key]);
    return mapped;
  }, {});
};

export const getPicklistLabel = function(value, picklist) {
  const index = picklist.values.findIndex(item => item === value);
  return picklist.labels[index];
};

export const getPicklistValue = function(value, picklist) {
  const index = picklist.labels.findIndex(label => label === value);
  return picklist.values[index];
};

export const findIdByNameFromArray = function(picklist, value) {
  const mappedPicklist = mapPicklist(picklist);
  return getPicklistValue(value, mappedPicklist);
};

// ****************************************************************
// Validation Methods
// ****************************************************************

export const emailValidator = function(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const phoneValidator = function(phone) {
  if (phone) {
    if (phone[0] === "1" && phone.length !== 11) {
      return [
        false,
        "Please remember, if the phone number starts with 1 it must be 11 characters"
      ];
    }
    if (phone[0] !== "1" && phone.length !== 10) {
      return [
        false,
        "Please remember, if the phone number starts with a number other than 1, it must be 10 characters"
      ];
    }
    return [true];
  }
};

export const validEmails = function(emailList) {
  for (const email of emailList) {
    if (!emailValidator(email)) return false;
  }
  return true;
};

export const removeStatusDepartment = function(departments) {
  const statusDepartment = departments.filter(function(department) {
    return ["ltl", "otr", "sss", "rfr"].includes(department.department_name);
  });

  const filteredDepartments = departments.filter(function(department) {
    return !["ltl", "otr", "sss", "rfr"].includes(department.department_name);
  });

  return [filteredDepartments, statusDepartment];
};

export const neuterValue = function(datatype = "s") {
  let output;
  switch (datatype) {
    case "s":
      output = "";
      break;
    case "b":
      output = false;
      break;
    case "a":
      output = [];
      break;
    case "o":
      output = {};
      break;
    default:
      output = "";
      break;
  }
  return output;
};

export const validate = function(object, key, datatype = "s") {
  if (object !== null && typeof object !== "undefined") {
    if (Object.keys(object).includes(key) && object[key] !== null)
      return object[key];
    else return neuterValue(datatype);
  } else return neuterValue(datatype);
};

// ****************************************************************
// Other Methods
// ****************************************************************

import Swal from "sweetalert2";

export const showAlert = function({
  alertType = "error",
  title = "Error",
  text = "",
  html = "",
  confirmText = "Ok",
  cancel = false
}) {
  return Swal.fire({
    title,
    text,
    html,
    icon: alertType,
    showCancelButton: cancel,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmText
  });
};

export const genId = function() {
  return 10 + Math.ceil(Math.random() * 900000);
};

// Ignores navigation duplicated error and prints any other error
export const handleNavigationDuplicated = function(err) {
  if (
    err.name !== "NavigationDuplicated" &&
    !err.message.includes("Avoided redundant navigation to current location")
  ) {
    console.error("[HANDLE NAVIGATION DUPLICATED (163)]", err);
  }
};

// Map errors
export const errorsMessages = function(errorsArray) {
  let msg = ``;
  errorsArray.forEach(
    error =>
      (msg +=
        error.detail?.msg || error.detail?.message
          ? "• " + error.detail.msg ||
            error.detail?.message +
              ` ${error.detail?.diagnostic}` +
              " status code " +
              error?.status_code +
              "<br />"
          : "• " + error.detail + "<br />")
  );
  return msg;
};
