<template>
  <div class="header">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand class="header--logo" @click="goHome"
        ><img
          src="@/assets/GLT-LOGISTICS-(TRADEMARK-NEGATIVO).png"
          width="70"
          height="70"
          alt=""
      /></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <h5 class="header--department-name">
          <b>{{
            $store.getters[
              "login/showUserDepartment"
            ].department_name.toUpperCase()
          }}</b>
          Department
        </h5>
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <b-form-input
              size="sm"
              v-model="optionSelected"
              @keyup.enter="search"
              @input="getLoad"
              autocomplete="off"
              class="mr-sm-2"
              list="my-list-id"
              type="text"
              placeholder="Search"
            ></b-form-input>

            <datalist id="my-list-id">
              <option v-for="(option, index) in options" :key="index">{{
                option.name
              }}</option>
            </datalist>
            <b-button
              size="sm"
              variant="outline-primary"
              class="my-2 my-sm-0 button-search"
              type="submit"
              ><i class="fas fa-search"></i>
            </b-button>
          </b-nav-form>

          <div class="pt-1" v-if="statusLoading">
            <b-icon
              icon="arrow-clockwise"
              animation="spin"
              v-if="true"
            ></b-icon>
          </div>

          <b-overlay
            v-else
            :show="busy"
            rounded
            opacity="0.6"
            spinner-small
            class="d-inline-block"
          >
            <b-nav-item-dropdown
              ref="dropdown-button"
              class="header-user"
              right
            >
              <template #button-content>
                {{ username }}
                <span class="header-user--status">{{
                  userSalesforceStatus
                }}</span>
              </template>
              <!-- button to change department -->
              <!-- <b-dropdown-item
                v-for="department in departments"
                v-bind:key="department"
                @click="changeDepartment(department)"
                ><i
                  class="fas fa-exchange-alt header-user--change-department"
                ></i>
                Change to {{ department.toUpperCase() }}</b-dropdown-item
              >
              <b-dropdown-divider
                v-if="departments.length != 0"
              ></b-dropdown-divider> -->
              <b-dropdown-item
                v-for="status in statusPicklist.availability"
                :key="status.value"
                @click="changeStatus(status.value)"
              >
                <b-icon
                  v-if="status.label == 'Available'"
                  icon="person-check-fill"
                  class="header-user--available"
                ></b-icon>
                <b-icon
                  v-if="status.label == 'Out for Lunch'"
                  icon="person-dash-fill"
                  class="header-user--out-for-lunch"
                ></b-icon>
                <b-icon
                  v-if="status.label == 'Solo Mios'"
                  icon="house-door-fill"
                  class="header-user--mios"
                ></b-icon>
                <b-icon
                  v-if="status.label == 'Not Available'"
                  icon="person-x-fill"
                  class="header-user--not-available"
                ></b-icon>
                {{ status.label }}
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="signout"
                ><i class="fas fa-sign-out-alt header-user--signout"></i> Sign
                Out</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </b-overlay>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Header",
  computed: {
    ...mapGetters("user", ["userSalesforce"]),
    ...mapGetters("user", { statusPicklist: "picklist" })
  },
  data() {
    return {
      statusLoading: false,
      departments: [],
      username: null,
      user: null,
      optionSelected: null,
      options: [],
      userSalesforceStatus: null,
      busy: false
    };
  },
  watch: {
    async optionSelected() {
      if (this.optionSelected.length >= 4) {
        await this.search();
        if (this.options.length == 1) {
          this.$router
            .push({
              name: "LoadView",
              params: { loadId: this.options[0].id }
            })
            .catch(() => this.handleNavigationDuplicated);
        }
      }
    }
  },
  async created() {
    try {
      this.statusLoading = true;
      await this.$store.dispatch("user/getPicklistStatus");
    } catch (err) {
      console.error("[HEAD (169)]", err.message);
    } finally {
      this.statusLoading = false;
    }
  },
  async mounted() {
    try {
      this.user = this.$store.getters["login/showUserLoggedIn"];
      await this.$store.dispatch(
        "user/getUserSalesforce",
        this.user.id_salesforce
      );
      this.userSalesforceStatus = this.userSalesforce
        ? this.userSalesforce.availability
        : "";
      let department = this.$store.getters["login/showUserDepartment"];
      this.username = this.user.username;
      for (let i in this.user.department) {
        if (
          this.user.department[i].department_name != department.department_name
        ) {
          this.departments.push(this.user.department[i].department_name);
        }
      }
    } catch (err) {
      console.error("[HEAD (195)]", err.message);
    }
  },
  methods: {
    goHome() {
      this.$router
        .push({ name: "Search" })
        .catch(() => this.handleNavigationDuplicated);
    },
    async search() {
      try {
        let shp;
        if (
          this.optionSelected.localeCompare("SHP", undefined, {
            sensitivity: "accent"
          }) == 1
        ) {
          shp = this.optionSelected.slice(3);
        } else {
          shp = this.optionSelected;
        }
        await this.$store.dispatch("load/getLoadList", shp);
        this.options = this.$store.getters["load/loadList"];
      } catch (err) {
        console.error("[HEAD (221)]", err.message);
        this.showAlert({ text: err.message });
      }
    },
    getLoad() {
      for (let i = 0; i < this.options.length; i++) {
        if (this.optionSelected == this.options[i].name) {
          this.$router
            .push({
              name: "LoadView",
              params: { loadId: this.options[i].id }
            })
            .catch(() => this.handleNavigationDuplicated);
        }
      }
    },
    async changeStatus(status) {
      try {
        this.busy = true;
        const payload = {
          id: this.user.id_salesforce,
          availability: { availability: status }
        };
        await this.$store.dispatch("user/changeStatus", payload);
        await this.$store.dispatch(
          "user/getUserSalesforce",
          this.user.id_salesforce
        );
        this.userSalesforceStatus = this.$store.getters[
          "user/userSalesforce"
        ].availability;
      } catch (err) {
        console.error("[HEAD (253)]", err.message);
        this.showAlert({ text: err.message });
      } finally {
        this.busy = false;
      }
    },
    changeDepartment(department) {
      for (let i in this.user.department) {
        if (this.user.department[i].department_name == department) {
          localStorage.setItem(
            "charlie-uploader-actual-department",
            JSON.stringify(this.user.department[i])
          );
        }
      }
      location.reload();
    },
    signout() {
      localStorage.removeItem("charlie-uploader-user");
      localStorage.removeItem("charlie-uploader-actual-department");
      localStorage.removeItem("uploader-status-department");
      this.$router
        .push({ name: "Login" })
        .catch(() => this.handleNavigationDuplicated);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../CSS/globals.scss";

.bg-dark {
  background-color: $color-background-darker !important;
  height: 73px;
}

.header--logo {
  cursor: pointer;
}

.header--department-name {
  margin: 0;
  color: $color-light;
}

.button-search {
  margin-right: $margin-most-used;
}

@mixin icon($color) {
  color: $color;
  margin-right: 10px;
  width: 30px;
}

.header-user {
  &--status {
    color: $color-primary-company;
    font-size: 11px;
    padding: 0px 5px;
  }
  &--available {
    @include icon($color-user-active);
  }
  &--out-for-lunch {
    @include icon($color-secondary-button);
  }
  &--mios {
    @include icon($color-primary-button);
  }
  &--not-available {
    @include icon($color-user-busy);
  }
  &--signout {
    @include icon($color-primary-company);
  }
  &--change-department {
    @include icon($color-secondary-button);
  }
}

.btn-outline-primary {
  cursor: pointer;
  color: get-opacity($color-white, 0.5);
  border-color: $color-primary-company;
  &:hover {
    color: $color-background-darker !important;
    background-color: $color-primary-company !important;
    border-color: $color-primary-company !important;
    opacity: 0.7;
  }
}
</style>
