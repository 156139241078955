<template>
  <div class="load-view">
    <div class="loading-spinner" v-if="isLoading">
      <img src="../assets/spinner.gif" width="200" height="200" />
    </div>
    <template v-else>
      <LoadDetails />
      <b-tabs content-class="mt-3">
        <b-tab title="Freight Quote Assistant">
          <FreightQuoteAssistant />
        </b-tab>
        <b-tab title="Load Details" active>
          <StopsTable />
        </b-tab>
        <b-tab title="Documents" @click="refreshDocuments">
          <Documents
            :loading="documentsLoading"
            @changeLoading="changeLoading"
          />
        </b-tab>
        <b-tab title="All Quotes">
          <AllQuotes />
        </b-tab>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// Components
import FreightQuoteAssistant from "@/components/FreightQuoteAssistant/FreightQuoteAssistant.vue";
import AllQuotes from "@/components/AllQuotes/AllQuotes.vue";
import StopsTable from "../components/LoadDetailsTab/StopsTable";
import Documents from "../components/Documents/Documents.vue";
import LoadDetails from "../components/LoadDetails.vue";

export default {
  name: "LoadView",
  components: {
    FreightQuoteAssistant,
    AllQuotes,
    StopsTable,
    Documents,
    LoadDetails
  },
  props: {
    loadId: String
  },
  data() {
    return {
      isLoading: true,
      documentsLoading: true
    };
  },
  computed: {
    ...mapGetters("load", ["selectedLoad"])
  },
  methods: {
    ...mapActions("load", [
      "getLoad",
      "getLoadPicklists",
      "getSummaryPicklist",
      "getEquipmentType",
      "getModes",
      "getPanelAdminAccessorialsData",
      "getAccessorials",
      "getLoadAccessorials"
    ]),
    ...mapActions("customerQuotes", ["getCustomerQuotes", "getPicklists"]),
    ...mapActions("carrierQuotes", ["getCarriers", "getCarriersPicklists"]),
    ...mapActions("stops", ["getLoadStops"]),
    ...mapActions("documents", ["getDocuments", "getTasks"]),
    changeLoading(value) {
      this.documentsLoading = value;
    },
    async refreshDocuments() {
      const params = {
        shp: this.selectedLoad.name,
        load_id: this.loadId
      };
      try {
        this.documentsLoading = true;
        await this.getDocuments(params);
        this.documentsLoading = false;
      } catch (err) {
        console.error("[LOAD (84)]", err.message);
        this.showAlert({ text: err.message }).then(() => {
          this.$router
            .push({ name: "Search" })
            .catch(() => this.handleNavigationDuplicated);
        });
      }
    },
    async getLoadData(load) {
      await this.getLoad(load);
      const customers = this.getCustomerQuotes(load);
      const carriersByLoad = this.getCarriers({
        loadId: load
      });
      const accessorials = this.getPanelAdminAccessorialsData();
      const loads = this.getLoadStops(load);
      // Get load accessorials
      const loadAccessorials = this.getLoadAccessorials(this.loadId);
      await Promise.all([
        customers,
        carriersByLoad,
        loads,
        loadAccessorials,
        accessorials
      ]);
    },
    async getPicklistsData() {
      // Get load picklists
      const loadPicks = this.getLoadPicklists();
      // Get general picklists
      const picklists = this.getPicklists();
      // Get modes
      const modes = this.getModes();
      // Get accessorials
      const loadMode = this.validate(
        this.selectedLoad.load_details,
        "mode_name"
      );
      const accessorials = this.getAccessorials(loadMode);
      await Promise.all([loadPicks, picklists, modes, accessorials]);
      // Get equipment type
      await this.getEquipmentType();
    },
    async getSummaryPicklists() {
      const carrier = this.getSummaryPicklist({
        picklistName: "carrier",
        params: {
          carrier: this.validate(this.selectedLoad.load_details, "carrier").name
        }
      });
      const customer = this.getSummaryPicklist({
        picklistName: "customer",
        params: {
          customer: this.validate(this.selectedLoad.load_details, "customer")
            .name
        }
      });
      const billing = this.getSummaryPicklist({
        picklistName: "billing",
        params: {
          billing: this.validate(
            this.selectedLoad.load_details,
            "carrier_bill_to"
          ).name
        }
      });
      await Promise.all([carrier, customer, billing]);
    },
    async getData() {
      try {
        await this.getLoadData(this.loadId);
        const picklists = this.getPicklistsData();
        const tasks = this.getTasks();
        await Promise.all([picklists, tasks]);
        await this.getSummaryPicklists();
        this.isLoading = false;
      } catch (err) {
        console.error("[LOAD (144)]", err.message);
        let errorMessage = err.message;
        if (err.response) errorMessage = err.response.data.detail;
        this.showAlert({ text: errorMessage }).then(() => {
          if (errorMessage === "Load not found")
            this.$router
              .push({ name: "Search" })
              .catch(() => this.handleNavigationDuplicated);
        });
      }
    }
  },
  created() {
    this.getData();
  }
};
</script>

<style lang="scss" scoped>
.load-view {
  padding: 2% 1.5% 6rem 1.5%;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
