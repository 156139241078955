import API from "./api/methods.js";
import { errorsMessages, showAlert } from "@/utils/helpers.js";

import {
  formatPicklist,
  mapPicklist,
  mapPicklistObject,
  getPicklistValue
} from "@/utils/helpers.js";

export default {
  namespaced: true,
  state: {
    loadList: [],
    selectedLoad: {},
    picklists: {},
    rawModes: {},
    modes: {},
    accessorials: {},
    loadAccessorials: [],
    panelAdminAccessorialData: null,
    tenderResponse: null,
    accessorialsValidationStatus: null
  },
  getters: {
    loadList(state) {
      return state.loadList;
    },
    selectedLoad(state) {
      return state.selectedLoad;
    },
    picklists(state) {
      return state.picklists;
    },
    modes(state) {
      return state.modes;
    },
    accessorials(state) {
      return state.accessorials;
    },
    loadAccessorials(state) {
      return state.loadAccessorials;
    },
    assignedMode(state) {
      const modeId = state.selectedLoad.load_details.mode_id;
      const mode = state.rawModes.find(function(mode) {
        return mode.id === modeId;
      });
      if (typeof mode !== "undefined") return mode.ops_dept;
      else return null;
    },
    showPanelAdminAccessorialData(state) {
      return state.panelAdminAccessorialData;
    },
    showTenderResponse(state) {
      return state.tenderResponse;
    },
    accessorialsValidationStatus(state) {
      return state.accessorialsValidationStatus;
    }
  },
  actions: {
    generateLoadRoute({ state }) {
      const baseDomain = process.env.VUE_APP_BASE_URL;
      if (baseDomain.includes("dev")) {
        return `https://gltlogistics--full.sandbox.lightning.force.com/lightning/r/rtms__Load__c/${state.selectedLoad.id}/view`;
      }
      return `https://gltlogistics.lightning.force.com/lightning/r/rtms__Load__c/${state.selectedLoad.id}/view`;
    },
    getLoadList({ commit }, shp) {
      const resource = `/load/list/SHP${shp}`;
      return API.get(resource, true).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting load data from server");
        commit("SET_LOAD_LIST", res.data);
      });
    },
    getLoad({ commit, dispatch }, loadId) {
      const resource = `/load/${loadId}`;
      return API.get(resource, true).then(async function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting load data from server");
        commit("SET_LOAD", res.data);
        const financialSummary = dispatch("getFinancialSummary", res.data);
        const salesPerformance = dispatch("getSalesPerformance", res.data);
        await Promise.all([financialSummary, salesPerformance]);
      });
    },
    getLoadPicklists({ commit }) {
      return API.get("/load/picklists", true).then(async function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting load picklists from server");
        const picklists = {
          ...res.data,
          carrier: [],
          customer: [],
          billing: [],
          documents: []
        };
        const mappedPicklists = mapPicklistObject(picklists);
        commit("SET_PICKLISTS", mappedPicklists);
      });
    },
    async getDocumentOptions({ commit, state }, billingCarrier) {
      try {
        const id = billingCarrier.id;
        const res = await API.get(`/document-options/carrier-bill/${id}`, true);
        if (typeof res === "undefined")
          throw new Error("Error getting document options from server");
        const documents = formatPicklist(res.data);
        const picklists = { ...state.picklists, documents };
        const mappedPicklists = mapPicklistObject(picklists);
        commit("SET_PICKLISTS", mappedPicklists);
      } catch (err) {
        console.error("[LOAD (80)]", err.message);
      }
    },
    getSummaryPicklist({ commit, dispatch, state }, { picklistName, params }) {
      return API.get("/account/carrier", true, params).then(async function({
        data
      }) {
        const picklist = formatPicklist(data);
        const picklists = { ...state.picklists, [picklistName]: picklist };
        const mappedPicklists = mapPicklistObject(picklists);
        commit("SET_PICKLISTS", mappedPicklists);
        // Recharge document options when the search on billing is done (first coincidence)
        if (picklistName === "billing") {
          if (data.length > 0) await dispatch("getDocumentOptions", data[0]);
          //  Resets document options when the searcn does not found anything
          else {
            const picklists = { ...state.picklists, documents: [] };
            const mappedPicklists = mapPicklistObject(picklists);
            commit("SET_PICKLISTS", mappedPicklists);
          }
        }
      });
    },
    getEquipmentType({ commit, state }) {
      const resource = "/equipment-type";
      return API.get(resource, true).then(async function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting equipment type data from server");
        const picklist = formatPicklist(res.data);
        const picklists = { ...state.picklists, equipmentType: picklist };
        const mappedPicklists = mapPicklistObject(picklists);
        commit("SET_PICKLISTS", mappedPicklists);
      });
    },
    getModes({ commit }) {
      const resource = "/mode";
      return API.get(resource, true).then(async function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting modes from server");
        const modes = formatPicklist(res.data);
        const mappedModes = mapPicklist(modes);
        commit("SET_MODES", { rawModes: res.data, mappedModes });
      });
    },
    async getAccessorials({ commit }, mode) {
      const resource = "/accessorials/";
      const params = { mode };
      return API.get(resource, true, params).then(res => {
        if (typeof res === "undefined")
          throw new Error("Error getting accessorials from server");
        const accessorials = formatPicklist(res.data);
        const mappedAccessorials = mapPicklist(accessorials);
        commit("SET_ACCESSORIALS", mappedAccessorials);
      });
    },
    getLoadAccessorials({ commit }, loadId) {
      const resource = `/accessorials/load/${loadId}`;
      return API.get(resource, true).then(function(res) {
        commit("SET_LOAD_ACCESSORIALS", res.data);
      });
    },
    async getPanelAdminAccessorialsData({ commit, dispatch }) {
      const resource = `v2/panel-admin/accessorials`;
      try {
        const response = await API.get(resource, true);
        commit("SET_PANEL_ADMIN_ACCESSORIALS_DATA", response?.data?.data);
        return response;
      } catch (error) {
        if (error.response && error.response.status == 403) {
          await dispatch("login/refreshToken");
          location.reload();
        } else if (error.response?.data?.errors.length > 0) {
          let msg = errorsMessages(error.response?.data?.errors);
          showAlert({ html: msg });
          throw new Error(error.message);
        } else {
          throw error;
        }
        return error;
      }
    },
    getFinancialSummary({ dispatch }, { load_financial_summary: load }) {
      const financialSummary = {
        quoted: {
          customer: load.customer_quote_total,
          vendor: load.carrier_quote_total,
          margin: load.margin_quoted,
          margin_percent: load.margin_pct_quoted
        },
        invoiced: {
          customer: load.customer_invoice_total,
          vendor: load.carrier_invoice_total,
          margin: load.margin_invoiced,
          margin_percent: load.margin_pct_invoiced
        },
        paid: {
          customer: load.customer_payment_total,
          vendor: load.carrier_payment_total,
          margin: load.margin_paid,
          margin_percent: load.margin_pct_paid
        }
      };
      dispatch("financialSummary/setFinancialSummary", financialSummary, {
        root: true
      });
    },
    getSalesPerformance({ commit }, load) {
      commit(
        "salesPerformance/SET_SALES_PERFORMANCE",
        load.load_sales_performance,
        {
          root: true
        }
      );
    },
    updateLoad({ dispatch }, { loadId, update }) {
      const resource = `/load/${loadId}`;
      delete update.loadId;
      return API.patch(resource, update, true).then(async function() {
        await dispatch("getLoad", loadId);
      });
    },
    addAccessorialToLoad({ state, dispatch }, { loadId, accessorialName }) {
      const resource = "/accessorials/load";
      const accessorialId = getPicklistValue(
        accessorialName,
        state.accessorials
      );
      const payload = {
        id: accessorialId,
        name: accessorialName,
        load: loadId
      };
      return API.post(resource, payload, true).then(function(res) {
        if (!res || typeof res === "undefined")
          throw new Error("Error creating load accessorial");
        dispatch("getLoadAccessorials", loadId);
      });
    },
    async createAccessorialToLoad({ dispatch }, payload) {
      const resource = "/accessorials/load";
      try {
        const response = await API.post(resource, payload, true);
        await dispatch("getLoadAccessorials", payload?.load);
        return response;
      } catch (error) {
        if (error.response && error.response.status == 403) {
          await dispatch("login/refreshToken");
          location.reload();
        } else if (error.response?.data?.errors.length > 0) {
          let msg = errorsMessages(error.response?.data?.errors);
          showAlert({ html: msg });
          throw new Error(error.message);
        } else {
          throw new Error("Error creating load accessorial");
        }
        return error;
      }
    },
    async deleteAccessorialToLoad({ dispatch }, payload) {
      const resource = `/accessorials/${payload.accessorial_id}`;
      try {
        const response = await API.delete(resource, payload, true);
        await dispatch("getLoadAccessorials", payload?.load);
        return response;
      } catch (error) {
        if (error.response && error.response.status == 403) {
          await dispatch("login/refreshToken");
          location.reload();
        } else {
          throw new Error("Error deleting load accessorial");
        }
        return error;
      }
    },
    tender(ctx, payload) {
      const resource = "load/v2/tender";
      return API.post(resource, payload, true);
    },
    // Version 2 to Process Tender
    async tenderProcessForCarrier({ dispatch, commit }, payload) {
      const resource = "v2/loads/tender";
      try {
        const response = await API.post(resource, payload, true);
        commit("SET_TENDER_RESPONSE", response?.data);
        dispatch("validateTenderResponseValues", response?.data);
        return response;
      } catch (error) {
        if (error.response && error.response.status == 403) {
          await dispatch("login/refreshToken");
          location.reload();
        } else if (error.response?.data?.errors.length > 0) {
          let msg = errorsMessages(error.response?.data?.errors);
          showAlert({ html: msg });
          throw new Error(error.message);
        } else {
          throw new Error(error.message);
        }
        return error;
      }
    },
    async validateTenderResponseValues({ state, dispatch }, tenderResponse) {
      if (!tenderResponse?.is_stored_rate) {
        showAlert({
          title: "Good job!",
          alertType: "success",
          text: "Tender Successful. Please check the references"
        });
      } else {
        showAlert({
          title: "Please remember!",
          alertType: "warning",
          text:
            "Pickup request not support via API. Please remember to schedule the pickup with the carrier."
        });
      }
      if (
        !tenderResponse?.new_pickup_number ||
        !tenderResponse?.new_references
      ) {
        await dispatch("stops/getLoadStops", state.selectedLoad?.id, {
          root: true
        });
        state.tenderResponse.new_pickup_number = null;
        state.tenderResponse.new_references = null;
      }
    }
  },
  mutations: {
    SET_LOAD_LIST(state, loadList) {
      state.loadList = loadList;
    },
    SET_LOAD(state, load) {
      state.selectedLoad = load;
    },
    SET_PICKLISTS(state, picklists) {
      state.picklists = picklists;
    },
    SET_MODES(state, { rawModes, mappedModes }) {
      state.rawModes = rawModes;
      state.modes = mappedModes;
    },
    SET_ACCESSORIALS(state, accessorials) {
      state.accessorials = accessorials;
    },
    SET_LOAD_ACCESSORIALS(state, accessorials) {
      state.loadAccessorials = null;
      state.loadAccessorials = accessorials;
    },
    SET_PANEL_ADMIN_ACCESSORIALS_DATA(state, accessorials) {
      state.panelAdminAccessorialData = accessorials;
    },
    SET_TENDER_RESPONSE(state, tenderResponse) {
      state.tenderResponse = tenderResponse;
    },
    SET_ACCESSORIALS_VALIDATION_STATUS(state, accessorialsValidationStatus) {
      state.accessorialsValidationStatus = null;
      state.accessorialsValidationStatus = accessorialsValidationStatus;
    }
  }
};
