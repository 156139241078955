<template>
  <div class="load-details">
    <template v-if="isEmpty(selectedLoad)">
      No Load Info Available
    </template>
    <template v-else>
      <div class="load-details__title">
        <table>
          <tr>
            <td>
              <span class="load-details__title--icon"
                ><i class="fas fa-truck"></i
              ></span>
            </td>
            <td>
              <table>
                <tr>
                  <td class="text-left">
                    <h5>
                      <a
                        target="_blank"
                        :href="loadRoute"
                        @click="getLoadRoute()"
                        ><strong>{{
                          validate(selectedLoad, "name")
                        }}</strong></a
                      >
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      {{ selectedLoad.load_status }}
                    </span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <LoadDetailsSummary class="load-details__title--summary" />
      </div>
      <hr />
      <div class="load-details__grid">
        <!-- Customer Profile -->
        <div class="load-details__grid__cell">
          <label for="customer-profile-input">
            Customer Profile
          </label>
          <b-form-input
            size="sm"
            class="hover-input"
            id="customer-profile-input"
            :value="
              validate(selectedLoad, 'load_details', 'o').customer_profile
            "
            disabled
          ></b-form-input>
        </div>

        <!-- PRO Number -->
        <div class="load-details__grid__cell">
          <label for="pro-number-input">
            PRO Number
          </label>
          <b-form-input
            :value="validate(selectedLoad, 'load_details', 'o').pro_number"
            :oldValue="validate(selectedLoad, 'load_details', 'o').pro_number"
            :title="validate(selectedLoad, 'load_details', 'o').pro_number"
            id="pro-number-input"
            size="sm"
            type="text"
            autocomplete="off"
            @blur="inputChange('pro_number', $event.target.value)"
          ></b-form-input>
        </div>

        <!-- Transit Days -->
        <div class="load-details__grid__cell">
          <label for="transit-days-input">
            Transit Days
          </label>
          <b-form-input
            :value="validate(selectedLoad, 'load_details', 'o').transit_days"
            :title="validate(selectedLoad, 'load_details', 'o').transit_days"
            id="transit-days-input"
            size="sm"
            type="text"
            autocomplete="off"
            disabled
          ></b-form-input>
        </div>

        <!-- Billing Reference -->
        <div class="load-details__grid__cell">
          <label for="billing-reference-input">
            Billing Reference
          </label>
          <b-form-input
            :value="
              validate(selectedLoad, 'load_details', 'o').billing_reference
            "
            :title="
              validate(selectedLoad, 'load_details', 'o').billing_reference
            "
            id="billing-reference-input"
            size="sm"
            type="text"
            autocomplete="off"
            @blur="inputChange('billing_reference', $event.target.value)"
          ></b-form-input>
        </div>

        <!-- Tracking Rep -->
        <div class="load-details__grid__cell">
          <label for="tracking-rep-input">
            Tracking Rep
          </label>
          <b-form-select
            size="sm"
            id="tracking-rep-input"
            :options="
              picklists.charlie_pricing_rep
                ? picklists.charlie_pricing_rep.labels
                : null
            "
            :disabled="picklists.charlie_pricing_rep ? false : true"
            :value="validate(selectedLoad, 'load_details', 'o').tracking_rep"
            @change="
              selectChange(
                'tracking_rep',
                $event,
                picklists.charlie_pricing_rep
              )
            "
          ></b-form-select>
        </div>

        <!-- PO Number -->
        <div class="load-details__grid__cell">
          <label for="po-number-input">
            PO Number
          </label>
          <b-form-input
            :value="validate(selectedLoad, 'load_details', 'o').po_number"
            :title="validate(selectedLoad, 'load_details', 'o').po_number"
            id="po-number-input"
            size="sm"
            type="text"
            autocomplete="off"
            @blur="inputChange('po_number', $event.target.value)"
          ></b-form-input>
        </div>

        <!-- External TMS Source -->
        <div class="load-details__grid__cell">
          <label for="external-tms-source-input">
            External TMS Source
          </label>
          <b-form-input
            :value="
              validate(selectedLoad, 'load_details', 'o').external_tms_source
            "
            :title="
              validate(selectedLoad, 'load_details', 'o').external_tms_source
            "
            id="external-tms-source-input"
            size="sm"
            type="text"
            autocomplete="off"
            disabled
          ></b-form-input>
        </div>

        <!-- Pricing Rep -->
        <div class="load-details__grid__cell">
          <label for="pricing-rep-input">
            Pricing Rep
          </label>
          <div>
            <CustomPicklist
              id="pricing-rep-input"
              :data="customersPicklists.dispatcher"
              :value="
                validate(selectedLoad.load_details, 'pricing_rep', 'o').name
              "
              placeholder="Pricing Rep"
              @onChange="
                selectChange(
                  'pricing_rep_id',
                  $event,
                  customersPicklists.dispatcher
                )
              "
              noTitle
            ></CustomPicklist>
          </div>
        </div>

        <!-- Portal User Tender -->
        <div class="load-details__grid__cell">
          <label for="portal-user-tender-checkbox">
            GLT Portal Tender
          </label>
          <b-form-checkbox
            :checked="
              validate(selectedLoad, 'load_details', 'o').origin_portal_tender
            "
            id="portal-user-tender-checkbox"
            disabled
          >
          </b-form-checkbox>
        </div>

        <!-- Dispatcher -->
        <div class="load-details__grid__cell">
          <label for="dispatcher-input">
            Dispatcher
          </label>
          <div>
            <b-overlay :show="loadingDispatch" spinner-small>
              <CustomPicklist
                id="dispatcher-input"
                :data="customersPicklists.dispatcher"
                :value="
                  validate(selectedLoad.load_details, 'dispatcher_internal')
                    .name
                "
                placeholder="Dispatcher"
                @onChange="setDispatcher($event)"
                @deleteContent="deleteContent"
                noTitle
              ></CustomPicklist>
            </b-overlay>
          </div>
        </div>

        <!-- Mode -->
        <div class="load-details__grid__cell">
          <label for="mode-select">
            Mode
          </label>
          <b-form-select
            size="sm"
            id="mode-select"
            :options="modes.labels"
            :value="validate(selectedLoad, 'load_details', 'o').mode_name"
            @change="selectChange('mode_id', $event, modes)"
          ></b-form-select>
        </div>

        <!-- Account Segment -->
        <div class="load-details__grid__cell">
          <label for="account-segment-input">
            Account Segment
          </label>
          <b-form-input
            :value="validate(selectedLoad, 'load_details', 'o').account_segment"
            :title="validate(selectedLoad, 'load_details', 'o').account_segment"
            id="account-segment-input"
            size="sm"
            type="text"
            autocomplete="off"
            disabled
          ></b-form-input>
        </div>

        <!-- Sales Rep -->
        <div class="load-details__grid__cell">
          <label for="sales-rep-input">
            Sales Rep
          </label>
          <b-form-input
            v-if="validate(selectedLoad, 'load_details', 'o').sales_rep != null"
            :value="validate(selectedLoad, 'load_details', 'o').sales_rep.name"
            :title="validate(selectedLoad, 'load_details', 'o').sales_rep.name"
            id="sales-rep-input"
            size="sm"
            type="text"
            autocomplete="off"
            disabled
          ></b-form-input>
          <b-form-input
            v-else
            :value="validate(selectedLoad, 'load_details', 'o').sales_rep"
            :title="validate(selectedLoad, 'load_details', 'o').sales_rep"
            id="sales-rep-input"
            size="sm"
            type="text"
            autocomplete="off"
            disabled
          ></b-form-input>
        </div>

        <!-- Sales Rep 2 -->
        <div class="load-details__grid__cell">
          <label for="sales-rep-2-input">
            Sales Rep 2
          </label>
          <b-form-input
            v-if="
              validate(selectedLoad, 'load_details', 'o').sales_rep_2 != null
            "
            :value="
              validate(selectedLoad, 'load_details', 'o').sales_rep_2.name
            "
            :title="
              validate(selectedLoad, 'load_details', 'o').sales_rep_2.name
            "
            id="sales-rep-2-input"
            size="sm"
            type="text"
            autocomplete="off"
            disabled
          ></b-form-input>
          <b-form-input
            v-else
            :value="validate(selectedLoad, 'load_details', 'o').sales_rep_2"
            :title="validate(selectedLoad, 'load_details', 'o').sales_rep_2"
            id="sales-rep-2-input"
            size="sm"
            type="text"
            autocomplete="off"
            disabled
          ></b-form-input>
        </div>

        <!-- Insurance Required -->
        <div class="load-details__grid__cell">
          <label for="insurance-checkbox">
            Insurance Required
          </label>
          <b-form-checkbox
            :checked="
              validate(selectedLoad, 'load_details', 'o').insurance_required
            "
            id="insurance-checkbox"
            @change="inputChange('insurance_required', $event)"
          >
          </b-form-checkbox>
        </div>

        <!-- Created By -->
        <div class="load-details__grid__cell">
          <label for="carrier-dispatcher-input">
            Created By
          </label>
          <b-form-input
            :value="createdBy"
            :title="createdBy"
            id="carrier-dispatcher-input"
            size="sm"
            type="text"
            autocomplete="off"
            disabled
          ></b-form-input>
        </div>

        <!-- Non-LTL input fields -->
        <template v-if="assignedMode !== 'LTL'">
          <!-- Equipment Type -->
          <div class="load-details__grid__cell">
            <label for="equipment-type-input">
              Equipment Type
            </label>
            <div>
              <CustomPicklist
                id="equipment-type-input"
                :data="picklists.equipmentType"
                :value="
                  validate(selectedLoad.load_details, 'equipment_type', 'o')
                    .name
                "
                placeholder="Equipment Type"
                @onChange="
                  selectChange(
                    'equipment_type',
                    $event,
                    picklists.equipmentType
                  )
                "
                noTitle
              ></CustomPicklist>
            </div>
          </div>

          <!-- Covered By -->
          <div class="load-details__grid__cell">
            <label for="covered-by-input">
              Covered By
            </label>
            <div>
              <CustomPicklist
                id="covered-by-input"
                :data="picklists.covered_by"
                :value="
                  getPicklistLabel(
                    validate(selectedLoad.load_details, 'covered_by', 'o'),
                    picklists.covered_by
                  )
                "
                placeholder="Covered By"
                @onChange="
                  selectChange('covered_by', $event, picklists.covered_by)
                "
                noTitle
              ></CustomPicklist>
            </div>
          </div>

          <!-- Quick Pay 2 -->
          <div class="load-details__grid__cell">
            <label for="quickpay-checkbox">
              Quick Pay 2
            </label>
            <b-form-checkbox
              :checked="validate(selectedLoad, 'load_details', 'o').quick_pay_2"
              id="quickpay-checkbox"
              @change="inputChange('quick_pay_2', $event)"
            >
            </b-form-checkbox>
          </div>

          <!-- Macropoint -->
          <div class="load-details__grid__cell">
            <label for="macropoint-checkbox">
              Macropoint
            </label>
            <b-form-checkbox
              :checked="validate(selectedLoad, 'load_details', 'o').macropoint"
              id="macropoint-checkbox"
              @change="inputChange('macropoint', $event)"
            >
            </b-form-checkbox>
          </div>

          <!-- XENON Quote -->
          <div class="load-details__grid__cell">
            <label for="xenon-quote-checkbox">
              XENON Quote
            </label>
            <b-form-checkbox
              :checked="validate(selectedLoad, 'load_details', 'o').xenon_quote"
              id="xenon-quote-checkbox"
              @change="inputChange('xenon_quote', $event)"
            >
            </b-form-checkbox>
          </div>

          <!-- Import/Export -->
          <div class="load-details__grid__cell">
            <label for="dispatcher-via-input">
              Import/Export
            </label>
            <div>
              <CustomPicklist
                id="import-export-input"
                :data="picklists.import_export"
                :value="
                  getPicklistLabel(
                    validate(selectedLoad.load_details, 'import_export', 'o'),
                    picklists.import_export
                  )
                "
                placeholder="Import/Export"
                @onChange="
                  selectChange('import_export', $event, picklists.import_export)
                "
                noTitle
              ></CustomPicklist>
            </div>
          </div>

          <!-- Carrier Dispatcher Info -->
          <div class="load-details__grid__cell">
            <label for="carrier-dispatcher-info-input">
              Carrier Dispatcher Info
            </label>
            <b-form-input
              :value="
                validate(selectedLoad, 'load_details', 'o')
                  .carrier_dispatcher_info
              "
              :title="
                validate(selectedLoad, 'load_details', 'o')
                  .carrier_dispatcher_info
              "
              id="carrier-dispatcher-info-input"
              size="sm"
              type="text"
              autocomplete="off"
              maxlength="60"
              @blur="
                inputChange('carrier_dispatcher_info', $event.target.value)
              "
            ></b-form-input>
          </div>

          <!-- Driver Info -->
          <div class="load-details__grid__cell">
            <label for="driver-info-input">
              Driver Info
            </label>
            <b-form-input
              :value="validate(selectedLoad, 'load_details', 'o').driver_info"
              :title="validate(selectedLoad, 'load_details', 'o').driver_info"
              id="driver-info-input"
              size="sm"
              type="text"
              autocomplete="off"
              maxlength="60"
              @blur="inputChange('driver_info', $event.target.value)"
            ></b-form-input>
          </div>

          <!-- Container -->
          <div class="load-details__grid__cell">
            <label for="container-input">
              Container
            </label>
            <b-form-input
              :value="validate(selectedLoad, 'load_details', 'o').container"
              :title="validate(selectedLoad, 'load_details', 'o').container"
              id="container-input"
              size="sm"
              type="text"
              autocomplete="off"
              maxlength="120"
              @blur="inputChange('container', $event.target.value)"
            ></b-form-input>
          </div>

          <!-- Dispatched Via -->
          <div class="load-details__grid__cell">
            <label for="dispatched-via-input">
              Dispatched Via
            </label>
            <div>
              <CustomPicklist
                id="dispatched-via-input"
                :data="picklists.dispatched_via"
                :value="
                  getPicklistLabel(
                    validate(selectedLoad.load_details, 'dispatched_via', 'o'),
                    picklists.dispatched_via
                  )
                "
                placeholder="Dispatched Via"
                @onChange="
                  selectChange(
                    'dispatched_via',
                    $event,
                    picklists.dispatched_via
                  )
                "
                noTitle
              ></CustomPicklist>
            </div>
          </div>

          <!-- Expected Cost -->
          <div class="load-details__grid__cell">
            <label for="expected-cost-input">
              Expected Cost
            </label>
            <b-form-input
              :value="validate(selectedLoad, 'load_details', 'o').expected_cost"
              :title="validate(selectedLoad, 'load_details', 'o').expected_cost"
              id="expected-cost-input"
              size="sm"
              type="number"
              step="any"
              autocomplete="off"
              @blur="inputChange('expected_cost', $event.target.value)"
            ></b-form-input>
          </div>

          <!-- ReQuote -->
          <div class="load-details__grid__cell">
            <label for="requote-input">
              ReQuote
            </label>
            <b-form-input
              :value="validate(selectedLoad, 'load_details', 'o').requote"
              :title="validate(selectedLoad, 'load_details', 'o').requote"
              id="requote-input"
              size="sm"
              type="number"
              step="any"
              autocomplete="off"
              @blur="inputChange('requote', $event.target.value)"
            ></b-form-input>
          </div>
        </template>
      </div>

      <!-- Traffic Rep Assigned -->
      <div class="load-details__grid__cell">
        <label for="traffic-rep-assigned-input">
          Traffic Rep Assigned
        </label>
        <b-form-textarea
          :value="
            validate(selectedLoad, 'load_details', 'o').dispatcher_assigned
          "
          :title="
            validate(selectedLoad, 'load_details', 'o').dispatcher_assigned
          "
          id="traffic-rep-assigned-input"
          size="sm"
          type="text"
          autocomplete="off"
          disabled
        ></b-form-textarea>
      </div>

      <!-- Load Accessorials Section -->
      <div class="load-details__grid__cell">
        <label for="tags-basic">Load Accessorials</label>
        <b-form-tags
          input-id="tags-basic"
          v-model="loadAccessorialsList"
          placeholder=""
          disabled
        ></b-form-tags>
      </div>

      <!-- Traffic Comments -->
      <div class="load-details__grid__cell">
        <label for="traffic-comments-input">
          Traffic Comments
        </label>
        <b-form-textarea
          :value="validate(selectedLoad, 'load_details', 'o').traffic_comments"
          id="traffic-comments-input"
          size="sm"
          type="text"
          autocomplete="off"
          disabled
        ></b-form-textarea>
      </div>
      <div :class="{ hidden: !isLoading }" class="loading-message m-3">
        Loading...
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

// Components
import LoadDetailsSummary from "@/components/LoadDetailsSummary";
import CustomPicklist from "@/components/CustomPicklist.vue";

export default {
  name: "loadDetails",
  components: {
    LoadDetailsSummary,
    CustomPicklist
  },
  data() {
    return {
      createdBy: "",
      isLoading: false,
      loadRoute: "",
      loadAccessorialsList: [],
      originalLoadAccessorials: [],
      updatedLoadAccessorials: [],
      loadAccessorialsComparison: true,
      deleteDispatch: null,
      loadingDispatch: false
    };
  },
  computed: {
    ...mapGetters("load", [
      "selectedLoad",
      "picklists",
      "modes",
      "assignedMode",
      "loadAccessorials",
      "accessorialsValidationStatus"
    ]),
    ...mapGetters("customerQuotes", { customersPicklists: "picklists" })
  },
  watch: {
    loadAccessorials: {
      deep: true,
      handler() {
        this.fillLoadAccessorialsList();
      }
    },
    accessorialsValidationStatus() {
      if (this.accessorialsValidationStatus) {
        this.originalLoadAccessorials = [...this.loadAccessorialsList];
      }
    }
  },
  methods: {
    ...mapActions("load", ["updateLoad", "generateLoadRoute"]),
    async selectChange(key, value, picklist) {
      const newValue = this.getPicklistValue(value, picklist);
      await this.inputChange(key, newValue);
    },
    async getLoadRoute() {
      this.loadRoute = await this.generateLoadRoute();
    },
    async inputChange(key, value) {
      try {
        this.isLoading = true;
        const update = { [key]: value };
        await this.updateLoad({ loadId: this.selectedLoad.id, update });
      } catch (err) {
        console.error("[LOAD DETAILS (668)]", err.message);
        this.showAlert({ text: err.message + ". Changes may not be saved" });
      } finally {
        this.isLoading = false;
      }
    },
    async changeInsurance(value) {
      await this.inputChange("insurance_required", value);
    },
    async changeSalesStatus() {
      try {
        this.isLoading = true;
        const loadId = this.selectedLoad.id;
        const salesStatus = this.selectedLoad.load_details.sales_status;
        if (salesStatus === "Pending")
          await this.updateLoad({ loadId, update: { sales_status: "Won" } });
      } catch (err) {
        console.error("[LOAD DETAILS (684)]", err.message);
      } finally {
        this.isLoading = false;
      }
    },
    async setDispatcher(dispatcher) {
      this.loadingDispatch = true;
      try {
        this.isLoading = true;
        let dispatcherId = this.getPicklistValue(
          dispatcher,
          this.customersPicklists.dispatcher
        );
        // Change load sales status
        this.changeSalesStatus();
        // Updates load
        const quoteTotal =
          Number.parseFloat(
            document.querySelector("#customer-pricing-quote-total").value
          ) || -99999;
        const update = {
          dispatcher_internal_id:
            typeof dispatcherId !== "undefined" ? dispatcherId : null,
          customer_quote_total: quoteTotal
        };
        await this.updateLoad({ loadId: this.selectedLoad?.id, update });
      } catch (err) {
        console.error("[LOAD DETAILS (712)]", err.message);
      } finally {
        this.isLoading = false;
      }
      this.loadingDispatch = false;
    },
    fillLoadAccessorialsList() {
      this.loadAccessorialsList = [];
      this.loadAccessorials.map(item =>
        this.loadAccessorialsList.push(item.name)
      );
      this.updatedLoadAccessorials = [...this.loadAccessorialsList];
      this.compareAccessorials();
    },
    compareAccessorials() {
      this.originalLoadAccessorials.sort();
      this.updatedLoadAccessorials.sort();
      this.loadAccessorialsComparison =
        this.originalLoadAccessorials.length ==
          this.updatedLoadAccessorials.length &&
        this.originalLoadAccessorials.every((accessorial, index) => {
          return accessorial === this.updatedLoadAccessorials[index];
        });
      this.$store.commit(
        "load/SET_ACCESSORIALS_VALIDATION_STATUS",
        this.loadAccessorialsComparison
      );
    },
    async deleteContent() {
      const cleanDispatcherField = {};
      cleanDispatcherField.id = this.selectedLoad.load_details.dispatcher_internal.id = null;
      cleanDispatcherField.name = this.selectedLoad.load_details.dispatcher_internal.name = null;
      await this.setDispatcher(cleanDispatcherField.name);
    }
  },
  async created() {
    let date = moment
      .utc(String(this.selectedLoad.created_date))
      .format("MM/DD/YYYY hh:mm a");
    this.createdBy = this.selectedLoad.created_by + ", " + date;
    this.fillLoadAccessorialsList();
    this.originalLoadAccessorials = [...this.loadAccessorialsList];
    this.compareAccessorials();
  }
};
</script>

<style lang="scss" scoped>
.load-details {
  @include card;
  margin: 20px 0px;
  &__title {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &--icon {
      @include icon-title($color-secondary-button);
    }
    h5 {
      margin: 0px;
    }
  }
  &__grid {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    &__cell {
      @include form-cell;
    }
  }
  &__traffic-comments {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid $color-background-input;
    padding: 10px;
    label {
      font-size: 13px;
      padding-top: 4px;
    }
    &--textarea {
      width: 700px;
      height: 75px;
      font-size: 11.8px !important;
      margin-left: 10px;
    }
  }
}

.custom-select {
  font-size: 11.8px !important;
  background-color: $color-background-input !important;
  &:hover {
    @include box-shadow;
  }
  &:focus {
    border-color: get-opacity($color-gray-shadow, 0.8) !important;
    box-shadow: 0 0 0 0.2rem get-opacity($color-gray-shadow, 0.4) !important;
  }
}

@media (max-width: 1300px) {
  .load-details {
    text-align: left;
    &__title {
      width: 100%;
      flex-direction: column;
      &--summary {
        margin-top: 20px;
        @include flex-wrap(center);
      }
    }
  }
}

@media (max-width: 900px) {
  .load-details__traffic-comments {
    justify-content: space-between;
    label {
      width: 20%;
    }
    &--textarea {
      width: 70%;
      font-size: 11.8px !important;
      margin-left: 10px;
    }
  }
}

.loading-message {
  font-weight: bold;
  color: $color-user-busy;
}
</style>
