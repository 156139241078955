<template>
  <div v-if="loadStops" class="shipper-consignee-info">
    <b-table
      hover
      show-empty
      fixed
      stacked="md"
      :busy="isBusy"
      :items="items"
      :fields="fields"
      head-variant="light"
      class="text-left shipper-consignee-info__table"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:thead-top="data">
        <b-tr v-bind:key="data.columns">
          <b-th variant="dark" :colspan="fields.length">
            <b-button
              @click="$bvModal.show('add-stop-modal')"
              variant="info"
              size="sm"
              class="shipper-consignee-info__table__add-stop"
            >
              Add Stop
            </b-button>
            <b-modal id="add-stop-modal" hide-footer>
              <template #modal-title>
                Add Stop
              </template>
              <div class="d-block text-center">
                <AddStop v-model="addStopFromModal" />
              </div>
            </b-modal>
          </b-th>
        </b-tr>
      </template>

      <template v-for="field in fields" v-slot:[`head(${field.key})`]="column">
        <template v-if="field.key != 'shipperConsigneeInfo'">
          <div :key="field.key" class="shipper-consignee-info__table__head">
            <span>{{ column.field.name }}</span>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="deleteStop(field.key)"
            >
              <span
                aria-hidden="true"
                class="shipper-consignee-info__table__head--delete-stop d-flex align-items-center"
                >&times;</span
              >
            </button>
          </div>
        </template>
      </template>

      <template
        v-for="(field, index) in fields"
        v-slot:[`cell(${field.key})`]="row"
      >
        <template v-if="field.key != 'shipperConsigneeInfo'">
          <template v-if="row.index == 0">
            <b-input-group :key="field.key" size="sm">
              <b-form-input
                v-model="loadStops[index - 1].location_name"
                :title="loadStops[index - 1].location_name"
                size="sm"
                :list="'location-list' + (index - 1)"
                placeholder="Location"
                class="shipper-consignee-info__table__location--input"
                autocomplete="off"
                @input="getLocation(index - 1, field)"
              ></b-form-input>
              <datalist :id="'location-list' + (index - 1)">
                <option
                  v-for="location in locations[index - 1]"
                  :key="location.id + keyRender"
                  :value="location.name + ' -- ' + location.id"
                  >{{ location.shipping_address.street }},
                  {{ location.shipping_address.city }},
                  {{ location.shipping_address.state }}
                  {{ location.shipping_address.postal_code }}</option
                >
                >
              </datalist>
              <b-input-group-append>
                <b-button
                  id="addNewLocation"
                  class="shipper-consignee-info__table__location--button"
                  @click="addNewLocation(row, field)"
                  >Add New</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </template>

          <template v-else-if="row.index == 1">
            <div :key="field.key">
              <b-input-group
                class="shipper-consignee-info__table__contact"
                :key="field.key"
                size="sm"
              >
                <b-form-input
                  v-model="loadStops[index - 1].address"
                  :title="loadStops[index - 1].address"
                  readonly
                  size="sm"
                  type="text"
                  class="shipper-consignee-info__table--input"
                  placeholder="Address"
                  autocomplete="off"
                ></b-form-input>
                <datalist :id="'contact-list' + (index - 1)">
                  <option
                    v-for="contact in contacts[index - 1]"
                    v-bind:key="contact.id"
                    >{{ contact.name }}</option
                  >
                </datalist>
                <b-input-group-append>
                  <b-button
                    @click="$bvModal.show(`edit-address-modal-${index - 1}`)"
                    size="sm"
                    class="shipper-consignee-info__table__contact--button"
                    ><i class="fas fa-pen"></i
                  ></b-button>
                </b-input-group-append>
              </b-input-group>
              <EditAddressModal
                :stopIndex="index - 1"
                @refreshStops="refreshStops"
              />
            </div>
          </template>

          <template v-else-if="row.index == 2">
            <div :key="field.key">
              <b-input-group
                class="shipper-consignee-info__table__contact"
                :key="field.key"
                size="sm"
              >
                <b-form-input
                  v-model="loadStops[index - 1].contact_info"
                  :title="loadStops[index - 1].contact_info"
                  size="sm"
                  type="text"
                  @input="getContact(index - 1, field)"
                  :list="'contact-list' + (index - 1)"
                  class="shipper-consignee-info__table__contact--input"
                  placeholder="Shipping/Receiving Contact"
                  autocomplete="off"
                ></b-form-input>
                <datalist :id="'contact-list' + (index - 1)">
                  <option
                    v-for="contact in contacts[index - 1]"
                    v-bind:key="contact.id"
                    >{{ contact.name }}</option
                  >
                </datalist>
                <!-- Delete contact button -->
                <b-input-group-append>
                  <b-button
                    @click="searchContact(field.key, index - 1)"
                    size="sm"
                    class="shipper-consignee-info__table__contact--button"
                    :disabled="isLoading"
                    ><i class="fas fa-eraser"></i
                  ></b-button>
                </b-input-group-append>
                <!-- Edit contact button -->
                <b-input-group-append>
                  <b-button
                    @click="editContact(index)"
                    size="sm"
                    class="shipper-consignee-info__table__contact--button"
                    :disabled="isLoading"
                    ><i class="fas fa-pen"></i
                  ></b-button>
                </b-input-group-append>
                <!-- Create contact button -->
                <b-input-group-append>
                  <b-button
                    @click="createContact(index)"
                    size="sm"
                    class="shipper-consignee-info__table__contact--button"
                    :disabled="isLoading"
                    ><i class="fas fa-plus"></i
                  ></b-button>
                </b-input-group-append>
              </b-input-group>
              <CreateContactModal
                :stopIndex="index - 1"
                @refreshStops="refreshStops"
              />
            </div>
          </template>

          <template v-else-if="row.index == 3">
            <b-form-input
              v-model="loadStops[index - 1].shipping_receiving_hours"
              :title="loadStops[index - 1].shipping_receiving_hours"
              :key="field.key"
              size="sm"
              type="text"
              @blur="updateStop(index - 1, field.key)"
              class="shipper-consignee-info__table--input"
              placeholder="Shipping/Receiving Hours"
              autocomplete="off"
            ></b-form-input>
          </template>

          <template v-else-if="row.index == 4">
            <b-form-datepicker
              v-model="loadStops[index - 1].expected_date"
              :title="loadStops[index - 1].expected_date"
              :key="field.name"
              :id="'datepicker' + (index - 1)"
              size="sm"
              locale="en"
              today-button
              reset-button
              close-button
              @input="updateStop(index - 1, field.key)"
              class="shipper-consignee-info__table--input"
              autocomplete="off"
            ></b-form-datepicker>
          </template>

          <template v-else-if="row.index == 5">
            <b-form-input
              v-model="loadStops[index - 1].pickup_delivery_number"
              :title="loadStops[index - 1].pickup_delivery_number"
              :key="field.name"
              size="sm"
              @blur="updateStop(index - 1, field.key)"
              class="shipper-consignee-info__table--input"
              :placeholder="items[row.index].shipperConsigneeInfo"
              autocomplete="off"
            ></b-form-input>
          </template>

          <template v-else-if="row.index == 6">
            <b-form-input
              v-model="loadStops[index - 1].references"
              :title="loadStops[index - 1].references"
              :key="field.name"
              size="sm"
              type="text"
              @blur="updateStop(index - 1, field.key)"
              class="shipper-consignee-info__table--input"
              :placeholder="items[row.index].shipperConsigneeInfo"
              autocomplete="off"
            ></b-form-input>
          </template>

          <template v-else-if="row.index == 7">
            <b-form-textarea
              v-model="loadStops[index - 1].instructions"
              :title="loadStops[index - 1].instructions"
              :key="field.name"
              class="shipper-consignee-info__table--input"
              id="textarea-small"
              size="sm"
              @blur="updateStop(index - 1, field.key)"
              :placeholder="items[row.index].shipperConsigneeInfo"
              autocomplete="off"
            ></b-form-textarea>
          </template>
        </template>
      </template>

      <template v-slot:row-details="row">
        <b-card :key="row.index">
          <AddLocation
            v-model="cancelAddLocation"
            :stopId="stopIdeForAddLocation"
          />
        </b-card>
      </template>

      <template v-slot:cell(shipperConsigneeInfo)="data">
        <span v-html="data.value"></span>
      </template>
    </b-table>
    <CarrierInfo
      :fields="fields"
      :key="keyCarrierInfo"
      :busy="isBusy"
      v-model="departureDateChanged"
    />
    <hr />
    <ItemsTable :stops="fields" :key="keyItemasTable" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

import AddLocation from "./AddLocation";
import CarrierInfo from "./CarrierInfo";
import ItemsTable from "./ItemsTable";
import AddStop from "./AddStop";
import EditAddressModal from "./EditAddressModal.vue";
import CreateContactModal from "./CreateContactModal.vue";

export default {
  name: "ShipperConsigneeInfo",
  components: {
    AddLocation,
    CarrierInfo,
    ItemsTable,
    AddStop,
    EditAddressModal,
    CreateContactModal
  },
  data() {
    return {
      isLoading: false,
      fields: [{ key: "shipperConsigneeInfo", name: "Shipper/Consignee Info" }],
      items: [
        { shipperConsigneeInfo: 'Location <span style="color:red">*</span>' },
        { shipperConsigneeInfo: 'Address <span style="color:red">*</span>' },
        {
          shipperConsigneeInfo:
            'Shipping/Receiving Contact <span style="color:red">*</span>'
        },
        {
          shipperConsigneeInfo:
            'Shipping/Receiving Hours <span style="color:red">*</span>'
        },
        {
          shipperConsigneeInfo:
            'Expected Date (<span style="color:red; font-size:10px;">*Required for Pickup</span>)'
        },
        { shipperConsigneeInfo: "Pickup/Delivery Number" },
        { shipperConsigneeInfo: "References" },
        { shipperConsigneeInfo: "Instructions" }
      ],
      numberOfStops: 0,
      sizes: ["Small", "Medium", "Large", "Extra Large"],
      cancelAddLocation: true,
      keyCarrierInfo: 0,
      keyItemasTable: 0,
      loadStops: [],
      cloneLoadStops: [],
      addStopFromModal: null,
      isBusy: false,
      locations: [],
      contacts: [],
      departureDateChanged: 0,
      stopIdeForAddLocation: null,
      keyRender: 0
    };
  },
  computed: {
    ...mapGetters("load", ["showTenderResponse"])
  },
  watch: {
    loadStops: {
      deep: true,
      async handler(val, oldVal) {
        try {
          this.isLoading = true;
          if (oldVal.length != 0) {
            for (let i in this.loadStops) {
              for (let j in this.cloneLoadStops[i]) {
                if (this.cloneLoadStops[i][j] != this.loadStops[i][j]) {
                  this.cloneLoadStops[i][j] = this.loadStops[i][j];
                  if (j == "location_name" && this.loadStops[i][j] != "") {
                    let name = `${this.loadStops[i][j]}?city=${this.loadStops[i].city}&state=${this.loadStops[i].state_province}&zip_code=${this.loadStops[i].postal_code}&type_tms=Shipper/Consignee`;
                    await this.$store.dispatch("stops/getLocations", name);
                    this.locations[i] = this.$store.getters["stops/locations"];
                    this.keyRender += 1;
                  }
                }
              }
            }
          }
        } catch (err) {
          console.error("[STOPS TABLE (409)]", err.message);
        } finally {
          this.isLoading = false;
        }
      }
    },
    cancelAddLocation() {
      if (this.cancelAddLocation == false) {
        document.getElementById("addNewLocation").click();
        this.cancelAddLocation = true;
      } else if (this.cancelAddLocation == "location added table") {
        document.getElementById("addNewLocation").click();
        this.cancelAddLocation = true;
        this.refreshStops();
      }
    },
    addStopFromModal() {
      if (typeof this.addStopFromModal == "number") {
        this.$bvModal.hide("add-stop-modal");
      } else {
        this.$bvModal.hide("add-stop-modal");
        this.refreshStops();
      }
    },
    departureDateChanged() {
      this.refreshStops();
    },
    showTenderResponse() {
      const pickupDeliveryNumberValue = this.loadStops[0]
        .pickup_delivery_number;
      const referencesValue = this.loadStops[0].references;
      this.loadStops[0].pickup_delivery_number =
        this.showTenderResponse?.new_pickup_number || pickupDeliveryNumberValue;
      this.loadStops[0].references =
        this.showTenderResponse?.new_references || referencesValue;
    }
  },
  async beforeMount() {
    this.getStops();
  },
  methods: {
    ...mapActions("stops", ["setEditMode"]),
    getStops() {
      this.loadStops = this.$store.getters["stops/loadStops"];
      for (let stop of this.loadStops) {
        let type;
        if (stop.is_dropoff == true) {
          type = "Delivery";
        } else if (stop.is_pickup == true) {
          type = "Pickup";
        }
        this.addNewStop(type, stop.id);
      }
      this.cloneLoadStops = this.loadStops.map(a => Object.assign({}, a));
    },
    addNewStop(type, stopId) {
      this.numberOfStops += 1;
      let field = {};
      field.key = stopId;
      for (let i = 0; i < this.$store.getters["stops/loadStops"].length; i++) {
        if (this.$store.getters["stops/loadStops"][i].id == stopId) {
          field.name =
            this.$store.getters["stops/loadStops"][i].name + " - " + type;
        }
      }
      this.fields.push(field);
      this.keyCarrierInfo += 1;
    },
    async getLocation(index, field) {
      if (this.locations[index] && this.locations[index].length != 0) {
        let name = this.loadStops[index].location_name.split(" -- ")[0];
        let idSelectedLocation = this.loadStops[index].location_name.split(
          " -- "
        )[1];
        for (let i = 0; i < this.locations[index].length; i++) {
          if (
            name == this.locations[index][i].name &&
            idSelectedLocation == this.locations[index][i].id
          ) {
            this.loadStops[index].location_id = this.locations[index][i].id;
            this.loadStops[index].address =
              this.locations[index][i].shipping_address.street +
              ", " +
              this.locations[index][i].shipping_address.city +
              ", " +
              this.locations[index][i].shipping_address.state +
              " " +
              this.locations[index][i].shipping_address.postal_code;
            let stopName = field.name.split(" - ")[1];
            if (stopName == "Pickup") {
              this.loadStops[index].shipping_receiving_hours = this.locations[
                index
              ][i].transportation_profile.pickup_receiving_hours;
              this.loadStops[index].appointmet_required = this.locations[index][
                i
              ].transportation_profile.pickup_appointment_required;
              this.loadStops[index].instructions = this.locations[index][
                i
              ].transportation_profile.pickup_instructions;
            } else if (stopName == "Delivery") {
              this.loadStops[index].shipping_receiving_hours = this.locations[
                index
              ][i].transportation_profile.delivery_receiving_hours;
              this.loadStops[index].appointmet_required = this.locations[index][
                i
              ].transportation_profile.delivery_appointment_required;
              this.loadStops[index].instructions = this.locations[index][
                i
              ].transportation_profile.delivery_instructions;
            }
            await this.$store.dispatch(
              "stops/getContactList",
              this.locations[index][i].id
            );
            this.contacts[index] = this.$store.getters["stops/contactList"];
            this.loadStops[index].contact_info = this.contacts[index][0].name;
            await this.getContact(index, field);
            return;
          }
        }
      }
    },
    async getContact(index, field) {
      for (let i in this.contacts[index]) {
        if (
          this.loadStops[index].contact_info == this.contacts[index][i].name
        ) {
          this.loadStops[index].contact_info = this.contacts[index][i].name;
          this.loadStops[index].contact_info_id = this.contacts[index][i].id;
        }
      }
      await this.updateStop(index, field.key);
      this.refreshStops();
    },
    async searchContact(stopId, index) {
      try {
        this.isLoading = true;
        this.loadStops[index].contact_info = null;
        for (
          let i = 0;
          i < this.$store.getters["stops/loadStops"].length;
          i++
        ) {
          if (this.$store.getters["stops/loadStops"][i].id == stopId) {
            let locationId = this.$store.getters["stops/loadStops"][i]
              .location_id;
            await this.$store.dispatch("stops/getContactList", locationId);
            this.contacts[index] = this.$store.getters["stops/contactList"];
          }
        }
      } catch (err) {
        console.error("[STOPS TABLE (551)]", err.message);
      } finally {
        this.isLoading = false;
      }
    },
    addNewLocation(row, field) {
      this.stopIdeForAddLocation = field.key;
      row.toggleDetails();
    },
    async updateStop(index, stopId) {
      let info = {};
      info.loadId = this.$route.params.loadId;
      info.stopId = stopId;
      info.body = {};
      let stop;
      for (let i = 0; i < this.$store.getters["stops/loadStops"].length; i++) {
        if (this.$store.getters["stops/loadStops"][i].id == stopId) {
          stop = this.$store.getters["stops/loadStops"][i];
        }
      }
      info.body.name = stop.name;
      info.body.number = stop.number;
      info.body.location_id = this.loadStops[index].location_id;
      info.body.contact_info_id = this.loadStops[index].contact_info_id;
      info.body.pickup_delivery_number = this.loadStops[
        index
      ].pickup_delivery_number;
      info.body.expected_date = this.loadStops[index].expected_date;
      info.body.references = this.loadStops[index].references;
      info.body.shipping_receiving_hours = this.loadStops[
        index
      ].shipping_receiving_hours;
      // Delete.?
      // info.body.appointmet_required = this.loadStops[index].appointmet_required;
      // Delete.?
      // info.body.appointment_time = this.loadStops[index].appointment_time;
      info.body.instructions = this.loadStops[index].instructions;
      await this.$store.dispatch("stops/updateStop", info);
      // this.refreshStops();
    },
    async deleteStop(stopId) {
      let info = {};
      info.stopId = stopId;
      info.loadId = this.$route.params.loadId;
      this.isBusy = true;
      let stop;
      for (let i = 0; i < this.$store.getters["stops/loadStops"].length; i++) {
        if (this.$store.getters["stops/loadStops"][i].id == stopId) {
          stop = this.$store.getters["stops/loadStops"][i];
        }
      }
      for (let i = 0; i < this.$store.getters["stops/loadStops"].length; i++) {
        if (
          this.$store.getters["stops/loadStops"][i].id != stopId &&
          parseInt(this.$store.getters["stops/loadStops"][i].number, 10) >
            stop.number
        ) {
          let infoStop = {};
          infoStop.stopId = this.$store.getters["stops/loadStops"][i].id;
          infoStop.loadId = this.$route.params.loadId;
          infoStop.body = {};
          infoStop.body.name =
            "Stop " +
            (parseInt(this.$store.getters["stops/loadStops"][i].number, 10) -
              1);
          infoStop.body.number =
            parseInt(this.$store.getters["stops/loadStops"][i].number, 10) - 1;
          await this.$store.dispatch("stops/updateStop", infoStop);
        }
      }
      await this.$store.dispatch("stops/deleteStop", info);
      this.refreshStops();
    },
    async refreshStops() {
      this.loadStops = [];
      this.fields = [];
      this.fields.push({
        key: "shipperConsigneeInfo",
        name: "Shipper/Consignee Info"
      });
      this.numberOfStops = 0;
      await this.getStops();
      this.isBusy = false;
      this.keyCarrierInfo += 1;
      this.keyItemasTable += 1;
    },
    async createContact(index) {
      await this.setEditMode(false);
      this.$bvModal.show(`create-contact-modal-${index - 1}`);
    },
    async editContact(index) {
      await this.setEditMode(true);
      this.$bvModal.show(`create-contact-modal-${index - 1}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.shipper-consignee-info {
  margin-top: 40px;
  &__table {
    @include box-shadow;
    &__add-stop {
      &--pickup {
        color: $color-user-active;
      }
      &--delivery {
        color: $color-user-busy;
      }
    }
    &__head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &--delete-stop {
        @include close-button($color-white);
      }
    }
    &__location {
      &--input {
        @extend %input-table;
      }
      &--button {
        @include secondary-button;
        box-shadow: none;
      }
    }
    &__contact {
      input {
        @extend %input-table;
        background-color: $color-light !important;
      }
      &--button {
        background-color: $color-light;
        color: $color-secondary-button;
        border: 0.5px solid $color-background-input !important;
        border-left: none !important;
        font-size: 13px !important;
        box-shadow: none;
        &:hover {
          background-color: $color-light;
          color: $color-secondary-button;
          border: 0px solid $color-light !important;
          box-shadow: none;
        }
        &:not(:disabled):not(.disabled):active {
          background-color: $color-light;
          color: $color-secondary-button;
          border-color: none !important;
          box-shadow: none;
        }
        &:focus {
          background-color: $color-light;
          color: $color-secondary-button;
          border-color: none !important;
          box-shadow: none;
        }
      }
    }
    &--input {
      @extend %input-table;
      background-color: $color-light !important;
    }
  }
}

.close {
  outline: none;
}

.card-body {
  padding: 20px !important;
}

.table .thead-light th {
  color: $color-light;
  background-color: $color-background-dark;
  border: none;
  border-radius: 6px 6px 0px 0px;
  padding: 15px;
}

.b-table {
  border-radius: 6px;
  font-size: 13px;
}
</style>
