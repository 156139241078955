<template>
  <div class="customer-quotes-table">
    <b-table
      class="items-table shadowed"
      stacked="lg"
      hover
      show-empty
      head-variant="light"
      empty-text="There are no items to show"
      sort-icon-left
      :items="items"
      :fields="fields"
      :sort-by="sortBy"
      :perPage="rowsPerPage"
      :currentPage="currentPage"
      @sort-changed="sortingChanged"
      @row-clicked="rowClicked"
      @row-middle-clicked="rowClicked"
    >
      <template v-slot:table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(customer_contact)="row">
        {{ validate(row.item.customer_contact, "name") }}
      </template>

      <!-- CREDIT HOLD TOGGLER -->
      <template #cell(credit_hold)="row">
        <b-form-checkbox
          switch
          disabled
          :checked="validate(row.item, 'credit_hold')"
        ></b-form-checkbox>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "CustomerQuotesTable",
  props: {
    fields: Array,
    items: Array,
    sortBy: String,
    tableSize: Number,
    rowsPerPage: Number,
    currentPage: Number
  },
  data() {
    return {
      deleting: false
    };
  },
  computed: {
    page: {
      get() {
        return this.currentPage;
      },
      set(currentPage) {
        this.$emit("setCurrentPage", currentPage);
      }
    }
  },
  methods: {
    rowClicked(row) {
      this.$emit("rowClicked", row);
    },
    sortingChanged(ctx) {
      this.$emit("sortChanged", ctx);
    },
    selectAll(checkStatus) {
      const checkboxes = this.$el.querySelectorAll(
        ".row-checkbox .custom-control-input"
      );
      checkboxes.forEach(function(checkbox) {
        checkbox.checked = checkStatus;
      });
    },
    async removeRows() {
      this.deleting = true;
      await this.$emit("removeRows");
      this.deleting = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.b-table {
  border-radius: 6px;
  font-size: 13px;
}
</style>
