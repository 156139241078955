<template>
  <div class="add-location">
    <p class="add-location--alert-message">--- All fields are required ---</p>
    <div class="add-location__grid">
      <div class="add-location__grid--cell">
        <label for="name">
          Name
        </label>
        <b-form-input
          id="name"
          v-model="location.name"
          placeholder="Name"
          type="text"
          size="sm"
          class="add-location--input"
          autocomplete="off"
        ></b-form-input>
      </div>

      <div class="add-location__grid--cell">
        <label for="shipping-country-code">
          Shipping Country Code
        </label>
        <b-form-input
          id="shipping-country-code"
          v-model="location.shipping_country_code"
          placeholder="Shipping Country Code"
          readonly
          type="text"
          size="sm"
          class="add-location--input"
          autocomplete="off"
        ></b-form-input>
      </div>

      <div class="add-location__grid--cell">
        <label for="shipping-street">
          Shipping Street
        </label>
        <b-form-textarea
          id="shipping-street"
          v-model="location.shipping_street"
          class="add-location--input"
          size="sm"
          placeholder="Shipping Street"
          autocomplete="off"
        ></b-form-textarea>
      </div>

      <div class="add-location__grid--cell">
        <label for="shipping-city">
          Shipping City
        </label>
        <b-input-group size="sm">
          <b-form-input
            id="shipping-city"
            v-model="location.shipping_city"
            v-on:keyup.enter="searchByCity"
            @input="selectCity"
            placeholder="Shipping City"
            type="text"
            size="sm"
            class="add-location--input"
            autocomplete="off"
            list="shipping-city-list"
            :key="cityKey"
          ></b-form-input>

          <datalist id="shipping-city-list">
            <option
              v-for="city in shippingCityList"
              v-bind:key="city.text"
              v-bind:value="city.text"
            ></option>
          </datalist>
          <b-input-group-append>
            <b-button
              size="sm"
              class="add-location--search-button"
              @click="searchByCity"
              ><i class="fas fa-search"></i
            ></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>

      <div class="add-location__grid--cell">
        <label for="shipping-state/Province-code">
          Shipping State/Province Code
        </label>
        <b-form-input
          id="shipping-state/Province-code"
          v-model="location.shipping_state_code"
          placeholder="Shipping State/Province Code"
          readonly
          type="text"
          size="sm"
          class="add-location--input"
          autocomplete="off"
        ></b-form-input>
      </div>

      <div class="add-location__grid--cell">
        <label for="shipping-zip/postal-code">
          Shipping Zip/Postal Code
        </label>
        <b-input-group size="sm">
          <b-form-input
            id="shipping-zip/postal-code"
            v-model="location.shipping_postal_code"
            v-on:keyup.enter="searchByZipCode"
            @input="selectZipCode"
            placeholder="Shipping Zip/Postal Code"
            type="text"
            size="sm"
            class="add-location--input"
            autocomplete="off"
            list="shipping-zip-code-list"
            :key="zipCodeKey"
          ></b-form-input>

          <datalist id="shipping-zip-code-list">
            <option
              v-for="zip in shippingZipCodeList"
              v-bind:key="zip.text"
              v-bind:value="zip.text"
            ></option>
          </datalist>

          <b-input-group-append>
            <b-button
              size="sm"
              class="add-location--search-button"
              @click="searchByZipCode"
              ><i class="fas fa-search"></i
            ></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>

      <div class="add-location__grid--cell">
        <label for="first-name">
          First Name
        </label>
        <b-form-input
          id="first-name"
          v-model="location.first_name"
          placeholder="First Name"
          type="text"
          size="sm"
          class="add-location--input"
          autocomplete="off"
        ></b-form-input>
      </div>

      <div class="add-location__grid--cell">
        <label for="last-name">
          Last Name
        </label>
        <b-form-input
          id="last-name"
          v-model="location.last_name"
          placeholder="Last Name"
          type="text"
          size="sm"
          class="add-location--input"
          autocomplete="off"
        ></b-form-input>
      </div>

      <div class="add-location__grid--cell">
        <label for="phone">
          Phone
        </label>
        <b-form-input
          id="phone"
          v-model="location.phone"
          placeholder="Phone"
          type="text"
          size="sm"
          class="add-location--input"
          autocomplete="off"
        ></b-form-input>
      </div>

      <div class="add-location__grid--cell">
        <label for="email">
          Email
        </label>
        <b-form-input
          id="email"
          v-model="location.email"
          placeholder="Email"
          type="text"
          size="sm"
          class="add-location--input"
          autocomplete="off"
        ></b-form-input>
      </div>
    </div>

    <div v-if="showSuccessMessage" class="add-location--sucess-message">
      Account created successfully
    </div>

    <div class="add-location__buttons">
      <b-button @click="cancel" class="add-location__buttons--cancel" size="sm"
        ><i class="fas fa-times-circle"></i> Cancel</b-button
      >
      <b-overlay
        :show="addingLocation"
        rounded
        opacity="0.6"
        spinner-small
        class="d-inline-block"
        @hidden="onHidden"
      >
        <b-button
          ref="button-save-location"
          class="add-location__buttons--save"
          size="sm"
          @click="addLocation"
          ><i class="fas fa-check-circle"></i> Save Location</b-button
        >
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { emailValidator, phoneValidator } from "@/utils/helpers.js";

export default {
  name: "AddLocation",
  props: {
    stopId: String
  },
  data() {
    return {
      emailValidator,
      location: {
        name: "",
        shipping_country_code: "",
        shipping_street: "",
        shipping_city: "",
        shipping_state_code: "",
        shipping_postal_code: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: ""
      },
      shippingCityList: [],
      shippingZipCodeList: [],
      cityKey: 0,
      zipCodeKey: 0,
      showSuccessMessage: false,
      addingLocation: false
    };
  },
  computed: {
    ...mapGetters("load", ["selectedLoad"]),
    ...mapGetters("stops", ["loadStops"])
  },
  methods: {
    async searchByZipCode() {
      await this.$store.dispatch(
        "stops/getShippingZipCode",
        this.location.shipping_postal_code
      );
      let options = [];
      for (let i in this.$store.getters["stops/shippingZipCode"]) {
        let option = {};
        option.value = this.$store.getters["stops/shippingZipCode"][i];
        option.text =
          this.$store.getters["stops/shippingZipCode"][i].city +
          ", " +
          this.$store.getters["stops/shippingZipCode"][i].state +
          " " +
          this.$store.getters["stops/shippingZipCode"][i].zip_code;
        options.push(option);
      }
      this.shippingZipCodeList = options;
    },
    async searchByCity() {
      await this.$store.dispatch(
        "stops/getShippingCity",
        this.location.shipping_city
      );
      let options = [];
      for (let i in this.$store.getters["stops/shippingCity"]) {
        let option = {};
        option.value = this.$store.getters["stops/shippingCity"][i];
        option.text =
          this.$store.getters["stops/shippingCity"][i].city +
          ", " +
          this.$store.getters["stops/shippingCity"][i].state +
          " " +
          this.$store.getters["stops/shippingCity"][i].zip_code;
        options.push(option);
      }
      this.shippingCityList = options;
    },
    selectZipCode(e) {
      for (let i in this.shippingZipCodeList) {
        if (this.shippingZipCodeList[i].text == e) {
          this.location.shipping_country_code = this.shippingZipCodeList[
            i
          ].value.country;
          this.location.shipping_state_code = this.shippingZipCodeList[
            i
          ].value.state;
          this.location.shipping_city = this.shippingZipCodeList[i].value.city;
          this.location.shipping_postal_code = this.shippingZipCodeList[
            i
          ].value.zip_code;
          this.zipCodeKey += 1;
        }
      }
    },
    selectCity(e) {
      for (let i in this.shippingCityList) {
        if (this.shippingCityList[i].text == e) {
          this.location.shipping_country_code = this.shippingCityList[
            i
          ].value.country;
          this.location.shipping_state_code = this.shippingCityList[
            i
          ].value.state;
          this.location.shipping_postal_code = this.shippingCityList[
            i
          ].value.zip_code;
          this.location.shipping_city = this.shippingCityList[i].value.city;
          this.cityKey += 1;
        }
      }
    },
    async addLocation() {
      try {
        let objectVerified = await this.verifyObject();
        if (objectVerified == true) {
          this.addingLocation = true;
          let info = {};
          info.location = {};
          info.location.name = this.location.name;
          info.location.country_code = this.location.shipping_country_code;
          info.location.street = this.location.shipping_street;
          info.location.city = this.location.shipping_city;
          info.location.state_code = this.location.shipping_state_code;
          info.location.postal_code = this.location.shipping_postal_code;
          info.contact = {};
          info.contact.account_id = "";
          info.contact.first_name = this.location.first_name;
          info.contact.last_name = this.location.last_name;
          info.contact.phone = this.location.phone;
          info.contact.email = this.location.email;

          if (!this.emailValidator(this.location.email))
            throw new Error("Invalid email");
          const phoneValidation = phoneValidator(this.location.phone);
          if (!phoneValidation[0]) {
            throw new Error(phoneValidation[1]);
          }
          const payload = {
            stopId: this.stopId,
            loadId: this.selectedLoad.id,
            info
          };
          await this.$store.dispatch("stops/addLocation", payload);
          let accountId = this.$store.getters["stops/accountId"];
          let contactId = this.$store.getters["stops/contactId"];
          await this.$store.dispatch("stops/getLocationById", accountId);
          let locationId = this.$store.getters["stops/selectedLocation"][0].id;
          if (typeof this.stopId !== "undefined") {
            let infoStop = {};
            infoStop.loadId = this.$route.params.loadId;
            infoStop.stopId = this.stopId;
            infoStop.body = {};
            infoStop.body.location_id = locationId;
            infoStop.body.contact_info_id = contactId;
            infoStop.body.expected_date = "";
            infoStop.body.references = "";
            infoStop.body.shipping_receiving_hours = "";
            infoStop.body.appointmet_required = false;
            infoStop.body.appointment_time = "";
            infoStop.body.instructions = "";
            await this.$store.dispatch("stops/updateStop", infoStop);
          }
          if (this.$store.getters["stops/contactId"] != null) {
            this.showSuccessMessage = true;
          }
          await this.sleep(2000);
          this.showSuccessMessage = false;
          if (typeof this.stopId !== "undefined") {
            return this.$emit("input", "location added table");
          } else {
            return this.$emit("input", "location added add stop");
          }
        } else {
          throw new Error("All fields are required");
        }
      } catch (err) {
        console.error("[ADD LOCATION (396)]", err.message);
        this.showAlert({ text: err.message });
      } finally {
        this.addingLocation = false;
      }
    },
    verifyObject() {
      for (var key in this.location) {
        if (this.location[key] == null || this.location[key] == "")
          return false;
      }
      return true;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    cancel() {
      return this.$emit("input", false);
    },
    onHidden() {
      this.$refs["button-save-location"].focus();
    },
    setInitialLocation() {
      const stop = this.loadStops.find(stop => stop.id === this.stopId);
      if (typeof stop !== "undefined" && stop !== null) {
        this.location.city = stop.city;
        this.location.shipping_country_code = stop.country;
        this.location.shipping_city = stop.city;
        this.location.shipping_state_code = stop.state_province;
        this.location.shipping_postal_code = stop.postal_code;
      }
    }
  },
  created() {
    this.setInitialLocation();
  }
};
</script>

<style lang="scss" scoped>
.add-location {
  color: #2c3e50;
  &--alert-message {
    color: red;
    text-align: center !important;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 350px));
    justify-content: center;
    &--cell {
      @include form-cell;
    }
  }
  &--input {
    font-size: 11.8px !important;
  }
  &--search-button {
    @include secondary-button;
    box-shadow: none;
    font-size: 11.8px !important;
  }
  &--sucess-message {
    color: $color-user-active;
    font-size: 15px;
    text-align: right;
    margin-top: 10px;
  }
  &__buttons {
    margin-top: 10px;
    @include flex-wrap(flex-end);
    &--cancel {
      @include secondary-button;
    }
    &--save {
      @include primary-button;
      margin-left: 10px;
    }
  }
}
</style>
