<template>
  <div class="documents-table">
    <b-table
      :busy="busy"
      class="table shadowed"
      stacked="lg"
      hover
      show-empty
      head-variant="light"
      empty-text="There are no documents to show"
      :items="dataTable"
      :sortBy="sortBy"
      :fields="fields"
      :perPage="rowsPerPage"
      :currentPage="currentPage"
      :tbody-tr-class="underLineRow"
    >
      <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(uploaded_by)="row">
        <span class="document-name">
          {{ row.item.metadata["uploaded-by"] }}
        </span>
      </template>
      <template #cell(file_name)="row">
        <span class="document-name">
          {{ row.item.file_name | formatName }}</span
        >
      </template>
      <template #cell(download)="row">
        <button class="download-button btn-sm" @click="downloadFile(row.item)">
          <font-awesome-icon icon="file-download" />
        </button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DocumentsTable",
  computed: {
    ...mapGetters("documents", ["documents", "selectedDocument"]),
    ...mapGetters("load", ["selectedLoad"])
  },
  props: {
    busy: Boolean,
    fileNameSelected: String
  },
  data() {
    return {
      rowsPerPage: 10,
      dataTable: [],
      currentPage: 1,
      sortBy: "file_name",
      fields: [
        { key: "file_name", label: "Document Name" },
        { key: "uploaded_by", label: "Uploaded By" },
        { key: "download" }
      ],
      fileName: {
        BILL_OF_LADING: "signed_bol_",
        LTL_BILL_OF_LADING: "bol_",
        LTL_SHIPPING_LABELS: "labels_",
        EASY_POST_LABEL: " labels_",
        DELIVERY_RECEIPT: "pod_",
        WEIGHT_CERTIFICATE: "weight_certificate_"
      }
    };
  },
  watch: {
    documents() {
      this.renderTableInfo();
    }
  },
  created() {
    this.renderTableInfo();
  },
  methods: {
    renderTableInfo() {
      this.dataTable = this.documents.filter(item => item.url != "");
    },
    underLineRow() {
      // console.log(item);
      // console.log(document.getElementsByClassName("table shadowed")?.style);
      // if (this.fileNameSelected) {
      //   console.log(item.file_name, this.fileNameSelected);
      //   if (item.file_name === this.fileNameSelected) {
      //     return "custom-highlight";
      //   }
      //   return "";
    },
    rowClass() {
      return "table-row";
    },
    difineFileName(fileName) {
      const prefix = this.fileName[fileName];
      const newName = prefix + this.selectedLoad.name;
      return newName;
    },
    async downloadFile(doc) {
      const url = doc.url;
      const newName = this.difineFileName(doc.file_name);

      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = newName;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.error("Error al descargar el archivo:", error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-highlight {
  background-color: #dd1717 !important; /* Cambia el color de fondo a tu preferencia */
}

.download-button {
  @include primary-button;
}
</style>
