import axios from "axios";
import API from "./api/methods.js";

export default {
  namespaced: true,
  state: {
    picklist: {},
    status: null,
    userSalesforce: {}
  },
  getters: {
    userSalesforce(state) {
      return state.userSalesforce;
    },
    picklist(state) {
      return state.picklist;
    }
  },
  mutations: {
    SetPicklist(state, newPicklist) {
      state.picklist = newPicklist;
    },
    SetStatus(state, newStatus) {
      state.status = newStatus;
    },
    SetUserSalesforce(state, newUserSalesforce) {
      state.userSalesforce = newUserSalesforce;
    }
  },
  actions: {
    getUserSalesforce({ commit }, id) {
      const resource = `/user/${id}`;
      return API.get(resource, true).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting user data from server");
        commit("SetUserSalesforce", res.data);
      });
    },
    getPicklistStatus({ commit }) {
      const resource = `/user/picklists`;
      return API.get(resource, true).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting user status picklists from server");
        commit("SetPicklist", res.data);
      });
    },
    changeStatus({ commit }, info) {
      const resource = `${process.env.VUE_APP_BASE_URL}/api/user/${info.id}`;
      // Getting the status token used only for this request
      const token = JSON.parse(
        localStorage.getItem("uploader-status-department")
      ).access_token;
      return axios
        .patch(resource, info.availability, { headers: { token } })
        .then(function(res) {
          if (typeof res === "undefined")
            throw new Error("Error getting updating user status from server");
          commit("SetStatus", res.data);
        });
    }
  }
};
