<template>
  <div class="edit-hazmat">
    <div class="edit-hazmat__field">
      <span class="edit-hazmat__field--label">
        <span>Hazmat contact: </span>
        <b-icon
          icon="arrow-clockwise"
          v-if="loadingContacts"
          animation="spin"
        ></b-icon>
      </span>
      <b-form-input
        v-model="hazmat.hazmat_contact.name"
        size="sm"
        @input="searchContacts"
        autocomplete="off"
        list="hazmat-contact"
      ></b-form-input>

      <datalist id="hazmat-contact">
        <option
          v-for="(contactName, contactId) in hazmatSelects.contactsList"
          v-bind:key="contactId"
          >{{ contactName }}</option
        >
      </datalist>
    </div>

    <div class="edit-hazmat__field">
      <span class="edit-hazmat__field--label">Hazmat number type:</span>
      <b-form-input
        v-model="hazmat.hazmat_number_type"
        size="sm"
        autocomplete="off"
        list="hazmat-number"
      ></b-form-input>

      <datalist id="hazmat-number">
        <option
          v-for="hazmatNumber in hazmatSelects.numberTypes"
          v-bind:key="hazmatNumber"
          >{{ hazmatNumber }}</option
        >
      </datalist>
    </div>

    <div class="edit-hazmat__field">
      <span class="edit-hazmat__field--label">Hazmat number:</span>
      <b-form-input
        v-model="hazmat.hazmat_number"
        size="sm"
        type="number"
        autocomplete="off"
      ></b-form-input>
    </div>
    <div class="edit-hazmat__field">
      <span class="edit-hazmat__field--label">Hazmat class:</span>
      <b-form-input
        v-model="hazmat.hazmat_class_division"
        size="sm"
        autocomplete="off"
        list="hazmat-class"
      ></b-form-input>

      <datalist id="hazmat-class">
        <option
          v-for="hazmatClass in hazmatSelects.classes"
          v-bind:key="hazmatClass"
          >{{ hazmatClass }}</option
        >
      </datalist>
    </div>

    <div class="edit-hazmat__field">
      <span class="edit-hazmat__field--label">Hazmat packaging group:</span>
      <b-form-input
        v-model="hazmat.hazmat_packaging_group"
        size="sm"
        autocomplete="off"
        list="hazmat-packaging-group"
      ></b-form-input>

      <datalist id="hazmat-packaging-group">
        <option
          v-for="hazmatPackagingGroup in hazmatSelects.packagingGroups"
          v-bind:key="hazmatPackagingGroup"
          >{{ hazmatPackagingGroup }}</option
        >
      </datalist>
    </div>

    <hr v-if="fromTable" />
    <div v-if="fromTable" class="edit-hazmat__buttons">
      <b-button
        class="edit-hazmat__buttons--cancel"
        size="sm"
        @click="closeModal"
        ><i class="fas fa-times-circle"></i> Cancel</b-button
      >
      <b-button
        class="edit-hazmat__buttons--save"
        size="sm"
        @click="saveHazmat"
      >
        <div>
          <i
            class="fas fa-check-circle"
            v-if="!savingHazmat"
            key="check-for-add-item"
          ></i>
        </div>
        <div>
          <b-icon
            icon="arrow-clockwise"
            animation="spin"
            v-if="savingHazmat"
          ></b-icon>
        </div>
        <span class="ml-1">Save Item</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EditHazmat",
  props: {
    fromTable: Boolean,
    hazmat: {
      type: Object,
      default: function() {
        return {
          hazmat_contact: { id: "", name: "" },
          hazmat_class_division: "",
          hazmat_number_type: "",
          hazmat_number: "",
          hazmat_packaging_group: ""
        };
      }
    },
    itemId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      contactSearchTimeout: null
    };
  },
  computed: {
    ...mapGetters("lineItem", [
      "hazmatSelects",
      "loadingContacts",
      "initialLineItems",
      "savingHazmat"
    ])
  },
  mounted() {
    if (!this.itemId) {
      this.hazmat.hazmat_contact.name = "NRC - 24Hr Emergency";
    }
  },
  methods: {
    ...mapActions("lineItem", ["searchHazmatContacts", "editHazmatFields"]),
    searchContacts() {
      if (this.contactSearchTimeout != null) {
        clearTimeout(this.contactSearchTimeout);
      }
      this.contactSearchTimeout = setTimeout(() => {
        this.searchHazmatContacts(this.hazmat.hazmat_contact.name);
      }, 1000);
    },
    fullData() {
      let finishedHazmat = {
        hazmat_class_division: this.hazmat.hazmat_class_division,
        hazmat_number_type: this.hazmat.hazmat_number_type,
        hazmat_number: this.hazmat.hazmat_number,
        hazmat_packaging_group: this.hazmat.hazmat_packaging_group
      };

      if (this.itemId) {
        let initialContact = this.initialLineItems.find(
          item => item.id == this.itemId
        ).hazmatFields.hazmat_contact;

        if (initialContact.name != this.hazmat.hazmat_contact.name) {
          finishedHazmat.hazmat_contact = Object.keys(
            this.hazmatSelects.contactsList
          ).find(
            key =>
              this.hazmatSelects.contactsList[key] ===
              this.hazmat.hazmat_contact.name
          );
        } else {
          finishedHazmat.hazmat_contact = this.hazmat.hazmat_contact.id;
        }
      } else {
        if (this.hazmat.hazmat_contact.name == "NRC - 24Hr Emergency") {
          finishedHazmat.hazmat_contact = "0031I00000AR3lKQAT";
        } else {
          finishedHazmat.hazmat_contact = Object.keys(
            this.hazmatSelects.contactsList
          ).find(
            key =>
              this.hazmatSelects.contactsList[key] ===
              this.hazmat.hazmat_contact.name
          );
        }
      }

      return finishedHazmat;
    },
    saveHazmat() {
      let finishedHazmat = this.fullData();
      this.editHazmatFields({
        itemId: this.itemId,
        hazmat: finishedHazmat,
        contactName: this.hazmat.hazmat_contact.name
      }).then(() => {
        this.closeModal();
      });
    },
    closeModal() {
      let initialHazmat = this.initialLineItems.find(
        item => item.id == this.itemId
      ).hazmatFields;

      this.hazmat.hazmat_contact.name = initialHazmat.hazmat_contact.name;
      this.hazmat.hazmat_contact.id = initialHazmat.hazmat_contact.id;
      this.hazmat.hazmat_number_type = initialHazmat.hazmat_number_type;
      this.hazmat.hazmat_number = initialHazmat.hazmat_number;
      this.hazmat.hazmat_packaging_group = initialHazmat.hazmat_packaging_group;
      this.hazmat.hazmat_class_division = initialHazmat.hazmat_class_division;

      this.$emit("closeModal");
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-hazmat {
  &__field {
    @include modal-form-field(20% 80%);
    input {
      font-size: 13px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    &--cancel {
      @include secondary-button;
    }
    &--save {
      display: flex;
      @include primary-button;
      margin-left: 10px;
    }
  }
}
</style>
