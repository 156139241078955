import axios from "axios";

import API from "./api/methods.js";

export default {
  namespaced: true,
  state: () => ({
    token: null,
    userLoggedIn: null,
    userDepartment: null
  }),
  getters: {
    showToken(state) {
      return state.token;
    },
    showUserLoggedIn(state) {
      return state.userLoggedIn;
    },
    showUserDepartment(state) {
      return state.userDepartment;
    }
  },

  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUserLoggedIn(state, userLoggedIn) {
      state.userLoggedIn = userLoggedIn;
    },
    setUserDepartment(state, newUserDepartment) {
      state.userDepartment = newUserDepartment;
    }
  },

  actions: {
    async login(ctx, login) {
      const resource = `${process.env.VUE_APP_BASE_URL}/api/auth/access-token`;
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      let body = `grant_type=&username=${login.username}&password=${login.password}&scope=&client_id=&client_secret=`;
      return axios.post(resource, body, headers);
    },
    async setLoginData({ commit }, data) {
      if (data.department.length === 0)
        throw new Error("User does not have any department");
      // Sorting departments in a descendent way
      if (data.department.length > 1) {
        const departments = ["ltl", "otr", "tc", "ss"];
        data.department.map(element => {
          if (departments.includes(element.department_name.toLowerCase())) {
            localStorage.setItem(
              "uploader-status-department",
              JSON.stringify(element)
            );
          }
        });
        /* data.department.sort(function(dep1, dep2) {
          return (
            Number.parseInt(dep2.department_id) -
            Number.parseInt(dep1.department_id)
          );
        }); */
        // Capturing the status responsible department from user departments
        // const statusDepartment = data.department.pop();
        // localStorage.setItem(
        //   "uploader-status-department",
        //   JSON.stringify(statusDepartment)
        // );
      } else {
        localStorage.setItem(
          "uploader-status-department",
          JSON.stringify(data.department[0])
        );
      }

      localStorage.setItem(
        "charlie-uploader-actual-department",
        JSON.stringify(data.department[0])
      );

      // Sets the first department as default department
      commit("setUserDepartment", data.department[0]);

      localStorage.setItem("charlie-uploader-user", JSON.stringify(data));
      commit("setUserLoggedIn", data);
      commit("setToken", data.department[0].access_token);
    },
    async refreshToken({ dispatch }) {
      const token = JSON.parse(
        localStorage.getItem("charlie-uploader-actual-department")
      ).access_token;
      const resource = `/auth/refresh-token?token=${token}`;
      return API.post(resource, null, true).then(async function(res) {
        await dispatch("setLoginData", res.data);
      });
    }
  }
};
