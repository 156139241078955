<template>
  <article>
    <button class="btn-md accessorial-button" v-b-modal.more-accessorials>
      Accessorials
      <b-badge v-if="totalAccessorialsSelected > 0" variant="light">{{
        totalAccessorialsSelected
      }}</b-badge>
    </button>
    <b-modal
      class="accessorial-modal"
      centered
      size="lg"
      id="more-accessorials"
      ref="more-accessorials"
      no-close-on-backdrop
    >
      <template #modal-header>
        <h4>Accessorials</h4>
      </template>
      <article class="search-container">
        <b-input-group size="sm">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            type="text"
            class="form-control
                form-control-sm"
            v-model="searchAccessorial"
            placeholder="Search..."
          ></b-form-input>
          <b-input-group-append>
            <b-button
              v-show="searchAccessorial"
              @click="clearSearchFilter()"
              variant="info"
              >X</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </article>
      <b-overlay :show="isLoading" opacity="0.7" class="h-100">
        <article :key="renderComponente" class="accessorial-checkbox-container">
          <section class="accessorial-checkbox-container__section">
            <h6>General</h6>
            <div
              v-if="generalAccessorials.length > 0"
              class="accessorial-checkbox-container__checkbox-section"
            >
              <div
                v-for="(accessorial, index) in generalAccessorials"
                :key="index"
              >
                <b-form-checkbox
                  :id="`checkbox-1${index}`"
                  v-model="accessorialSelected.general[accessorial.id]"
                  :name="`checkbox-1${index}`"
                  @change="updateAccessorial($event, accessorial, 'general')"
                >
                  {{ accessorial.accessorial_name }}
                </b-form-checkbox>
              </div>
            </div>
            <span
              class="accessorial-checkbox-container__checkbox-section"
              v-else
              >No accessorials found</span
            >
          </section>
          <br />
          <section class="accessorial-checkbox-container__section">
            <h6>Pick up</h6>
            <div
              v-if="pickupAccessorials.length > 0"
              class="accessorial-checkbox-container__checkbox-section"
            >
              <div
                v-for="(accessorial, index) in pickupAccessorials"
                :key="index"
              >
                <b-form-checkbox
                  :id="`checkbox-2${index}`"
                  v-model="accessorialSelected.pickup[accessorial.id]"
                  :name="`checkbox-2${index}`"
                  @change="updateAccessorial($event, accessorial, 'pickup')"
                >
                  {{ accessorial.accessorial_name }}
                </b-form-checkbox>
              </div>
            </div>
            <span
              class="accessorial-checkbox-container__checkbox-section"
              v-else
              >No accessorials found</span
            >
          </section>
          <br />
          <section
            class="accessorial-checkbox-container__section--without-separetor"
          >
            <h6>Delivery</h6>
            <div
              v-if="deliveryAccessorials.length > 0"
              class="accessorial-checkbox-container__checkbox-section"
            >
              <div
                v-for="(accessorial, index) in deliveryAccessorials"
                :key="index"
              >
                <b-form-checkbox
                  :id="`checkbox-3${index}`"
                  v-model="accessorialSelected.delivery[accessorial.id]"
                  :name="`checkbox-3${index}`"
                  @change="updateAccessorial($event, accessorial, 'delivery')"
                >
                  {{ accessorial.accessorial_name }}
                </b-form-checkbox>
              </div>
            </div>
            <span
              class="accessorial-checkbox-container__checkbox-section"
              v-else
              >No accessorials found</span
            >
          </section>
        </article>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="info"
          size="sm"
          @click="cancel((searchAccessorial = ''))"
        >
          Close
        </b-button>
      </template>
    </b-modal>
  </article>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AccessorialSection",
  data() {
    return {
      accessorialSelected: { general: {}, pickup: {}, delivery: {} },
      generalAccessorials: [],
      pickupAccessorials: [],
      deliveryAccessorials: [],
      searchAccessorial: "",
      renderComponente: 0,
      isLoading: false
    };
  },
  components: {},
  computed: {
    ...mapGetters("load", [
      "selectedLoad",
      "showPanelAdminAccessorialData",
      "loadAccessorials"
    ]),
    totalAccessorialsSelected() {
      let arrayWithoutDuplicates = [...this.loadAccessorials];
      let propertyValidation = {};
      arrayWithoutDuplicates = arrayWithoutDuplicates.filter(current => {
        let exists = !propertyValidation[current.name];
        propertyValidation[current.name] = true;
        return exists;
      });
      return arrayWithoutDuplicates.length;
    }
  },
  watch: {
    searchAccessorial() {
      if (this.searchAccessorial) {
        this.generalAccessorials = this.filterAccessorial("general");
        this.pickupAccessorials = this.filterAccessorial("pick up");
        this.deliveryAccessorials = this.filterAccessorial("delivery");
      } else {
        this.fillAllData();
      }
    },
    loadAccessorials() {
      this.reviewAccessorialsByLoad();
      this.fillAllData();
    }
  },
  created() {
    this.reviewAccessorialsByLoad();
    this.fillAllData();
  },
  methods: {
    fillAccessorialByType(type) {
      return this.showPanelAdminAccessorialData.filter(
        accessorial => accessorial.accessorial_type === type
      );
    },
    fillAllData() {
      this.generalAccessorials = this.fillAccessorialByType("general");
      this.pickupAccessorials = this.fillAccessorialByType("pick up");
      this.deliveryAccessorials = this.fillAccessorialByType("delivery");
    },
    checkAccessorials(array, value) {
      array.forEach(accessorial => {
        const accessorialType =
          accessorial?.accessorial_type === "pick up"
            ? "pickup"
            : accessorial?.accessorial_type;
        this.accessorialSelected[accessorialType][accessorial.id] = value;
      });
    },
    reviewAccessorialsByLoad() {
      let accessorialChecked = [];
      this.loadAccessorials.forEach(itemA => {
        this.showPanelAdminAccessorialData.forEach(itemB => {
          if (
            itemA.name === itemB?.accessorial_name ||
            itemA.name === itemB?.accessorial_reve_name
          ) {
            accessorialChecked.push(itemB);
          }
        });
      });
      this.checkAccessorials(accessorialChecked, true);
    },
    clearSearchFilter() {
      this.searchAccessorial = "";
      this.fillAllData();
    },
    filterAccessorial(type) {
      return this.showPanelAdminAccessorialData.filter(item => {
        return (
          item.accessorial_type === type &&
          item.accessorial_name
            .toLowerCase()
            .includes(this.searchAccessorial.toLowerCase())
        );
      });
    },
    async addAccessorial(accessorial) {
      const accessorialType =
        accessorial?.accessorial_type === "pick up"
          ? "pickup"
          : accessorial?.accessorial_type;
      const accessorialId = accessorial.id;
      this.accessorialSelected[accessorialType][accessorialId] = true;
      const payload = {};
      payload.id = accessorial?.accessorial_reve_id;
      payload.name = accessorial?.accessorial_name;
      payload.load = this.selectedLoad?.id;
      try {
        if (accessorial?.accessorial_name === "") {
          throw new Error("Empty accessorial name");
        }
        this.isLoading = true;
        await this.$store.dispatch("load/createAccessorialToLoad", payload);
        this.searchAccessorial = "";
      } catch (err) {
        console.error(err.message);
        this.accessorialSelected[accessorialType][accessorialId] = false;
        this.showAlert({ text: err.message });
        this.renderComponente += 1;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteAccessorial(accessorial) {
      const idToDelete = this.loadAccessorials.filter(
        acce =>
          accessorial.accessorial_name === acce.name ||
          accessorial.accessorial_reve_name === acce.name
      );
      const accessorialType =
        accessorial?.accessorial_type === "pick up"
          ? "pickup"
          : accessorial?.accessorial_type;
      const accessorialId = accessorial.id;
      const payload = {};
      payload.accessorial_id = idToDelete[0]?.id;
      payload.load = this.selectedLoad?.id;
      try {
        this.isLoading = true;
        await this.$store.dispatch("load/deleteAccessorialToLoad", payload);
        this.searchAccessorial = "";
      } catch (err) {
        console.error(err.message);
        this.accessorialSelected[accessorialType][accessorialId] = true;
        this.showAlert({ text: err.message });
        this.renderComponente += 1;
      } finally {
        this.isLoading = false;
      }
    },
    updateAccessorial(value, accessorial) {
      if (value) {
        this.addAccessorial(accessorial);
      } else {
        this.deleteAccessorial(accessorial);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.accessorial-button {
  @include primary-button;
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
  position: relative;
  margin-right: 1rem;
}

.search-container {
  margin: 0 0.5rem 1.5rem;
}
.accessorial-checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;

  &__section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 33%;
    border-right: 1.5px solid #ced4da;
    &--without-separetor {
      border-right: none;
      h6 {
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
        margin-left: 0.5rem;
      }
    }
    h6 {
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
      margin-left: 0.5rem;
    }
  }
  &__checkbox-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 188px;
    border-top: 1px solid #ced4da;
    padding-top: 1rem;
    overflow: scroll;
    overflow-x: hidden;
  }
  span {
    padding-top: 1rem;
  }
}

::v-deep {
  .modal-header {
    align-items: center;
    justify-content: center;
    border-bottom: none;
    padding: 1rem 1rem 0;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
  }

  .badge {
    position: absolute;
    float: none;
    top: -6px;
    border-radius: 50px;
    border: 1px solid $color-primary-button;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    text-align: center;
    min-width: 20px;
  }
  .input-group-text {
    background-color: $color-primary-button;
    border: 1px solid $color-primary-button;
    color: $color-white;
  }
  .custom-control {
    margin: 0 0.5rem;
  }
  .badge-light {
    color: $color-primary-button;
  }
}

::-webkit-scrollbar {
  width: 3px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ced4da;
  border-radius: 8px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-primary-button;
}
</style>
