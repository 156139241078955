<template>
  <div class="financial-summary">
    <CollapsingContent
      title="Financial Summary"
      icon="book"
      content="financial-summary"
      backgroundColor="background-color: #8675a9"
    >
      <div class="financial-summary-content">
        <FinancialSummaryTable
          :fields="fields"
          :items="financialSummary"
          :sortBy="sortBy"
          :tableSize="3"
          :rowsPerPage="rowsPerPage"
          :currentPage="currentPage"
        />
      </div>

      <div class="mt-4">
        <b-form-select
          size="sm"
          class="currency-select col-2"
          :options="picklists.currency_iso_code.labels"
          value="U.S. Dollar"
          @change="changeUnits"
        ></b-form-select>
        <span
          v-if="typeof selectedLoad.created_date != 'undefined'"
          class="ml-3"
        >
          Updated {{ validate(selectedLoad, "created_date") | toReadableDate }}
        </span>
      </div>
    </CollapsingContent>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import CollapsingContent from "@/components/CollapsingContent.vue";
import FinancialSummaryTable from "@/components/AllQuotes/FinancialSummaryTable.vue";

export default {
  name: "FinancialSummary",
  components: {
    CollapsingContent,
    FinancialSummaryTable
  },
  data() {
    return {
      rowsPerPage: 3,
      currentPage: 1,
      search: "",
      sortBy: "",
      fields: [
        {
          key: "step",
          label: "",
          sortable: false
        },
        {
          key: "customer",
          sortable: false
        },
        {
          key: "vendor",
          sortable: false
        },
        {
          key: "margin",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters("load", ["selectedLoad", "picklists"]),
    ...mapGetters("financialSummary", ["financialSummary"])
  },
  methods: {
    ...mapActions("financialSummary", ["changeCurrency"]),
    changeUnits(newC) {
      try {
        const currency = this.getPicklistValue(
          newC,
          this.picklists.currency_iso_code
        );
        this.changeCurrency(currency);
      } catch (err) {
        console.error("[FINNANCIAL SUMMARY (92)]", err.message);
        this.showAlert({ text: err.message });
      }
    }
  }
};
</script>
