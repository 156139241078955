<template>
  <main>
    <b-overlay :show="isLoading" opacity="0.7">
      <div v-if="shortHistoryData.length > 0">
        <Splitter :key="render" style="height: 500px">
          <SplitterPanel :size="30" :minSize="30">
            <section class="table-section">
              <b-table
                ref="historyTable"
                class="items-table shadowed mt-2"
                stacked="lg"
                hover
                show-empty
                head-variant="light"
                empty-text="There are no items to show"
                :fields="fields"
                :items="shortHistoryData"
                @row-clicked="showHistory"
                selectable
                select-mode="single"
              ></b-table>
            </section>
          </SplitterPanel>
          <SplitterPanel v-if="showPanel" :size="70" :minSize="20">
            <section class="viewer-section">
              <div class="viewer-section__header">
                <span
                  class="viewer-section__header--back-option"
                  @click="
                    {
                      (showPanel = false), $refs.historyTable.clearSelected();
                    }
                  "
                >
                  <b-icon
                    icon="arrow-left-circle"
                    aria-hidden="true"
                    scale="2"
                    variant="info"
                  ></b-icon>
                </span>
                <span class="viewer-section__header--title">
                  <h4>History</h4>
                </span>
              </div>
              <!-- <span>{{ historyByShp }}</span> -->
              <json-viewer
                v-if="historyByShp"
                :value="historyByShp"
                :expand-depth="5"
                copyable
                expanded
              ></json-viewer>
            </section>
          </SplitterPanel>
        </Splitter>
      </div>
      <p v-else>There are no items to show</p>
    </b-overlay>
  </main>
</template>

<script>
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import { mapGetters } from "vuex";

export default {
  name: "PanelHistory",
  data() {
    return {
      isLoading: false,
      showPanel: false,
      fields: [
        { key: "created_at", label: "Quoted At" },
        { key: "version_id", label: "Version Id" },
        { key: "quote_type", label: "Quote Type" }
      ],
      shortHistoryData: [],
      historyByShp: null,
      render: 0
    };
  },
  components: { Splitter, SplitterPanel },
  computed: {
    ...mapGetters("load", ["selectedLoad"])
  },
  methods: {
    async getShortHistoryByShp() {
      this.showPanel = false;
      this.historyByShp = null;
      this.isLoading = true;
      const shp = this.selectedLoad?.name;
      try {
        const response = await this.$store.dispatch(
          "history/getShortHistoryByShp",
          shp
        );
        this.render += 1;
        this.shortHistoryData = response?.data?.data;
        this.isLoading = false;
      } catch (err) {
        console.error(err.message);
        this.isLoading = false;
      }
    },
    async getHistoryByHaulVersionId(item) {
      this.isLoading = true;
      const payload = {};
      payload.shpData = this.selectedLoad?.name;
      payload.object_id = item.object_id;
      try {
        this.historyByShp = null;
        const response = await this.$store.dispatch(
          "history/getHistoryByShp",
          payload
        );
        this.historyByShp = response?.data;
        this.isLoading = false;
        this.showPanel = !!this.historyByShp;
      } catch (err) {
        console.error(err.message);
        this.showPanel = false;
        this.isLoading = false;
      }
    },
    showHistory(item) {
      this.getHistoryByHaulVersionId(item);
    }
  }
};
</script>

<style scoped lang="scss">
.table-section {
  overflow: auto;
  width: 100%;
  padding: 0.5rem;
}
.viewer-section {
  overflow: auto;
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    &--back-option {
      cursor: pointer;
    }
    &--title {
      width: 70%;
    }
  }
}
::v-deep {
  .b-table {
    border-radius: 6px;
    font-size: 13px;
    text-align: center;
  }
  .p-splitter-gutter {
    background-color: #eceff1;
  }
  .p-splitter-horizontal > .p-splitter-gutter > .p-splitter-gutter-handle {
    background-color: #ced4da;
  }
  .p-splitter-panel-nested {
    width: 30%;
  }
  .jv-container .jv-code.open {
    text-align: left;
  }
}
</style>
