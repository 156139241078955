<template>
  <b-modal
    :id="`create-contact-modal-${stopIndex}`"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    @show="resetModal"
  >
    <template #modal-title>
      {{ editMode ? "Edit Contact" : "Create Contact" }}
    </template>
    <div class="create-contact">
      <!-- First name field -->
      <div class="create-contact__field">
        <span class="create-contact__field--label">First Name</span>
        <b-form-input
          v-model="firstName"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </div>

      <!-- Lastname field -->
      <div class="create-contact__field">
        <span class="create-contact__field--label">Last Name</span>
        <b-form-input
          v-model="lastName"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </div>

      <!-- Phone -->
      <div class="create-contact__field">
        <span class="create-contact__field--label">Phone</span>
        <b-form-input
          v-model="phone"
          size="sm"
          type="text"
          autocomplete="off"
          @keyup="formatPhone"
        ></b-form-input>
      </div>

      <!-- Email -->
      <div class="create-contact__field">
        <span class="create-contact__field--label">Email</span>
        <b-form-input
          v-model="email"
          size="sm"
          autocomplete="off"
        ></b-form-input>
      </div>

      <hr />
      <div class="create-contact__buttons">
        <!-- Cancel button -->
        <b-button
          class="create-contact__buttons--cancel"
          size="sm"
          @click="closeModal"
          ><i class="fas fa-times-circle"></i> Cancel</b-button
        >
        <!-- Save button -->
        <b-button
          class="create-contact__buttons--save"
          size="sm"
          @click="handleSave"
        >
          <div v-if="!isLoading"><i class="fas fa-check-circle"></i></div>
          <div>
            <b-icon
              icon="arrow-clockwise"
              animation="spin"
              v-if="isLoading"
            ></b-icon>
          </div>
          <span class="ml-1">Save Contact</span>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { phoneValidator, emailValidator } from "@/utils/helpers.js";

export default {
  name: "EditAddressModal",
  computed: {
    ...mapGetters("load", ["selectedLoad"]),
    ...mapGetters("stops", ["loadStops", "stopsContacts", "editMode"])
  },
  props: {
    stopIndex: Number
  },
  data() {
    return {
      isLoading: false,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      formattedPhone: ""
    };
  },
  methods: {
    ...mapActions("stops", ["updateStop", "createContact", "updateContact"]),
    async resetModal() {
      try {
        const contact = this.stopsContacts[this.stopIndex];
        if (this.editMode) {
          if (contact === null) throw new Error("No selected contact to edit");
          if (!contact) throw new Error("The account does not have a salesforce " + 
            "contact associated with it. Please, create a new contact for the account.");
          this.firstName = contact.first_name;
          this.lastName = contact.last_name;
          this.phone = contact.phone;
          this.email = contact.email;
        } else {
          this.firstName = "";
          this.lastName = "";
          this.phone = "";
          this.email = "";
        }
      } catch (err) {
        console.error("[CREATE CONTACT (124)]", err.message);
        this.showAlert({ text: err.message }).then(() => {
          this.closeModal();
        });
      }
      this.formatPhone();
    },
    formatPhone() {
      if (this.phone.length > 10) {
        const cleanNumber = this.phone.replace(/\D/g, "");
        let formateddNumber = cleanNumber.startsWith("1")
          ? cleanNumber.slice(1, 11)
          : cleanNumber.slice(0, 10);
        this.formattedPhone = formateddNumber;
        if (formateddNumber.length === 10) {
          formateddNumber = `(${formateddNumber.slice(
            0,
            3
          )}) ${formateddNumber.slice(3, 6)}-${formateddNumber.slice(6)}`;
        } else if (formateddNumber.length === 11) {
          formateddNumber = `(${formateddNumber.slice(
            1,
            4
          )}) ${formateddNumber.slice(4, 7)}-${formateddNumber.slice(7)}`;
        }
        this.phone = formateddNumber;
      }
    },
    handleSave() {
      const emptyField = false;
      const verifyFields = [
        !!this.firstName,
        !!this.lastName,
        !!this.phone,
        !!this.email
      ];
      if (verifyFields.includes(emptyField)) {
        this.showAlert({
          alertType: "warning",
          title: "Please fill all fields"
        });
      } else {
        const phoneValidation = phoneValidator(this.formattedPhone);
        if (!phoneValidation[0]) {
          this.showAlert({ text: phoneValidation[1] });
          throw new Error(phoneValidation[1]);
        }
        if (!emailValidator(this.email)) {
          this.showAlert({ text: "Invalid email" });
          throw new Error("Invalid email");
        } else {
          if (this.editMode) this.editContact();
          else this.saveContact();
        }
      }
    },
    async editContact() {
      try {
        this.isLoading = true;
        const loadId = this.selectedLoad.id;
        const contactId = this.stopsContacts[this.stopIndex].id;
        const contact = {
          first_name: this.firstName,
          last_name: this.lastName,
          phone: this.formattedPhone,
          email: this.email
        };
        await this.updateContact({ loadId, contactId, update: contact });
        await this.$emit("refreshStops");
        this.closeModal();
      } catch (err) {
        console.error("[CREATE CONTACT (149)]", err.message);
        this.showAlert({ text: err.message });
      } finally {
        this.isLoading = false;
      }
    },
    async saveContact() {
      try {
        this.isLoading = true;
        const locationId = this.loadStops[this.stopIndex].location_id;
        if (locationId === null)
          throw new Error("Cannot create contact without location");
        const payload = {
          stopId: this.loadStops[this.stopIndex].id,
          loadId: this.selectedLoad.id,
          info: {
            account_id: locationId,
            first_name: this.firstName,
            last_name: this.lastName,
            phone: this.formattedPhone,
            email: this.email
          }
        };
        await this.createContact(payload);
        await this.$emit("refreshStops");
        this.closeModal();
      } catch (err) {
        console.error("[CREATE CONTACT (176)]", err.message);
        this.showAlert({ text: err.message });
      } finally {
        this.isLoading = false;
      }
    },
    closeModal() {
      this.$bvModal.hide(`create-contact-modal-${this.stopIndex}`);
    }
  },
  created() {
    this.resetModal();
    this.formatPhone();
  }
};
</script>

<style lang="scss" scoped>
.create-contact {
  &__field {
    @include modal-form-field(20% 80%);
    input {
      font-size: 13px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    &--cancel {
      @include secondary-button;
    }
    &--save {
      display: flex;
      @include primary-button;
      margin-left: 10px;
    }
  }
}
</style>
