"use strict";

import Vue from "vue";
import App from "./App.vue";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store";

// axios
import axios from "axios";

// Bootstrap Framework
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";

//fotawesome
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

// Bootstrap Vue Framework
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Element
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";

Vue.use(ElementUI, { locale });

// Primevue
import PrimeVue from "primevue/config";

Vue.use(PrimeVue);

// Vueviewer
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import JsonViewer from "vue-json-viewer";

// Import JsonViewer as a Vue.js plugin
Vue.use(JsonViewer);

Vue.use(VueViewer);
// Sweetalert
import swal from "sweetalert2";

Vue.prototype.swal = swal;

// Fontawesome Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBook,
  faTruckMoving,
  faMobileAlt,
  faShoppingCart,
  faSearch,
  faFileDownload
} from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faBook,
  faTruckMoving,
  faMobileAlt,
  faShoppingCart,
  faSearch,
  faFileDownload,
  faCircleXmark
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// Vue Filters
import "@/filters/filters.js";

// Vue Mixins
import mixin from "@/mixins/mixins.js";
Vue.mixin(mixin);

//jwt decoder
import VueJWT from "vuejs-jwt";
Vue.use(VueJWT);

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
