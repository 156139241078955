import API from "./api/methods.js";

import {
  isEmpty,
  formatPicklist,
  mapPicklist,
  errorsMessages,
  showAlert
} from "@/utils/helpers.js";

export default {
  namespaced: true,
  state: {
    carrierQuotes: [],
    picklists: {},
    rawCarrierServices: [],
    selectedCarrier: {},
    // Complete carrier information V2
    carrierQuoteAccepted: {},
    // General carrier information
    selectedCarrierV2: null,
    // New carrier created in Salesforce
    newCarrierApplied: {},
    carrierQuoteRoute: ""
  },
  getters: {
    selectedCarrier(state) {
      return state.selectedCarrier;
    },
    carrierQuotes(state) {
      return state.carrierQuotes;
    },
    carrierQuotesLength(state) {
      return state.carrierQuotes.length;
    },
    picklists(state) {
      return state.picklists;
    },
    rawCarrierServices(state) {
      return state.rawCarrierServices;
    },
    // Complete carrier information V2
    carrierQuoteAccepted(state) {
      return state.carrierQuoteAccepted;
    },
    // General carrier information
    showselectedCarrierV2(state) {
      return state.selectedCarrierV2;
    },
    //
    showNewCarrierApplied(state) {
      return state.newCarrierApplied;
    }
  },
  actions: {
    generateCarrierQuoteRoute({ state }) {
      const baseDomain = process.env.VUE_APP_BASE_URL;
      if (baseDomain.includes("dev")) {
        return `https://gltlogistics--full.sandbox.lightning.force.com/lightning/r/rtms__CarrierQuote__c/${state.carrierQuoteAccepted.id}/view`;
      }
      return `https://gltlogistics.lightning.force.com/lightning/r/rtms__CarrierQuote__c/${state.carrierQuoteAccepted.id}/view`;
    },
    getCarrierQuotes({ commit, dispatch }, loadId) {
      const resource = `/carrier-quote/load/${loadId}`;
      return API.get(resource, true).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting carriers quotes from server");
        commit("SET_CARRIER_QUOTES", res.data);
        res.data.forEach(async function(carrier) {
          if (carrier.accepted) await dispatch("selectCarrier", carrier);
        });
      });
    },
    createCarrierQuote({ dispatch, state }, carrierQuote) {
      const resource = "/carrier-quote";
      return API.post(resource, carrierQuote, true).then(async function(res) {
        await dispatch("getCarrierQuotes", carrierQuote.load_id);
        return state.carrierQuotes.find(function(carrier) {
          return carrier.id === res.data;
        });
      });
    },
    getCarriersPicklists({ commit }, value) {
      const resource = "/carrier-service";
      const params = {
        name: value,
        limit: 30,
        skip: 0
      };
      return API.get(resource, true, params).then(function(res) {
        if (typeof res === "undefined")
          throw new Error("Error getting carrier picklists data from server");
        commit("SET_RAW_CARRIER_SERVICES", res.data);
        const carriers = formatPicklist(res.data);
        const mappedPicklists = mapPicklist(carriers);
        commit("SET_PICKLISTS", mappedPicklists);
      });
    },
    selectCarrier({ commit, dispatch, state }, carrier) {
      commit("SELECT_CARRIER", carrier);
      // commit("SET_CARRIER_QUOTE_ACCEPTED", carrier);
      if (!isEmpty(state.selectedCarrier)) {
        return dispatch(
          "getCarriersPicklists",
          state.selectedCarrier.carrier_service.name
        );
      }
    },
    // accept carrier old version
    async acceptCarrier(
      { commit, state, dispatch },
      { loadId, selectedCarrier }
    ) {
      let promises = [];
      state.carrierQuotes.forEach(async function(carrier) {
        if (carrier.id === selectedCarrier.id) {
          commit("SET_ACCEPTED_STATUS", { carrier, status: true });
          const promise = dispatch("updateCarrier", {
            id: carrier.id,
            accepted: true
          });
          promises.push(promise);
        } else if (carrier.accepted) {
          commit("SET_ACCEPTED_STATUS", { carrier, status: false });
          const promise = dispatch("updateCarrier", {
            id: carrier.id,
            accepted: false
          });
          promises.push(promise);
        }
      });
      // Waiting the accept process
      await Promise.all(promises);
      const load = dispatch("load/getLoad", loadId, { root: true });
      const customers = dispatch("customerQuotes/getCustomerQuotes", loadId, {
        root: true
      });
      const carriers = dispatch("carrierQuotes/getCarrierQuotes", loadId, {
        root: true
      });
      const stops = await dispatch("stops/getLoadStops", loadId, {
        root: true
      });
      return Promise.all([load, customers, carriers, stops]);
    },
    setAcceptedStatus({ commit }, { carrier, status }) {
      commit("SET_ACCEPTED_STATUS", { carrier, status });
    },
    updateCarrier(ctx, payload) {
      const resource = `/carrier-quote/${payload.id}`;
      delete payload.id;
      return API.patch(resource, payload, true);
    },
    tenderCarrier({ dispatch, rootGetters }, { tender, customerQuote }) {
      const loadId = tender.load_id;
      return API.post("/load/tender", tender, true).then(async function(res) {
        if (typeof res === "undefined")
          throw new Error("Error tendering carrier from server");
        const errorMessage = res.data.error_message;
        if (errorMessage !== null) throw { message: errorMessage };
        const load = dispatch("load/getLoad", loadId, { root: true });
        const customers = dispatch("customerQuotes/getCustomerQuotes", loadId, {
          root: true
        });
        const carriers = dispatch("carrierQuotes/getCarrierQuotes", loadId, {
          root: true
        });
        const stops = dispatch("stops/getLoadStops", loadId, {
          root: true
        });
        await Promise.all([load, customers, carriers, stops]);
        customerQuote.id = rootGetters["customerQuotes/customerQuote"].id;
        await dispatch(
          "customerQuotes/updateCustomerQuote",
          { loadId, customerQuote },
          {
            root: true
          }
        );
        return res;
      });
    },
    refreshCarriers(ctx, loadId) {
      const resource = `/carrier-quote/refresh/${loadId}`;
      return API.get(resource, true);
    },
    // Get Carriers list by Load V2
    async getCarriers({ commit, state, dispatch }, { loadId }) {
      const resource = `/v2/carriers/load/${loadId}`;
      try {
        const response = await API.get(resource, true);
        commit("SET_CARRIER_QUOTES", response?.data?.data);
        state.selectedCarrierV2 = null;
        // General carrier information
        [state.selectedCarrierV2] = response?.data?.data.filter(
          carrier => carrier?.accepted
        );
        if (state.selectedCarrierV2) {
          // Get complete carrier information
          await dispatch("getCarrierQuoteAccepted", loadId);
        }
        return response;
      } catch (error) {
        if (error.response && error.response.status == 403) {
          await dispatch("login/refreshToken");
          location.reload();
        } else if (error.response?.data?.errors.length > 0) {
          let msg = errorsMessages(error.response?.data?.errors);
          showAlert({ html: msg });
          throw new Error(error.message);
        } else {
          throw error;
        }
        return error;
      }
    },
    // accept carrier V2
    async acceptCarrierQuote({ state, dispatch }, params) {
      const resource = `/v2/carrier-quote?carrier_id=${params.carrier_id}&load_id=${params.load_id}`;
      try {
        const response = await API.post(resource, null, true);
        state.newCarrierApplied = {};
        return response;
      } catch (error) {
        if (error.response && error.response.status == 403) {
          await dispatch("login/refreshToken");
          location.reload();
        } else if (error.response?.data?.errors.length > 0) {
          let msg = errorsMessages(error.response?.data?.errors);
          showAlert({ html: msg });
          throw new Error(error.message);
        } else {
          throw error;
        }
        return error;
      }
    },
    async createCarrierQuoteSalesforce({ dispatch, commit }, payload) {
      const resource = `/v2/carrier-quote/salesforce`;
      try {
        const response = await API.post(resource, payload, true);
        commit("SET_CARRIER_CREATED_IN_SALESFORCE", response?.data?.data[0]);
        commit("SET_CARRIER_QUOTE_ACCEPTED", response?.data?.data[0]);
        return response;
      } catch (error) {
        if (error.response && error.response.status == 403) {
          await dispatch("login/refreshToken");
          location.reload();
        } else if (error.response?.data?.errors.length > 0) {
          let msg = errorsMessages(error.response?.data?.errors);
          showAlert({ html: msg });
          throw new Error(error.message);
        } else {
          throw error;
        }
        return error;
      }
    },
    // Get complete carrier information
    async getCarrierQuoteAccepted({ state, commit, dispatch }, loadId) {
      const resource = `/carrier-quote/load/${loadId}/accepted`;
      try {
        const response = await API.get(resource, true);
        const ifNewCarrierExists = state.newCarrierApplied?.id
          ? state.newCarrierApplied
          : response?.data[0];
        commit("SET_CARRIER_QUOTE_ACCEPTED", ifNewCarrierExists);
        return response;
      } catch (error) {
        if (error.response && error.response.status == 403) {
          await dispatch("login/refreshToken");
          location.reload();
        } else if (error.response?.data?.errors.length > 0) {
          let msg = errorsMessages(error.response?.data?.errors);
          showAlert({ html: msg });
          throw new Error(error.message);
        } else {
          throw error;
        }
        return error;
      }
    },
    async requoteByLoad(
      { commit, dispatch },
      { loadId, customerId, nmfcClass }
    ) {
      const resource = nmfcClass
        ? `/v2/rates/requote/${loadId}?customer_id=${customerId}&nmfc_class=${nmfcClass}`
        : `/v2/rates/requote/${loadId}?customer_id=${customerId}`;
      try {
        const response = await API.get(resource, true);
        commit("SET_CARRIER_QUOTES", response?.data?.data);
        return response;
      } catch (error) {
        if (error.response && error.response.status == 403) {
          await dispatch("login/refreshToken");
          location.reload();
        } else if (error.response?.data?.errors.length > 0) {
          let msg = errorsMessages(error.response?.data?.errors);
          showAlert({ html: msg });
          throw new Error(error.message);
        } else {
          throw error;
        }
        return error;
      }
    }
  },
  mutations: {
    SET_CARRIER_QUOTES(state, carrierQuotes) {
      state.carrierQuotes = carrierQuotes;
    },
    SELECT_CARRIER(state, carrier) {
      state.selectedCarrier = carrier;
    },
    SET_ACCEPTED_STATUS(state, { carrier, status }) {
      const index = state.carrierQuotes.findIndex(
        quote => quote.id === carrier.id
      );
      const carrierFound = state.carrierQuotes[index];
      carrierFound.accepted = status;
      state.carrierQuotes.splice(index, 1, carrierFound);
    },
    SET_PICKLISTS(state, picklists) {
      state.picklists = picklists;
    },
    SET_RAW_CARRIER_SERVICES(state, raw) {
      state.rawCarrierServices = raw;
    },
    // Complete carrier information V2
    SET_CARRIER_QUOTE_ACCEPTED(state, carrierAccepted) {
      state.carrierQuoteAccepted = carrierAccepted;
    },
    // New carrier created in Salesforce is saved for display in table
    SET_CARRIER_CREATED_IN_SALESFORCE(state, carrierApplied) {
      state.newCarrierApplied = carrierApplied;
    }
  }
};
