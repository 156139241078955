<template>
  <div class="carrier-quote card p-3">
    <b-overlay :show="isLoading" opacity="0.7" class="h-100">
      <div
        class="carrier-quote-header d-flex flex-wrap justify-content-between"
      >
        <div class="col-12 col-lg-3 col-xl-4 px-0 px-xl-2">
          <a
            v-if="validate(carrierQuoteAccepted, 'name')"
            target="_blank"
            @click="getCarrierQuoteRoute"
            :href="carrierQuoteRoute"
            ><strong>{{ validate(carrierQuoteAccepted, "name") }}</strong></a
          >
          <strong v-else>"No Carrier Quote"</strong>
          <br />
          <span :class="{ hidden: !savingQuote }">Saving Quote...</span>
        </div>
        <div
          class="col-12 col-lg-9 col-xl-8 px-0 px-xl-2 mt-4 mt-lg-0 d-flex justify-content-end align-items-center"
        >
          <button class="btn-sm new-button mr-2" @click="newCarrierQuote">
            New Quote
          </button>
          <button
            class="btn-sm save-button mr-2"
            @click="saveCarrierQuote"
            :disabled="!newCarrierActivated"
          >
            Save Quote
            <div>
              <b-icon
                icon="arrow-clockwise"
                animation="spin"
                v-if="savingQuote"
              ></b-icon>
            </div>
          </button>
          <button
            class="btn-sm tender-button mr-2"
            @click="tenderLoadV2"
            :disabled="isLoading"
          >
            Tender
          </button>
          <button
            class="btn-sm assign-button"
            @click="assign(carrierQuoteAccepted)"
          >
            Assign
          </button>
        </div>
      </div>
      <span
        class="message tender-message mt-2"
        :class="{ hidden: !tenderMessage }"
      ></span>
      <span
        class="message assign-message mt-2"
        :class="{ hidden: !assignMessage }"
      ></span>
      <div class="form-group row mt-4">
        <div class="col-sm-6">
          <label class="control-label" for="carrier-service-input"
            >Carrier Service</label
          >
          <div class="form-group d-flex mb-0">
            <div class="col-12 px-0">
              <input
                list="carriers"
                type="text"
                class="hover-input form-control
                  form-control-sm"
                id="carrier-service-input"
                placeholder="Carrier Service"
                v-model="carrierQuote.carrierService"
                @keyup.enter="changeCarriersPicklist"
                @change="changeCarrierService($event.target.value)"
                :disabled="!isEmpty(carrierQuoteAccepted)"
              />
              <datalist id="carriers">
                <template v-for="(carrier, index) in carriersPicklists.labels">
                  <option :key="index" :value="carrier" />
                </template>
              </datalist>
            </div>
          </div>
          <div class="text-danger mt-2 " :class="{ hidden: !searchingMessage }">
            Searching contacts...
          </div>
          <div class="text-danger mt-2" :class="{ hidden: !errorMessage }">
            {{ errorMessage }}
          </div>
        </div>
        <div class="col-sm-6">
          <label for="carrier-quote-contract-id">Quote/Contract Id</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="carrier-quote-contract-id"
            placeholder="Quote/Contract Id"
            v-model="carrierQuote.contractId"
            :disabled="!isEmpty(carrierQuoteAccepted)"
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-6">
          <label for="carrier-quote-effective-date">Effective Date</label>
          <input
            type="date"
            class="form-control form-control-sm"
            id="carrier-quote-effective-date"
            placeholder="Effective Date"
            v-model="carrierQuote.effectiveDate"
            :disabled="!isEmpty(carrierQuoteAccepted)"
          />
        </div>
        <div class="col-sm-6">
          <label for="carrier-quote-service-class-input">Service Class</label>
          <input
            type="text"
            class="hover-input form-control form-control-sm"
            id="carrier-quote-service-class-input"
            placeholder="Service Class"
            v-model="carrierQuote.serviceClass"
            :disabled="!isEmpty(carrierQuoteAccepted)"
          />
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-6">
          <label for="carrier-quote-currency-select">Currency</label>
          <b-form-select
            size="sm"
            class="hover-input"
            id="carrier-quote-currency-select"
            :options="picklists.currency_iso_code.labels"
            :disabled="!isEmpty(carrierQuoteAccepted)"
            v-model="carrierQuote.currency"
          ></b-form-select>
          <div class="col-sm-12 mt-2">
            <b-form-checkbox
              :checked="validate(carrierQuoteAccepted, 'accepted', 'b')"
              id="carrier-quote-accepted"
              name="carrier-quote-accepted-check"
              switch
              v-model="carrierQuote.accepted"
              :disabled="!isEmpty(carrierQuoteAccepted)"
            >
              Accepted
            </b-form-checkbox>
          </div>
        </div>
        <div class="col-sm-6">
          <label for="carrier-quote-comments-input">Carrier Comments</label>
          <textarea
            type="text"
            class="form-control form-control-sm"
            id="carrier-quote-comments-input"
            placeholder="Comments"
            v-model="carrierQuote.comments"
            :disabled="!isEmpty(carrierQuoteAccepted)"
          />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CarrierQuote",
  computed: {
    ...mapGetters("login", ["showUserLoggedIn"]),
    ...mapGetters("load", ["selectedLoad", "picklists", "assignedMode"]),
    ...mapGetters("customerQuotes", ["picklists", "customerQuote", "contacts"]),
    ...mapGetters("carrierQuotes", [
      "rawCarrierServices",
      "carrierQuoteAccepted",
      // General carrier information
      "showselectedCarrierV2",
      "showNewCarrierApplied"
    ]),
    ...mapGetters("carrierQuotes", { carriersPicklists: "picklists" })
  },
  data() {
    return {
      savingQuote: false,
      assignMessage: false,
      tenderMessage: false,
      searchingMessage: false,
      isLoading: false,
      errorMessage: null,
      carrierService: null,
      carrierQuote: {},
      carrierQuoteRoute: "",
      newCarrierActivated: false
    };
  },
  watch: {
    carrierQuoteAccepted: {
      deep: true,
      handler(newValue) {
        if (!this.isEmpty(newValue)) this.setCarrierFields();
        else this.resetCarrierFields();
      }
    }
  },
  methods: {
    ...mapActions("load", ["updateLoad", "tender"]),
    ...mapActions("customerQuotes", [
      "createCustomerQuote",
      "getCustomerQuotes",
      "updateCustomerQuote"
    ]),
    ...mapActions("carrierQuotes", [
      "acceptCarrierQuote",
      "acceptCarrier",
      "updateCarrier",
      "tenderCarrier",
      "getCarriersPicklists",
      "generateCarrierQuoteRoute"
    ]),
    ...mapActions("stops", ["getLoadStops"]),
    async selectChange(key, value, picklist) {
      if (value !== "") {
        const newValue = this.getPicklistValue(value, picklist);
        await this.inputChange(key, newValue);
      }
    },
    async getCarrierQuoteRoute() {
      this.carrierQuoteRoute = await this.generateCarrierQuoteRoute();
    },
    async inputChange(key, value) {
      try {
        this.errorMessage = null;
        const payload = { id: this.carrierQuoteAccepted.id, [key]: value };
        await this.updateCarrier(payload);
      } catch (err) {
        console.error("[CARRIER QUOTE (213)]", err.message);
        this.errorMessage = err.message;
      }
    },
    async changeCarriersPicklist() {
      try {
        const searchbar = document.querySelector("#carrier-service-input");
        const carrier = searchbar.value;
        this.searchingMessage = true;
        this.errorMessage = null;
        await this.getCarriersPicklists(carrier);
        this.searchingMessage = false;
        this.newCarrierActivated = true;
      } catch (err) {
        console.error("[CARRIER QUOTE (226)]", err.message);
        this.searchingMessage = false;
        this.errorMessage = err.message;
      }
    },
    async changeCarrierService(value) {
      this.carrierService = value;
      this.newCarrierActivated = true;
    },
    requiredFieldsOk(required) {
      let valid = true;
      required.forEach(function(field) {
        if (field === "") {
          valid = false;
        }
      });
      return valid;
    },
    getCustomerQuoteFields() {
      const loadId = this.selectedLoad.id;
      const contact = document.querySelector("#customer-quote-contact-input")
        .value;
      const status = document.querySelector("#customer-quote-status-select")
        .value;
      const customerTotal = document.querySelector(
        "#customer-pricing-quote-total"
      ).value;
      const currency = document.querySelector("#customer-quote-currency-select")
        .value;
      const contactId = this.findIdByNameFromArray(this.contacts, contact);
      const pricing = document.querySelector("#customer-quote-pricing-select")
        .value;
      const accCharges = document.querySelector(
        "#customer-pricing-accessorial-charges"
      ).value;
      const comments = document.querySelector("#customer-quote-comments-input")
        .value;
      const netFreightCharges = document.querySelector(
        "#customer-pricing-net-freight-charges"
      ).value;
      const fuelSurcharge = document.querySelector(
        "#customer-pricing-fuel-surcharge"
      ).value;
      const date = document.querySelector("#customer-quote-date").value;

      const requiredFields = [loadId, status, customerTotal, currency];
      const validRequiredFields = this.requiredFieldsOk(requiredFields);

      const customerQuote = {
        load_id: loadId,
        status,
        customer_quote_total: Number.parseFloat(customerTotal),
        current_iso_code: this.getPicklistValue(
          currency,
          this.picklists.currency_iso_code
        ),
        customer_contact: contactId,
        pricing: this.getPicklistValue(pricing, this.picklists.pricing),
        accessorial_charges: Number.parseFloat(accCharges),
        comments,
        net_line_haul: Number.parseFloat(netFreightCharges),
        fuel_surcharge: Number.parseFloat(fuelSurcharge),
        quote_date: date
      };

      return [customerQuote, validRequiredFields];
    },
    async changeSalesStatus() {
      const loadId = this.selectedLoad.id;
      const salesStatus = this.selectedLoad.load_details.sales_status;
      if (salesStatus === "Pending")
        await this.updateLoad({ loadId, update: { sales_status: "Won" } });
    },
    async assign(carrier) {
      let accessorialsValidationStatus = this.$store.getters[
        "load/accessorialsValidationStatus"
      ];
      if (accessorialsValidationStatus) {
        try {
          const creditHold = document.querySelector(
            "#customer-quote-credit-hold-input"
          ).checked;
          if (creditHold) {
            const customerName = this.validate(
              this.selectedLoad.load_details,
              "customer",
              "o"
            ).name;
            const adRep = this.validate(
              this.selectedLoad.load_details,
              "customer",
              "o"
            ).ar_rep;
            throw new Error(
              `${customerName} credit limit exceeded, please contact ${adRep}`
            );
          }
          if (this.assignedMode === "OTR") {
            const coveredBy = document.querySelector("#covered-by-input").value;
            const dispatchedVia = document.querySelector(
              "#dispatched-via-input"
            ).value;
            const equipmentType = document.querySelector(
              "#equipment-type-input"
            ).value;
            if (
              coveredBy === "" ||
              dispatchedVia === "" ||
              equipmentType === ""
            )
              throw new Error(
                "In order to proceed, you must have covered by, dispatched via and equipment type selected."
              );
          }
          this.assignMessage = true;
          this.resetMessage(".assign-message");
          this.showMessage(".assign-message", "Assigning...", "info");
          // Check carrier quote exists
          if (this.isEmpty(carrier))
            throw { message: "No Carrier Quote to Assign" };
          // Check customer status is accepted
          const customerStatus = document.querySelector(
            "#customer-quote-status-select"
          ).value;
          if (customerStatus !== "Accepted")
            throw { message: "Customer Status Must Be Accepted" };
          // Change load sales status
          this.changeSalesStatus();
          // Creates customer quote if it doesn't exist
          if (typeof this.customerQuote === "undefined") {
            const [customerQuote, valid] = this.getCustomerQuoteFields();
            if (!valid)
              throw { message: "Required Customer Quote Fields Missing" };
            else await this.createCustomerQuote(customerQuote);
          }
          // Updates customer quote total
          const customer_quote_total = carrier.carrier_total + carrier.markup;
          const customerQuoteUpdate = {
            id: this.customerQuote.id,
            customer_quote_total
          };
          await this.updateCustomerQuote({
            loadId: this.selectedLoad.id,
            customerQuote: customerQuoteUpdate
          });
          // Change carrier status to accepted V2
          if (this.showNewCarrierApplied?.portal_carrier_id) {
            const payloadToCarrierAccepted = {};
            payloadToCarrierAccepted.carrier_id = this.showNewCarrierApplied?.portal_carrier_id;
            payloadToCarrierAccepted.load_id = this.selectedLoad?.id;
            const response = await this.$store.dispatch(
              "carrierQuotes/acceptCarrierQuote",
              payloadToCarrierAccepted
            );
            //  After the new carrier is accepted, get carriers info
            if (response?.data) {
              await this.$store.dispatch("carrierQuotes/getCarriers", {
                loadId: this.selectedLoad?.id
              });
            }
          }
          // Updates load carrier and load total customer quote
          const loadUpdate = {
            carrier_id: this.carrierQuoteAccepted?.vendor.id,
            customer_quote_total,
            carrier_service: this.carrierQuoteAccepted?.carrier_service.id,
            vendor: this.carrierQuoteAccepted?.vendor.id
          };
          const firstUpdate = this.updateLoad({
            loadId: this.selectedLoad.id,
            update: loadUpdate
          });
          // Change load status to assigned
          const dateBefore = this.selectedLoad.load_details
            .tender_accepted_date;
          const update = this.updateLoad({
            loadId: this.selectedLoad.id,
            update: { load_status: "Assigned" }
          });
          const dateAfter = this.selectedLoad.load_details.tender_accepted_date;
          if (dateBefore === dateAfter) {
            // Update dispatcher
            const user = this.showUserLoggedIn;
            const dispatcherId = user.id_salesforce;
            const update = {
              dispatcher_internal_id: dispatcherId
            };
            await this.updateLoad({ loadId: this.selectedLoad.id, update });
          }
          await Promise.all([firstUpdate, update]);
          this.showMessage(
            ".assign-message",
            "Carrier Successfully Assigned",
            "success"
          );
        } catch (err) {
          console.error("[CARRIER QUOTE (360)]", err.message);
          this.showMessage(
            ".assign-message",
            `Assign: ${err.message}`,
            "error"
          );
        }
      } else {
        this.showAlert({
          title: "Please remember!",
          alertType: "warning",
          text: "Refresh quotes to continue."
        });
      }
    },
    resetMessage(className) {
      const element = this.$el.querySelector(className);
      element.classList.remove("message-error");
      element.classList.remove("message-success");
    },
    showMessage(className, message, type) {
      const element = this.$el.querySelector(className);
      element.innerHTML = message;
      if (type === "error") element.classList.add("message-error");
      else if (type === "success") element.classList.add("message-success");
      element.classList.remove("hidden");
    },
    // eslint-disable-next-line no-unused-vars
    // async tender(carrier) {
    // this.tenderMessage = true;
    // try {
    //   this.resetMessage(".tender-message");
    //   this.showMessage(".tender-message", "Tendering...", "info");
    //   const [customerQuote, valid] = this.getCustomerQuoteFields();
    //   const dispatcher = document.querySelector(
    //     "#customer-quote-dispatcher-input > .input"
    //   ).value;
    //   // Validations
    //   if (!valid) throw { message: "Required Customer Quote Fields Missing" };
    //   if (dispatcher === "") throw { message: "Dispatcher Required" };
    //   if (this.isEmpty(carrier)) throw { message: "No Carrier to Tender" };
    //   // Tender carrier
    //   const tender = {
    //     load_id: this.selectedLoad.id,
    //     quote_id: carrier.id
    //   };
    //   await this.tenderCarrier({ tender, customerQuote });
    //   // No customer quote error
    //   if (typeof this.customerQuote === "undefined")
    //     throw { message: "No Customer Quote Found" };
    //   const customerQuoteTotalValue = document.querySelector(
    //     "#customer-pricing-quote-total"
    //   ).value;
    //   const customerQuoteTotal = Number.parseFloat(customerQuoteTotalValue);
    //   const fuelSurcharge =
    //     Number.parseFloat(customerQuote.fuelSurcharge) || 0;
    //   const accCharges = Number.parseFloat(customerQuote.accCharges) || 0;
    //   const netFreightCharges =
    //     customerQuoteTotal - accCharges - fuelSurcharge;
    //   // updates customer quote
    //   const customerQuoteUpdate = {
    //     id: this.customerQuote.id,
    //     net_line_haul: netFreightCharges
    //   };
    //   await this.updateCustomerQuote({
    //     loadId: this.selectedLoad.id,
    //     customerQuote: customerQuoteUpdate
    //   });
    //   // updates load carrier and load total customer quote
    //   const loadUpdate = {
    //     carrier_id: carrier.vendor.id,
    //     carrier_service: carrier.carrier_service.id,
    //     customer_quote_total: this.selectedLoad.load_details.customer_quote_total
    //   };
    //   await this.updateLoad({
    //     loadId: this.selectedLoad.id,
    //     update: loadUpdate
    //   });
    //   this.showMessage(".tender-message", "Tender Successful", "success");
    // } catch (err) {
    //   console.error(err.message);
    //   this.showMessage(".tender-message", `Tender: ${err.message}`, "error");
    // }
    // },
    // Tender old version delete.
    async tenderLoad() {
      try {
        this.isLoading = true;
        const payload = {
          load_id: this.selectedLoad.id,
          user_id: this.showUserLoggedIn.id_salesforce
        };
        const { data: tenderResponse } = await this.tender(payload);
        if (tenderResponse.errors) {
          const errors = tenderResponse.errors
            .map(error => `${error.message}: ${error.diagnostic}`)
            .join("<br/>");
          throw new Error(`<strong>${errors}</strong>`);
        }
        await this.getLoadStops(this.selectedLoad.id);
        this.showAlert({
          title: "Great!",
          alertType: "success",
          text: "Tender Successful. Please check the references"
        });
      } catch (err) {
        if (err.response && err.response.status === 400) {
          const errorMessage = err.response.data.detail[0];
          const message = errorMessage
            .split("\n")
            .map(error => `• ${error}`)
            .join("<br />");
          this.showAlert({
            html: message
          });
        } else {
          console.error(err.message);
          this.showAlert({
            html: err.message
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    // Version 2 to Process Tender
    async tenderLoadV2() {
      let accessorialsValidationStatus = this.$store.getters[
        "load/accessorialsValidationStatus"
      ];
      if (accessorialsValidationStatus) {
        if (this.tenderValidation()) {
          const body = {};
          body.source = this.showselectedCarrierV2?.source;
          body.carrier_id = this.showselectedCarrierV2?.carrier_id;
          body.load_id = this.selectedLoad?.id;
          body.user_id = this.showUserLoggedIn?.id_salesforce;
          body.carrier_total_price = this.carrierQuoteAccepted?.customer_quote_total;
          try {
            this.isLoading = true;
            await this.$store.dispatch("load/tenderProcessForCarrier", body);
          } catch (err) {
            console.error(err.message);
          } finally {
            this.isLoading = false;
          }
        }
      } else {
        this.showAlert({
          title: "Please remember!",
          alertType: "warning",
          text: "Refresh quotes to continue."
        });
      }
    },
    tenderValidation() {
      if (
        this.showselectedCarrierV2?.carrier_id &&
        this.carrierQuoteAccepted?.customer_quote_total
      ) {
        return true;
      }
      this.showAlert({
        title: "Please validate",
        alertType: "warning",
        text: "Please validate that you have accepted a carrier."
      });
      return false;
    },
    getCarrierPricingFields() {
      const netFreightCharges = document.querySelector(
        "#carrier-pricing-net-freight-charges"
      );
      const fuelSurcharge = document.querySelector(
        "#carrier-pricing-fuel-surcharge"
      );
      const accessorialCharges = document.querySelector(
        "#carrier-pricing-accessorial-charges"
      );
      const quoteTotal = document.querySelector("#carrier-pricing-quote-total");

      return {
        net_line_haul: Number.parseFloat(netFreightCharges.value) || 0,
        fuel_surcharge: Number.parseFloat(fuelSurcharge.value) || 0,
        accessorial_charges: Number.parseFloat(accessorialCharges.value) || 0,
        carrier_total: Number.parseFloat(quoteTotal.value) || 0
      };
    },
    async saveCarrierQuote() {
      try {
        this.savingQuote = true;
        if (this.carrierQuote.carrierService === "")
          throw new Error("Carrier service cannot be empty");
        const carrierService = this.rawCarrierServices.find(
          carrier => carrier.name === this.carrierService
        );
        const {
          accepted,
          serviceClass,
          currency,
          effectiveDate,
          comments,
          contractId
        } = this.carrierQuote;
        const carrierPricing = this.getCarrierPricingFields();
        const carrierQuote = {
          load_id: this.selectedLoad.id,
          carrier_service: carrierService.id,
          accepted,
          service_class: serviceClass,
          currency: this.getPicklistValue(
            currency,
            this.picklists.currency_iso_code
          ),
          quote_contract_id: contractId,
          carrier_comments: comments,
          vendor_id: carrierService.carrier,
          ...carrierPricing,
          effective_date: effectiveDate,
          shp: this.selectedLoad?.name
        };
        // Create Carrier Quote in salesforce v2
        const carrier = await this.$store.dispatch(
          "carrierQuotes/createCarrierQuoteSalesforce",
          carrierQuote
        );
        if (carrier?.status === 201) {
          await this.$store.dispatch("carrierQuotes/getCarriers", {
            loadId: this.selectedLoad?.id
          });
          this.newCarrierActivated = false;
        }
        this.showAlert({
          alertType: "success",
          title: "Great!",
          text: "Carrier quote created successfully"
        });
      } catch (err) {
        console.error("[CARRIER QUOTE (504)]", err.message);
        this.showAlert({ text: err.message });
      } finally {
        this.savingQuote = false;
      }
    },
    newCarrierQuote() {
      try {
        this.newCarrierActivated = true;
        this.resetCarrierFields();
        this.$store.commit("carrierQuotes/SET_CARRIER_QUOTE_ACCEPTED", {});
      } catch (err) {
        console.error("[CARRIER QUOTE (564)]", err.message);
      }
    },
    resetCarrierFields() {
      this.carrierQuote = {
        carrierService: "",
        contractId: "",
        effectiveDate: new Date().toISOString().substring(0, 10),
        serviceClass: "",
        currency: this.getPicklistLabel(
          "USD",
          this.picklists.currency_iso_code
        ),
        accepted: false,
        comments: ""
      };
    },
    setCarrierFields() {
      const {
        carrier_service,
        quote_contract_id,
        effective_date,
        service_class,
        currency,
        currency_code,
        accepted,
        carrier_comments
      } = this.carrierQuoteAccepted;
      this.carrierQuote = {
        carrierService: carrier_service.name,
        contractId: quote_contract_id,
        effectiveDate: effective_date,
        serviceClass: service_class,
        currency: this.getPicklistLabel(
          currency || currency_code,
          this.picklists.currency_iso_code
        ),
        accepted,
        comments: carrier_comments
      };
    }
  },
  created() {
    if (!this.isEmpty(this.carrierQuoteAccepted)) {
      this.setCarrierFields();
    } else this.resetCarrierFields();
    this.carrierService =
      this.validate(this.carrierQuoteAccepted, "carrier_service", "o").name ||
      null;
    this.getCarrierQuoteRoute();
  },
  destroyed() {
    this.$store.commit("carrierQuotes/SET_CARRIER_QUOTE_ACCEPTED", {});
  }
};
</script>

<style lang="scss" scoped>
.new-button,
.tender-button,
.save-button,
.send-quote-button,
.search-contact-button {
  @include primary-button;
}

.assign-button {
  @include secondary-button;
}

.message {
  font-weight: bold;
}

.message-error {
  color: $color-user-busy;
}

.message-success {
  color: $color-user-active;
}
</style>
