<template>
  <div class="financial-summary-table">
    <b-table
      class="items-table shadowed"
      stacked="lg"
      hover
      show-empty
      head-variant="light"
      empty-text="There are no items to show"
      sort-icon-left
      :items="items"
      :fields="fields"
      :sort-by="sortBy"
      :perPage="rowsPerPage"
      :currentPage="currentPage"
    >
      <template #cell(step)="data">
        <strong>{{ data.item.step | capitalize }}</strong>
      </template>

      <template #cell(margin)="data">
        {{ data.item.margin }} /
        {{ (data.item.margin_percent || 0).toFixed(2) + "%" }}
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "FinancialSummaryTable",
  props: {
    fields: Array,
    items: Array,
    sortBy: String,
    tableSize: Number,
    rowsPerPage: Number,
    currentPage: Number,
    search: Function
  }
};
</script>

<style lang="scss" scoped>
.b-table {
  border-radius: 6px;
  font-size: 13px;
}
</style>
