<template>
  <div class="collapsing-content card mx-auto">
    <div class="card-header">
      <button
        class="title-button"
        data-toggle="collapse"
        :data-target="`#${content}-card-collapse`"
        aria-expanded="false"
        :aria-controls="`card-collapse`"
      >
        <div class="title-icon-content" :style="backgroundColor">
          <font-awesome-icon :icon="icon" class="title-icon mr-2" />
        </div>
        {{ title }}
      </button>
    </div>
    <div class="collapse" :id="`${content}-card-collapse`">
      <div class="card-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CollapsingContent",
  props: {
    title: String,
    content: String,
    icon: String,
    backgroundColor: String
  }
};
</script>

<style lang="scss" scoped>
.collapsing-content {
  .title-button {
    color: inherit;
    border: none;
    outline: none;
    width: 100%;
    text-align: left;
    background-color: transparent;
    @include flex-wrap(flex-start);
  }

  .card-header {
    &:hover {
      background-color: get-opacity($color-gray-shadow, 0.3);
    }
  }

  .title-icon-content {
    width: 40px;
    @include icon-title($color-secondary-button);
  }

  .title-icon {
    min-width: 20px;
  }
}
</style>
