<template>
  <div class="documents">
    <div class="row">
      <div class="documents-list text-left col-sm-6 col-md-3">
        <h6 class="documents--title">
          <strong>Select the documents to generate:</strong>
        </h6>
        <b-form-group>
          <b-form-checkbox
            v-for="(checkBox, index) in options"
            :key="index"
            v-model="selected"
            :value="checkBox.value"
            :disabled="
              pendingDocuments.length > 0 &&
              checkBox.text === 'POD' &&
              !pendingDocuments.includes('DELIVERY_RECEIPT')
                ? true
                : pendingDocuments.includes('DELIVERY_RECEIPT') &&
                  checkBox.text != 'POD'
            "
            stacked
            @change="onCheckboxChange"
            >{{ checkBox.text }}</b-form-checkbox
          >
        </b-form-group>
        <b-overlay
          class="overlay-button"
          :show="generatingOverlay"
          rounded
          opacity="0.6"
          spinner-small
        >
          <b-button
            class="documents--button mt-1"
            size="sm"
            @click="generate"
            :disabled="pendingDocuments.length === 0 || generatingDocuments"
            ><i class="fas fa-check-circle"></i> Generate</b-button
          >
        </b-overlay>
        <div class="documents__upload">
          <el-upload
            :key="uploadRender"
            class="upload-demo"
            action="/upload"
            :on-change="uploadFiles"
            :on-remove="removeFile"
            :file-list="fileList"
          >
            <b-overlay
              class="overlay-button"
              :show="showOverlay"
              rounded
              opacity="0.6"
              spinner-small
            >
              <el-button size="small" type="primary">Upload POD</el-button>
            </b-overlay>
            <div slot="tip" class="el-upload__tip">
              pdf/jpg/png/tiff files with a size less than 500kb
            </div>
          </el-upload>
        </div>
        <br /><span
          class="generate-message d-inline-block mt-3 failure"
          :class="{ hidden: errorMessage }"
        ></span>
      </div>
      <div class="col-sm-6 col-md-3 text-left">
        <h6 class="generated-documents-title">
          <strong>Current Documents Status</strong>
        </h6>
        <span :class="{ hidden: !generatingDocuments }">
          <strong>Loading Tasks...</strong>
        </span>
        <span :class="{ hidden: generatingDocuments }">
          <strong>No Tasks in Queue</strong>
        </span>
        <ul class="generated-documents-list mt-3">
          <template v-for="(document, index) in generatedDocuments">
            <li
              :key="validate(document, 'name') + index"
              class="generated-document"
            >
              {{ validate(document, "name") }}:
              <span
                :class="{ success: document.status, failure: !document.status }"
              >
                {{ validate(document, "status") ? "succeed" : "failed" }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <DocumentsTable
        class="col-md-6 mt-4"
        :fileNameSelected="fileNameSelected"
        :busy="documentsLoading"
      />
    </div>
    <PdfViewer />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";
import DocumentsTable from "@/components/Documents/DocumentsTable.vue";
import PdfViewer from "@/components/Documents/PdfViewer.vue";

export default {
  name: "Documents",
  components: {
    DocumentsTable,
    PdfViewer
  },
  computed: {
    documentsLoading: {
      get() {
        return this.loading;
      },
      set(newValue) {
        this.$emit("changeLoading", newValue);
      }
    },
    ...mapGetters("load", ["selectedLoad"]),
    ...mapGetters("documents", [
      "loadStatus",
      "documents",
      "tasks",
      "pendingDocuments",
      "generatedDocuments"
    ])
  },
  props: {
    loading: Boolean
  },
  data() {
    return {
      intervalID: null,
      generatingDocuments: false,
      options: [
        {
          text: "BOL",
          value: "LTL_BILL_OF_LADING"
        },
        {
          text: "Signed BOL",
          value: "BILL_OF_LADING"
        },
        {
          text: "Carrier Rate and Load Confirmation",
          value: "Carrier Rate and Load Confirmation"
        },
        {
          text: "Driver Load Confirmation",
          value: "Driver Load Confirmation"
        },
        {
          text: "LTL Labels",
          value: "LTL_SHIPPING_LABELS"
        },
        {
          text: "Easy Post Labels",
          value: "EASY_POST_LABEL"
        },
        {
          text: "POD",
          value: "DELIVERY_RECEIPT"
        }
      ],
      selected: [],
      errorMessage: false,
      fileList: [],
      uploadRender: 0,
      forbiddenStates: ["Quotes Received", "Quotes Requested", "Unassigned"],
      showOverlay: false,
      showText: false,
      openFileUrl: "",
      fileNameSelected: "",
      generatingOverlay: false
    };
  },
  methods: {
    ...mapActions("documents", [
      "generateDocuments",
      "getDocuments",
      "getTasksStatus",
      "removeTask",
      "setPendingDocuments",
      "setGeneratedDocuments",
      "addGeneratedDocument"
    ]),
    async onCheckboxChange(documents) {
      this.setPendingDocuments(documents);
    },
    async uploadFiles(data) {
      this.fileList[0] = data;
      const fileExtention = this.fileList[0].name.slice(
        this.fileList[0].name.indexOf("."),
        this.fileList[0].name.length
      );
      this.fileList[0].name = `${this.selectedLoad.name}${fileExtention}`;
      this.openFileUrl = "";
      this.uploadRender += 1;
      this.showOverlay = true;
      await this.sendFiles();
    },
    removeFile() {
      this.fileList = [];
      this.uploadRender += 1;
    },
    async sendFiles() {
      const loadStatus = this.selectedLoad.load_status;
      if (!this.forbiddenStates.includes(loadStatus)) {
        await this.documentExist();
      } else {
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Cannot Generate Documents in this Current Load Status"
        });
        this.showOverlay = false;
      }
    },
    async documentExist() {
      let body = {};
      body.shp = this.selectedLoad.name;
      body.load_id = this.selectedLoad.id;
      body.documents = ["DELIVERY_RECEIPT"];
      try {
        const response = await this.$store.dispatch(
          "documents/getDocuments",
          body
        );
        this.documentManagement(response);
      } catch {
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!"
        });
      }
    },
    documentManagement(response) {
      if (response[0].url) {
        swal
          .fire({
            title: "The load already has a POD",
            text: "Do you want to change it or view it?",
            showDenyButton: true,
            confirmButtonText: "Change it",
            denyButtonText: `View it`
          })
          .then(async result => {
            if (result.isConfirmed) {
              await this.getUrlBucket();
            } else {
              this.fileNameSelected = response[0].file_name;
              this.showOverlay = false;
              this.fileList = [];
            }
          });
      } else {
        this.getUrlBucket();
      }
    },
    async getUrlBucket() {
      const UrlBucket = await this.$store.dispatch(
        "documents/getUrlFile",
        this.fileList[0].name
      );
      await this.saveDocument(UrlBucket);
    },
    async saveDocument(url) {
      const user = JSON.parse(localStorage.getItem("charlie-uploader-user"));
      let requestInfo = {
        url: url.url,
        user: user.username,
        document: this.fileList[0]
      };
      try {
        await this.$store.dispatch("documents/saveDocument", requestInfo);
        this.showOverlay = false;
        this.fileList = [];
        swal.fire({
          icon: "success",
          title: "POD was saved correctly"
        });
      } catch {
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!"
        });
      }
    },
    tasksToArrays(tasks) {
      let tasksIds = [];
      let documents = [];
      tasks.forEach(function(task) {
        tasksIds.push(task.task_id);
        documents.push(task.document);
      });
      return { task_ids: tasksIds, documents };
    },
    async checkTasksStatus() {
      try {
        if (this.tasks.length !== 0) {
          const queryObj = this.tasksToArrays(this.tasks);
          await this.getTasksStatus(queryObj);
          this.tasks.forEach(async task => {
            if (task.status !== null) {
              if (task.status) {
                this.showAlert({
                  alertType: "success",
                  title: "Great!",
                  text: `${task.document} Has Been Generated`
                }).then(async () => {
                  await this.removeTask(task);
                  // Task succeded or failed, not in queue
                  this.addGeneratedDocument({
                    name: task.document,
                    status: task.status
                  });
                });
                await this.getDocumentsData();
              } else
                this.showAlert({
                  text: `Generating ${task.document} Has Failed`
                }).then(async () => {
                  // Task succeded or failed, not in queue
                  this.addGeneratedDocument({
                    name: task.document,
                    status: task.status
                  });
                  await this.removeTask(task);
                });
            }
          });
        } else {
          this.generatingDocuments = false;
          clearInterval(this.intervalID);
        }
      } catch (err) {
        console.error("[DOCUMENTS (169)]", err.message);
        this.showAlert({ text: err.message });
      }
    },
    checkTasks() {
      this.generatingDocuments = true;
      this.intervalID = setInterval(() => {
        this.checkTasksStatus();
      }, 10000);
    },
    async generate() {
      this.generatingOverlay = true;
      try {
        this.setGeneratedDocuments([]);
        // generates new documents
        if (this.pendingDocuments.length !== 0) {
          const loadStatus = this.selectedLoad.load_status;
          if (this.forbiddenStates.includes(loadStatus))
            throw new Error(
              "Cannot Generate Documents in this Current Load Status"
            );
          const payload = this.payloadConstructor();
          const response = await this.generateDocuments({
            payload: payload,
            loadStatus: loadStatus
          });
          this.generetingDocsError(response.data);
          await this.getDocumentsData();
        }
        // checks for pending tasks
        this.checkTasks();
      } catch (err) {
        console.error("[DOCUMENTS (204)]", err.message);
        this.errorMessage = true;
        this.$el.querySelector(".generate-message").textContent = err.message;
      }
      this.generatingOverlay = false;
    },
    payloadConstructor() {
      const payload = {};
      const documentsBot = [];
      const documentsDowload = [];
      this.pendingDocuments.forEach(item => {
        if (
          item === "Carrier Rate and Load Confirmation" ||
          item === "Driver Load Confirmation"
        ) {
          documentsBot.push(item);
        } else {
          documentsDowload.push(item);
        }
      });
      documentsDowload.includes("DELIVERY_RECEIPT")
        ? documentsDowload.push("WEIGHT_CERTIFICATE")
        : documentsDowload;
      payload.load_id = this.selectedLoad.id;
      payload.shp = this.selectedLoad.name;
      payload.documents_download = documentsDowload;
      payload.documents_bot = documentsBot;
      return payload;
    },
    generetingDocsError(docsData) {
      docsData.forEach(item => {
        if (item.errors && item.file_name != "WEIGHT_CERTIFICATE") {
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Couldnot Generate ${item.file_name} document`
          });
        }
      });
    },
    async getDocumentsData() {
      const params = {
        shp: this.selectedLoad.name,
        load_id: this.selectedLoad.id
      };
      try {
        this.documentsLoading = true;
        await this.getDocuments(params);
        this.documentsLoading = false;
      } catch (err) {
        console.error("[DOCUMENTS (215)]", err.message);
        this.showAlert({ text: err.message });
      }
    }
  },
  async created() {
    try {
      const documents = this.getDocumentsData();
      const generate = this.generate();
      await Promise.all([documents, generate]);
    } catch (err) {
      console.error("[DOCUMENTS (227)]", err.message);
      this.showAlert({ text: err.message });
    }
  },
  beforeDestroy: async function() {
    await clearInterval(this.intervalID);
  }
};
</script>

<style lang="scss" scoped>
.documents--title,
.generated-documents-title {
  text-align: left;
  margin: 20px 0;
}

.download-document {
  margin-top: 5px;
  &__link {
    text-decoration: none;
    color: white;
  }
}
.documents--button {
  margin-top: 20px;
  @include primary-button;
}

.documents--data-list {
  width: 300px;
}

.generated-documents-list {
  list-style-type: none;
  padding-left: 0;
}

.success {
  font-weight: bold;
  color: $color-user-active;
}

.failure {
  font-weight: bold;
  color: $color-user-busy;
}

.documents {
  &__upload {
    margin-top: 15px;
  }
}
</style>
