<template>
  <div class="custom-picklist">
    <label v-if="!noTitle" :for="id" :class="{ bold, noMargin }">
      {{ placeholder }}
    </label>
    <b-form-input
      :id="id"
      size="sm"
      :list="`picklist-datalist-${id}`"
      type="text"
      class="input hover-input"
      :placeholder="placeholder"
      :value="value"
      @keyup.enter="onEnter($event)"
      @change="onChange($event)"
    ></b-form-input>
    <datalist v-if="data" :id="`picklist-datalist-${id}`">
      <template v-for="(label, index) in data.labels">
        <option :key="index" :value="label" />
      </template>
    </datalist>
    <b-icon
      v-if="placeholder === 'Dispatcher' && this.value"
      variant="danger"
      class="custom-picklist__delete-field"
      icon="x-circle-fill"
      @click="deleteContent()"
    ></b-icon>
  </div>
</template>

<script>
export default {
  component: "CustomPicklist",
  props: {
    id: String,
    data: Object,
    value: String,
    placeholder: String,
    bold: Boolean,
    noMargin: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onEnter(value) {
      this.$emit("onEnter", value);
    },
    onChange(value) {
      this.$emit("onChange", value);
    },
    deleteContent() {
      if (this.placeholder === "Dispatcher") {
        this.$emit("deleteContent", true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-picklist {
  display: flex;
  &__delete-field {
    display: flex !important;
    width: 20px;
    height: 28px;
    padding-left: 4px;
    text-align: end;
    cursor: pointer;
  }
}
.input {
  font-size: 11.8px !important;
  &:focus {
    border-color: get-opacity($color-gray-shadow, 0.8) !important;
    box-shadow: 0 0 0 0.2rem get-opacity($color-gray-shadow, 0.4) !important;
  }
}

.bold {
  font-weight: bold;
}

.noMargin {
  margin-bottom: 0;
}
</style>
